import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Grid from "@material-ui/core/Grid";
// if Box has issues replace with div
import Box from "@material-ui/core/Box";
import styles from "./styles.module.scss"; // Import the CSS file for default styles

const DescriptionItem = ({
  label,
  content,
  className,
  labelClassName,
  contentClassName,
  formatter,
  spacingBetweenItemAndLabel,
  spacingBetweenSubsequentDescriptionItems,
}) => (
  <Box 
  sx={{
    marginBottom:
    spacingBetweenSubsequentDescriptionItems 
        ? spacingBetweenSubsequentDescriptionItems
        : "",
  }}
  className={classNames(styles?.["custom-descriptions-item"], className)}
  >
    <Box
      className={classNames(
        styles?.["custom-descriptions-item-label"],
        labelClassName
      )}
    >
      {label}
    </Box>
    <Box
      sx={{
        marginTop: spacingBetweenItemAndLabel ? spacingBetweenItemAndLabel : "",
      }}
      className={classNames(
        styles?.["custom-descriptions-item-content"],
        contentClassName
      )}
    >
      {typeof formatter === "function" ? formatter(content) : (content ?? '--') }
    </Box>
  </Box>
);

DescriptionItem.propTypes = {
  label: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  formatter: PropTypes.func,
};

const Description = ({
  bordered,
  layout,
  className,
  spacingBetweenDescriptions,
  spacingBetweenDescriptionTitleAndItems,
  spacingBetweenSubsequentDescriptionItems,
  spacingBetweenItemAndLabel,

  DescriptionsArray,
}) => {
  const borderedClass = styles?.["custom-descriptions-bordered"] ?? "";
  const layoutClass = styles?.[`custom-descriptions-${layout}`] ?? "";
  const rootClassName = classNames(
    styles?.["custom-descriptions"],
    {
      [borderedClass]: bordered,
      [layoutClass]: layout,
    },
    className
  );

  return (
    <Box className={rootClassName}>
      {Array.isArray(DescriptionsArray) &&
        DescriptionsArray.length > 0 &&
        DescriptionsArray.map((desc, idx) => (
          <Box
            sx={{
              marginTop:
                spacingBetweenDescriptions && idx !== 0
                  ? spacingBetweenDescriptions
                  : "",
            }}
          >
            {desc.title && (
              <Box
                sx={{
                  marginBottom: spacingBetweenDescriptionTitleAndItems
                    ? spacingBetweenDescriptionTitleAndItems
                    : "",
                }}
                className={styles?.["custom-descriptions-title"]}
              >
                {desc.title}
              </Box>
            )}
            <Box className={styles?.["custom-descriptions-view"]}>
              <Grid container>
                {desc.items.map((item, index) => (
                  <Grid item key={index} xs={item.xs} md={item.md} lg={item.lg}>
                    <DescriptionItem
                      label={item.label}
                      content={item.content}
                      formatter={item.formatter}
                      className={item.className}
                      labelClassName={item.labelClassName}
                      contentClassName={item.contentClassName}
                      spacingBetweenItemAndLabel={spacingBetweenItemAndLabel}
                      spacingBetweenSubsequentDescriptionItems = {spacingBetweenSubsequentDescriptionItems}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        ))}
    </Box>
  );
};

Description.propTypes = {
  bordered: PropTypes.bool,
  spacingBetweenDescriptions: PropTypes.string,
  layout: PropTypes.oneOf(["horizontal", "vertical"]),
  className: PropTypes.string,
  DescriptionsArray: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.node.isRequired,
          content: PropTypes.node.isRequired,
          className: PropTypes.string,
          labelClassName: PropTypes.string,
          contentClassName: PropTypes.string,
          xs: PropTypes.number,
          md: PropTypes.number,
          lg: PropTypes.number,
        })
      ).isRequired,
    })
  ).isRequired,
};

Description.defaultProps = {
  bordered: false,
  layout: "horizontal",
  DescriptionsArray: [],
};

export default Description;
