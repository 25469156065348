export const HINDI = {
    hi: {
        language: "Hindi",
        header: {
            beacon: {
                searchPlaceholder: "यहाँ स्मार्ट खोज के लिए क्लिक करें..."
            },

            popOver: {
                profile: "मेरी प्रोफ़ाइल",
                logout: "लॉग आउट"
            }
        },
        inputs: {
            search: "खोजें...",

            placeholder: {
                searchByEmployee: "कर्मचारी पर खोजें"
            },

            labels: {
                appraisalPercentage: "मूल्यांकन प्रतिशत",
                applicableDate: "लागू तिथि",
                incentiveAmount: "प्रोत्साहन राशि",
                normalizedRating: "मानकीकृत रेटिंग",
                year: "वर्ष",
                cycle: "साइकिल",
                periodicity: "अवधि"
            }
        },

        select: {

            labels: {
                selectEvaluationYear: "मूल्यांकन वर्ष का चयन करें",
                selectEvaluationCycle: "मूल्यांकन साइकिल का चयन करें",
                selectCycleQuarter: "साइकिल क्वार्टर का चयन करें",
                chooseObjectiveCycle: "उद्देश्य साइकिल चुनें",
                choosePeriod: "अवधि चुनें"
            },

            placeholder: {
                approvalStatus: "मंजूरी की स्थिति"
            },

            options: {
                all: "सभी",
                approved: "मंजूर",
                pendingApproval: "अनुमोदन की प्रतीक्षा में",
                pendingApprovalOnRM: "आरएम पर अनुमोदन की प्रतीक्षा",
                rejected: "अस्वीकृत",
                thisMonth: "इस महीने",
                lastMonth: "पिछले महीने",
                thisQuarter: "इस तिमाही",
                lastQuarter: "पिछली तिमाही",
                thisYear: "इस साल",
                lastYear: "पिछले साल",
                upcoming: "आगामी",
                completed: "पूर्ण",
                overdue: "समय से पहले"
            }

        },

        buttons: {
            updateReviewers: "समीक्षकों को अपडेट करें",
            viewDetails: "विवरण देखें",
            initiate: "प्रारंभ करें",
            createStructure: "संरचना बनाएं",
            appreciate: "प्रशंसा करना",
            scheduleOneToOne: "एक से एक की अनुसूची करें",
            giveFeedback: "प्रतिक्रिया दें",
            bulkUploadGrade: "ग्रेड का थोक अपलोड करें",
            cancel: "रद्द करें",
            update: "अपडेट करें",
            submit: "भेजना",
            createNewObjective: "उद्देश्य बनाएं"
        },

        modals: {
            titles: {
                appraisalPercentage: "मूल्यांकन प्रतिशत",
                incentiveAmount: "प्रोत्साहन राशि",
                initiateIncentive: "प्रोत्साहन प्रारंभ करें",
                normalizedRating: "मानकीकृत रेटिंग"
            }
        },

        table: {
            staticHeaders: {
                employeeCode: "कर्मचारी कोड",
                employeeName: "कर्मचारी का नाम",
                employee: "कर्मचारी",
                employees: "कर्मचारी",
                cycle: "साइकल",
                startDate: "प्रारंभ तिथि",
                endDate: "समाप्ति तिथि",
                cycleYear: "साइकल वर्ष",
                cycleQuarter: "साइकल क्वार्टर",
                structureName: "संरचना का नाम",
                action: "कार्रवाई",
                finalRating: "अंतिम रेटिंग",
                ratingYCoordinate: "रेटिंग Y समन्वय",
                designation: "पद",
                department: "विभाग",
                location: "स्थान",
                employeeEmail: "कर्मचारी ईमेल",
                employeeCount: "कर्मचारी की संख्या",
                evaluationYear: "मूल्यांकन वर्ष",
                createdBy: "द्वारा बनाई गई",
                cycleName: "साइकिल का नाम",
                structureOwner: "संरचना का मालिक",
                status: "स्थिति",
                keyResult: "मुख्य परिणाम",
                startTarget: "आरंभ लक्ष्य",
                evalCycle: "मूल्यांकन साइकिल",
                endTarget: "समाप्ति लक्ष्य",
                checkinValue: "जाँच मूल्य",
                checkinRemarks: "जाँच टिप्पणियाँ",
                objective: "उद्देश्य",
                periodicity: "अवधि",
                appraisalYear: "मूल्यांकन वर्ष",
                reportingTo: "सूचना देना",
                preNormalizedRating: "पूर्व-मानकीकृत रेटिंग",
                grade: "ग्रेड",
                appraisalPercentage: "मूल्यांकन प्रतिशत",
                previousCtc: "पिछला CTC",
                increasedCtc: "बढ़ाया गया CTC",
                incentiveAmount: "प्रोत्साहन राशि",
                applicableDate: "लागू तिथि",
                previousDesignation: "पिछला पद",
                newDesignation: "नया पद",
                objectiveWeightage: "उद्देश्य वेटेज"
            },
            dynamicHeaders: {
                EMPLOYEE_CODE: "कर्मचारी कोड",
                EMPLOYEE_NAME: "कर्मचारी का नाम",
                CYCLE_NAME: "साइकिल का नाम",
                STRUCTURE_NAME: "संरचना का नाम",
                H1_TOTAL_WTG: "एच1 कुल वजन",
                H1_ACHEIVED_WTG: "एच1 प्राप्त वजन",
                H1_ACHEIVED_WTG_PERCENTAGE: "एच1 प्राप्त वजन प्रतिशत",
                H1_PERFORMANCE_RATING: "एच1 प्रदर्शन रेटिंग",
                H1_COMPETENCY_RATING: "एच1 क्षमता रेटिंग",
                H1_POTENTIAL_RATING: "एच1 क्षमता रेटिंग",
                H1_WEIGHTED_PERFORMANCE_RATING: "एच1 वेटेड प्रदर्शन रेटिंग",
                H1_WEIGHTED_COMPETENCY_RATING: "एच1 वेटेड क्षमता रेटिंग",
                H1_WEIGHTED_POTENTIAL_RATING: "एच1 वेटेड पॉटेंशियल रेटिंग",
                H1_FINAL_RATING: "एच1 अंतिम रेटिंग"

            },

            actions: {
                compute: "गणना करें",
                view: "देखें",
                initiate: "प्रारंभ करें",
            }
        },

        popConfirm: {
            areYouSure: "क्या आप सुनिश्चित हैं?",
            approveDesignation: "क्या आप नए पद को स्वीकृत करना चाहते हैं",
            rejectDesignation: "क्या आप नए पद को अस्वीकार करना चाहते हैं",
            action: {
                cancel: "रद्द करें",
                approve: "स्वीकृत करें",
                reject: "अस्वीकृत करें"
            }
        },

        bulkUpload: {
            titleOne: "1. टेम्पलेट डाउनलोड करें",
            subTitleOne: "पूर्व परिभाषित स्तंभों के साथ इस टेम्पलेट का उपयोग करें, और अपने डेटा को भरें।",
            downloadTemplate: "टेम्पलेट डाउनलोड करें",
            titleTwo: "2. एक्सेल शीट आयात करें",
            subTitleTwo: "जब आपने एक्सेल शीट भर ली है, तो इसे आयात करें।",
            dragAndDrop: "अपना फ़ाइल यहाँ खींचें या",
            chooseFile: "फ़ाइल चुनें"
        },

        login: {
            title: "लॉगिन",
            img1Text: "सर्वश्रेष्ठ कर्मचारी अनुभव प्लेटफ़ॉर्म",
            img1SubText: "अपने आप को सीमित न करें। बहुत से लोग वे कार्य करने में सीमित करते हैं जो वे सोचते हैं कि वे कर सकते हैं। आप अपने मन की अनुमति के अनुसार इतने दूर तक जा सकते हैं। आप जो मानते हैं वह प्राप्त करते हैं।",
            img2Text: "अंत में एक जगह जहाँ सब कुछ संग्रहित होता है",
            img2SubText: "जैसा कि आप जैसे कर्मचारियों ने सोमवार को भी आनंदमय बना सकते हैं। आपके मेहनत और उत्साह के लिए धन्यवाद।",
            img3Text: "महत्वपूर्ण काम पर ध्यान केंद्रित करें",
            img3SubText: "व्यावसायिक अवसर बसों की तरह होते हैं, हमेशा एक और आता है।",
            notRegistered: "अभी तक पंजीकृत नहीं है?",
            signUp: "साइन अप करें",
            forgotPassword: "पासवर्ड भूल गए?",
            loginBtnText: "लॉगिन",
            loginWithOkta: "ओक्टा के साथ लॉगिन",
            loginWithSso: "एसएसओ के साथ लॉगिन",
            emailLabel: "ईमेल या मोबाइल नंबर",
            passwordLabel: "पासवर्ड"
        },

        tabs: {
            pending: "अपूर्ण",
            completed: "पूर्ण",
            expired: "समाप्त",
            received: "प्राप्त",
            given: "दिया गया",
            approved: "मंजूर",
            rejected: "अस्वीकृत"

        },
        navbar: {
            "Admin Dashboard": "व्यवस्थापक डैशबोर्ड",
            "Dashboard": "डैशबोर्ड",
            "Review": "समीक्षा",
            "Quick Actions": "त्वरित क्रियाएँ",
            "Analytics": "विश्लेषण",
            "Pipeline": "पाइपलाइन",
            "Rating Comparison": "रेटिंग तुलना",
            "Bell Curve": "घंटी की वक्र",
            "Objective": "लक्ष्य",
            "Feedback": "प्रतिक्रिया",
            "My Team": "मेरी टीम",
            "Self": "खुद",
            "Team": "टीम",
            "KRA-KPI Approval": "केआरए/केपीआई मंजूरी",
            "KRA/KPI Approval": "केआरए/केपीआई मंजूरी",
            "Target Setting": "लक्ष्य सेट करना",
            "Peer Assessment": "सहकर्मी मूल्यांकन",
            "Approval": "मंजूरी",
            "Organization": "संगठन",
            "KRA-KPI Creation": "केआरए-केपीआई निर्माण",
            "KRA-KPI Mapping": "केआरए-केपीआई मैपिंग",
            "Result": "परिणाम",
            "Request": "अनुरोध",
            "Check-In": "जाँच करें",
            "Key-Result": "महत्वपूर्ण परिणाम",
            "Appreciation": "सराहना",
            "One to One": "एक से एक",
            "Continuous Feedback": "लगातार प्रतिक्रिया",
            "Compensation": "मुआवजा",
            "All": "सभी",
            "Normalization": "सामान्यीकरण",
            "Appraisal": "मूल्यांकन",
            "Incentive": "प्रोत्साहन",
            "Promotion": "पदोन्नति",
            "Reports": "रिपोर्टें",
            "OKR": "OKR",
            "Settings": "सेटिंग्स",
            "Global": "वैश्विक",
            "Modules": "मॉड्यूल",
            "Time Format": "समय प्रारूप",
            "People": "लोग",
            "Untagged": "अटैग नहीं",
            "Terminology": "शब्दावली",
            "Year": "साल",
            "Cycle": "साइकिल",
            "KRA-KPI Configuration": "केआरए-केपीआई विन्यास",
            "Rating Scale": "रेटिंग स्केल",
            "Parameters": "पैरामीटर",
            "Mandatory Parameters": "अनिवार्य पैरामीटर",
            "Employee Visibility": "कर्मचारी की दृश्यता",
            "9-Box": "9-बॉक्स",
            "Set-up": "सेट अप",
            "Workflow": "वर्कफ़्लो",
            "Templates": "टेम्पलेट्स",
            "Theme": "थीम",
            "Normalization Rating": "सामान्यीकरण रेटिंग",
            "Library": "लाइब्रेरी",
            "KRAS": "क्र",
            "KPIS": "कपियों",
            "Potential": "क्षमता",
            "Competency": "क्षमता",
            "Key Result": "महत्वपूर्ण परिणाम",
            "Appreciation Badges": "सराहना बैज",
            "Skills": "कौशल",
            "Imports": "आयात",
            "Bulk Goals Setting": "थोक लक्ष्य सेटिंग",
            "Bulk Goals Achievement": "थोक लक्ष्य प्राप्ति",
            "Quick Performance Evaluation": "त्वरित प्रदर्शन मूल्यांकन",
            "Bulk Objective": "थोक लक्ष्य",
            "Bulk Objective Key Results": "थोक लक्ष्य प्रमुख परिणाम",
            "Bulk Structure": "थोक संरचना",
            "Email": "ईमेल",
            "Email Configuration": "ईमेल विन्यास",
            "Email Library": "ईमेल लाइब्रेरी",
            "Notification": "सूचना",
            "Self Assessment": "स्व-मूल्यांकन"
        },
        reports: {
            review: {
                appraiserMatrix: {
                    name: "कर्मचारी अप्रेशियर मैट्रिक्स",
                    desc: "प्रत्येक कर्मचारी के साथ संबंधित प्रदर्शन समीक्षकों या अप्रेशियर के अवलोकन प्रदान करता है, जो समीक्षा साइकिल के विभिन्न चरणों में सौदों के दौरान निर्धारित मूल्यांकनकर्ताओं का व्यापक झलक प्रस्तुत करता है, समीक्षा प्रक्रिया के दौरान सौंपे गए मूल्यांकनकर्ताओं के बारे में एक समग्र स्नैपशॉट।"
                },
                stageStatus: {
                    name: "कर्मचारी चरण स्थिति रिपोर्ट",
                    desc: "समीक्षा साइकिल के प्रत्येक चरण में समीक्षा की स्थिति का विस्तृत सारांश प्रदान करता है, कर्मचारी मूल्यांकन के विभिन्न चरणों के दौरान प्रगति की दृष्टि प्रदान करता है।"
                },
                combinedPerformenceAverage: {
                    name: "कुल प्रदर्शन औसत रिपोर्ट",
                    desc: "समीक्षा साइकिल के विभिन्न चरणों में प्राप्त प्रदर्शन रेटिंगों का औसत प्रदान करता है, इसे उनके प्रदर्शन के खिलाफ समीक्षा अवधि के दौरान विभिन्न प्रदर्शन मापकों के खिलाफ एक समग्र दृश्य प्रदान करता है।"
                },
                competencyAverage: {
                    name: "%COMPETENCY% औसत रिपोर्ट",
                    desc: "समीक्षा साइकिल के विभिन्न चरणों में प्राप्त क्षमता रेटिंगों का औसत प्रदान करता है, इसे उनके प्रदर्शन के खिलाफ समीक्षा अवधि के दौरान विभिन्न क्षमताओं के खिलाफ एक समग्र दृश्य प्रदान करता है।"
                },
                kraAverage: {
                    name: "%KRA_LABEL%/%KPI_LABEL% औसत रिपोर्ट",
                    desc: "रिपोर्ट किसी कर्मचारी के KRA/KPI रेटिंगों का सारांश प्रदान करती है, जो समीक्षा अवधि के दौरान सेट उद्देश्यों के खिलाफ उनके प्रदर्शन का एक अवलोकन प्रदान करती है।"
                },
                targetAchievement: {
                    name: "लक्ष्य साधन रिपोर्ट",
                    desc: "लक्ष्य साधन रिपोर्ट' विशेषज्ञों द्वारा तय किए गए लक्ष्यों को किस मापदंड में पूरा किया गया है, इस अवलोकन में इच्छित लक्ष्यों को प्राप्त करने की प्रगति और सफलता की जानकारी प्रदान करती है।"
                },
                potentialAverage: {
                    name: "%POTENTIALS% औसत रिपोर्ट",
                    desc: "समीक्षा साइकिल के विभिन्न चरणों में प्राप्त संभावित रेटिंगों का औसत प्रदान करता है, इसे उनके प्रदर्शन के खिलाफ समीक्षा अवधि के दौरान विभिन्न संभावनाओं के खिलाफ एक समग्र दृश्य प्रदान करता है।"
                },
                cycleRatings: {
                    name: "समीक्षा रेटिंग रिपोर्ट",
                    desc: "किसी कर्मचारी के लिए समीक्षा साइकिल के दौरान आयोजित समीक्षाओं का एक समग्र सारांश प्रदान करती है, इस समयावधि के दौरान उनके प्रदर्शन और प्रतिक्रिया का सम्पूर्ण दृश्य प्रदान करती है।"
                },
                targetVsAchievementOverview: {
                    name: "लक्ष्य vs प्राप्ति अवलोकन",
                    desc: "यह रिपोर्ट प्राप्त परिणामों के लिए निर्धारित लक्ष्य के खिलाफ उपलब्धि का विस्तृत दृश्य प्रदान करती है।"
                },
                targetBasedPerformence: {
                    name: "लक्ष्य आधारित प्रदर्शन रिपोर्ट",
                    desc: "यह रिपोर्ट निर्धारित परिणामों के लिए लक्ष्य के खिलाफ उपलब्धि का विस्तृत दृश्य प्रदान करती है।"
                },
                nineBox: {
                    name: "9 बॉक्स विस्तृत रिपोर्ट",
                    desc: "यह रिपोर्ट सेट उपलब्धियों के खिलाफ 9 बॉक्स मैट्रिक्स का विस्तृत दृश्य प्रदान करती है।"
                },
                employeeReview: {
                    name: "अंतिम रेटिंग (तिमाही)",
                    desc: "यह रिपोर्ट प्रत्येक समीक्षक के रेटिंग स्कोर प्रदान करती है।"
                },
                competencyRating: {
                    name: "क्षमता रेटिंग रिपोर्ट",
                    desc: "यह रिपोर्ट क्षमता रेटिंग का विस्तृत अवलोकन प्रदान करती है।"
                },
                employeeStructureKraKpi: {
                    name: "कर्मचारी संरचना %KRA_LABEL% %KPI_LABEL% रिपोर्ट",
                    desc: "यह रिपोर्ट कर्मचारी संरचना %KRA_LABEL% %KPI_LABEL% का विस्तृत अवलोकन प्रदान करती है।"
                },

                employeeAppraisal: {
                    name: "कर्मचारी मूल्यांकन रिपोर्ट",
                    desc: "यह रिपोर्ट कर्मचारी मूल्यांकन का विस्तृत अवलोकन प्रदान करती है"
                },
                employeePromotion: {
                    name: "कर्मचारी पदोन्नति रिपोर्ट",
                    desc: "यह रिपोर्ट कर्मचारी पदोन्नति का विस्तृत अवलोकन प्रदान करती है"
                },
                employeeUntag: {
                    name: "कर्मचारी अनटैग रिपोर्ट",
                    desc: "यह रिपोर्ट अनटैग्ड कर्मचारियों का विस्तृत अवलोकन प्रदान करती है"
                },
                finalAverageRating: {
                    name: "अंतिम औसत रेटिंग",
                    desc: "यह रिपोर्ट अंतिम औसत रेटिंग का विस्तृत अवलोकन प्रदान करती है"
                },
                finalRatingAchievements: {
                    name: "अंतिम रेटिंग उपलब्धियाँ",
                    desc: "यह रिपोर्ट अंतिम रेटिंग उपलब्धियों का विस्तृत अवलोकन प्रदान करती है"
                },
                potentialRatings: {
                    name: "संभावित रेटिंग रिपोर्ट",
                    desc: "यह रिपोर्ट संभावित रेटिंग रिपोर्ट का विस्तृत अवलोकन प्रदान करती है"
                }
            },

            okr: {
                objectiveCompletion: {
                    name: "कर्मचारी आधारित %OBJECTIVE% पूर्णता रिपोर्ट",
                    desc: "कर्मचारी आधारित %OBJECTIVE% पूर्णता का एक अवलोकन प्रदान करता है"
                },
                objectiveKeyResult: {
                    name: "%OBJECTIVE% %KEY_RESULT_LABEL%",
                    desc: "%OBJECTIVE% %KEY_RESULT_LABEL% का एक अवलोकन प्रदान करता है"
                },
                checkInApproval: {
                    name: "चेकिन्स मंजूरी की स्थिति",
                    desc: "चेकिन्स और उनकी मंजूरी की स्थिति का एक अवलोकन प्रदान करता है"
                },
                objectiveLog: {
                    name: "उद्देश्य लॉग रिपोर्ट्स",
                    desc: "लॉग (चेकिन्स, बनाएं, संपादित करें, हटाएं) उद्देश्य/मुख्य परिणामों का एक अवलोकन प्रदान करता है"
                }
            },

            continuousFeedback: {
                oneToOne: {
                    name: "लगातार प्रतिक्रिया रिपोर्ट",
                    desc: "कर्मचारी आधारित लगातार प्रतिक्रिया का एक अवलोकन प्रदान करता है"
                },
                feedback: {
                    name: "एक से एक मीटिंग रिपोर्ट",
                    desc: "कर्मचारी के एक से एक मीटिंग का एक अवलोकन प्रदान करता है"
                },
                appreciation: {
                    name: "कर्मचारी सराहना रिपोर्ट",
                    desc: "कर्मचारी सराहना रिपोर्ट का एक अवलोकन प्रदान करता है"
                }
            },
        },

        filters: {
            default: {
                multiSelect: "फ़िल्टर चुनें...",
                singleSelect: "फ़िल्टर चुनें..."
            },
            cycle: "साइकिल",
            department: "विभाग",
            designation: "पदनाम",
            location: "स्थान",
            entity: "एन्टिटी",
            more: "अधिक",
            status: "स्थिति",
            evalCycle: "मूल्यांकन साइकिल",
            employmentType: "रोजगार का प्रकार",
            businessUnit: "व्यवसायिक इकाई",
            costCenter: "लागत केंद्र",
            shift: "शिफ्ट",
            structure: "संरचना",
            cycleYear: "साइकिल वर्ष",
            joiningYear: "शामिल होने का वर्ष",
            joiningMonth: "शामिल होने का महीना",
            cycleUntaggedEmployees: "साइकिल अटैग न किए गए कर्मचारी",
            cycleTaggedEmployees: "साइकिल टैग किए गए कर्मचारी",
            employeeStatus: "कर्मचारी की स्थिति",
            reset: "रीसेट",
            approvalStatus: "मंजूरी की स्थिति",
            evalYear: "मूल्यांकन वर्ष",
            mappingStatus: "मैपिंग स्थिति",
            periodicity: "आवृत्ति",
            objectiveCycle: "लक्ष्य साइकिल",
            month: "महीना"

        },
        dashBoard: {
            review: {
                quickActions: {
                    pendingPeopleInformation: "विचारक जानकारी लंबित",
                    needAttention: "ध्यान देने की आवश्यकता (समाप्त)",
                    kraKpiApproval: "KRA/KPI मंजूरी",
                    targetSetting: "लक्ष्य सेट करना",
                    selfAssesments: "स्व-मूल्यांकन",
                    peerAssesments: "सहकर्मी मूल्यांकन",
                    reviews: "समीक्षा",
                    approvals: "मंजूरियाँ"
                },

                analytics: {
                    reviewDistributionByStage: "चरण अनुसार समीक्षा वितरण",
                    organizationAchievement: "संगठन की प्राप्ति",
                    bestPerformers: "सर्वोत्तम प्रदर्शक",
                    worstPerformers: "खराब प्रदर्शक",
                    departmentWiseRatingCount: "विभाग अनुसार रेटिंग गणना",
                    designationWiseRatingCount: "पदनाम अनुसार रेटिंग गणना",
                    nineBoxMatrix: "9-बॉक्स मैट्रिक्स",
                    performanceAssessment: "प्रदर्शन मूल्यांकन",
                    potentialAssessment: "क्षमता मूल्यांकन"
                },
                pipeLines: {
                    kraKpiMapping: "%KRA%/%KPIS% मैपिंग",
                    kraKpiApproval: "%KRA%/%KPIS% मंजूरी",
                    targetSetting: "लक्ष्य सेटिंग",
                    selfAssessment: "आत्म मूल्यांकन",
                    peerAssessment: "सहकर्मी मूल्यांकन",
                    review1: "समीक्षा (स्तर 1)",
                    review2: "समीक्षा (स्तर 2)",
                    approve1: "मंजूर (स्तर 1)",
                    approve2: "मंजूर (स्तर 2)",
                    completed: "पुरा होना"
                },
                ratingComparision: {
                    reviewRatingComparison: "समीक्षा रेटिंग तुलना"
                },
                bellCurve: {
                    bellCurveBasedOnFinalRatings: "घंटी गुणांक - अंतिम रेटिंग के आधार पर",
                    bellCurveEmployeesRatingWise: "घंटी गुणांक - कर्मचारी (रेटिंग के अनुसार)",
                    tabs: {
                        ideal: "आदर्श",
                        actual: "वास्तविक"
                    }
                }
            }
        },

        objective: {
            organizationOKR: "संगठन OKR",
            teamOKR: "टीम OKR",
            myOKR: "मेरा OKR"
        },

        compensation: {
            initiateEmployeeCompensation: "कर्मचारी भत्ते को प्रारंभ करें",
            popOver: {
                moveToNormalization: "मूल्यांकन में जाएं",
                moveToAppraisal: "मूल्यांकन में जाएं",
                moveToIncentive: "प्रोत्साहन में जाएं",
                moveToIncentiveAndAppraisal: "प्रोत्साहन और मूल्यांकन में जाएं",
                updateGrade: "ग्रेड अपडेट करें",
                moveToAppraisal: "मूल्यांकन में जाएं"
            },
            bulkUpload: {
                normalizationGradeLabel: "मानकीकरण ग्रेड का थोक अपलोड करें"
            },
        },

        empty: {

            noRecordsFound: "कोई रिकॉर्ड नहीं मिला",

            filters: {
                multiSelect: {
                    cycle: "रिकॉर्ड्स के लिए कृपया साइकिल चुनें"
                },
                single: {
                    cycle: "कृपया संबंधित साइकिल का चयन करें"
                }
            }
        }
    }
}