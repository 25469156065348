import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";
import Header from "../components/Header";
import isEqual from "lodash/isEqual";
import errorImage from "../assests/svg/error.svg";
const ErrorPage = () => {
  const { token } =
    useSelector((state) => state.adminUserReducer, isEqual) 
  return (
    <Box className="wh-vw-vh ">

      <Box className="wh-100 flex-center flex-column">
        <img src={errorImage} style={{ height: "29vh" }} alt="" />
        <Typography sx={{ mt: 3.5, fontSize: 20 }} variant="h3">
          Page Not Found
        </Typography>
        <Typography sx={{ mt: 1 }} variant="body1">
          The page you are looking for doesn’t exist or an another error
          occurred, please go back to homepage.
        </Typography>
      </Box>
    </Box>
  );
};

export default ErrorPage;
