import moment from "moment";

export const FEEDBACK_TABS_ALL = {
    ONE_TO_ONE_TAB: 1,
    FEEDBACK_TAB: 2,
    APPRECIATION_TAB: 3
}

export const PERFORMANCE_TABS_ALL = {
    REVIEW: 1,
    OBJECTIVE:3,
    PIP: 2,
}

export const ReasonsToInitatePIPOptions = [
    { label: "Poor Performance", value: 1 },
    { label: "Not Meeting Expected Goals", value: 2 },
    { label: "Fill Skill Gaps", value: 3 },
    { label: "Behavioural Concerns", value: 4 },
    { label: "Others", value: 5 },
];

export const InitatePIPModalInitialValues = {
    ReasonsToInitiatePip: "",
    PlanDescription: "",
    StartDate: null,
    EndDate: null,
    ReviewOnDates: [{ ReviewDate: null, Time: null }],
};

export const pip__details__error = {
    color: "#f40b0b",
    fontSize: "0.75rem",
    fontWeight: "400",
    lineHeight: "1.66",
    marginBottom: "0",
    marginLeft: "4px",
    marginRight: "14px",
    marginTop: "4px",
    textAlign: "left",
};

export const initailOneToOneModalValues = {
    MEETING_TITLE: "",
    DESCRIPTION: "",
    START_TIME: moment(new Date()).format("HH:mm:ss"),
    DATE: moment(new Date())?.format("YYYY-MM-DD"),
    DURATION: "",
    LOCATION: "",
    MEDIUM: 1,
    SCHEDULE_WITH: "",
    MODULE_TYPE: 1,
}