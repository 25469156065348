import {
  GET_ACTIVE_SESSION_SUCCESS,
  GET_SESSION_HISTORY_SUCCESS,
  KILL_SESSION_SUCCESS,
} from "./constant";

const initialValue = {
  activeSession: [],
  sessionHistory: [],
};

const reducer = (state = initialValue, { type, payload }) => {
  switch (type) {
    case GET_ACTIVE_SESSION_SUCCESS:
      return {
        ...state,
        activeSession: [...payload],
      };
    case GET_SESSION_HISTORY_SUCCESS:
      return {
        ...state,
        sessionHistory: [...payload],
      };
    case KILL_SESSION_SUCCESS:
      state.activeSession.splice(
        state.activeSession.findIndex((item) => item.id === payload),
        1
      );

      return { ...state, activeSession: [...state.activeSession] };
    default:
      return { ...state };
  }
};
export default reducer;
