// import styles "../../components/HorizontalAccordion/styles.module.scss";
import EmailIcon from "@mui/icons-material/Email";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, CircularProgress, Grid } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import CustomChip from "../../components/CustomChip/CustomChip";
import CustomPopover from "../../components/CustomPopover/CustomPopover";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import styles from "../../components/HorizontalAccordion/styles.module.scss";
import Loader from "../../components/Loader";
import TextReducingTooltipComponent from "../../components/TextReducingTooltipComponent";
import { useFetch } from "../../hooks/useFetch";
import { get_formated_date } from "../../utitlies/commonFunction";
import FeedbackModal from "../continuous_feedback/feedback/FeedbackModal";
import apiPaths from "../../config/apiPath.json";
import { getEmpDetails } from "../reviews/configuration/apiPromises";
import ContinuosFeedback from "./ContinousFeedback/ContinuosFeedback";
import EmployeeDetails from "./EmployeeDetails/EmployeeDetails";
import InitiatePipModal from "./Modals/InitiatePipModal";
import Performance from "./Performance/Performance";
import EmployeeTimeline from "./Timeline/EmployeeTimeline";
import { lazy, Suspense } from "react";
import "./myTeam.styles.scss";
// import ObjectiveNew from "../../modules/objectives/ObjectiveNew";
import CareerProgression from "./CareerProgression/CareerProgression";
import { CustomAvatar } from "@zimyo/components";
import { postAxiosForuseFetch } from "../../Api/axiosPrivate";
import { AppreciationModal, ContinuousFeedbackModal, OneToOneModal } from "@zimyo/engage"

const ObjectiveNew = lazy(() =>
  import("../../modules/objectives/ObjectiveNew")
);

const employeeStatus = {
  0: "Active",
  1: "On Probation",
  3: "Separated",
  4: "Absconded",
  5: "Terminated",
  6: "Suspended",
  dummy_suspend: "Suspended/Dummy Record",
};

const EmployeeProfile = ({
  employeeData = {},
  isPeople = false,
  isMyTeam = false,
  hide_tabs = [],
  employee_id,
  handleClose = () => {},
}) => {
  const { user = {}, token } = useSelector(
    (state) => state.adminUserReducer
    // isEqual
  );
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [anchorEl, setanchorEl] = useState({});
  const [filterType, setFilterType] = useState(1);
  const [isAppreciationModalOpen, setIsAppreciationModalOpen] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [isOneToOneModalOpen, setIsOneToOneModalOpen] = useState(false);
  const [isInitiatePipModalOpen, setIsInitiatePipModalOpen] = useState(false);

  const handleTabChange = (event, newValue) => {
    setFilterType(newValue);
  };

  const { data: employeeDetails, isFetching } = useFetch(
    getEmpDetails,
    {
      token,
      emp_id: employee_id ?? employeeData?.EMPLOYEE_ID,
    },
    "getEmpDetails",
    {
      enabled: Boolean(employee_id) || Boolean(employeeData?.EMPLOYEE_ID),
    }
  );

  const { data: feedbackGraphData } = useFetch(
    postAxiosForuseFetch,
    {
      token,
      url: apiPaths.FEEDBACK_DASHBOARD_COUNT,
      data: {
        employee_id: employee_id ?? employeeData?.EMPLOYEE_ID,
      },
    },
    "feedbackGraphData"
  );

  const PopoverMenu = [
    {
      title: "Schedule One to One",
      onClick: () => {
        setIsPopoverOpen(false);
        setIsOneToOneModalOpen(true);
      },
    },
    {
      title: "Give Feedback",
      onClick: () => {
        setIsPopoverOpen(false);
        setIsFeedbackModalOpen(true);
      },
    },
    {
      title: "Appreciate Employee",
      onClick: () => {
        setIsPopoverOpen(false);
        setIsAppreciationModalOpen(true);
      },
    },
  ];

  const tabData = useMemo(() => {
    return [
      {
        id: "overview",
        label: "Overview",
        value: 1,
        component: (
          <EmployeeDetails
            employeeDetails={employeeDetails}
            feedbackGraphData={feedbackGraphData}
          />
        ),
      },

      {
        id: "performance",
        label: "Review",
        value: 3,
        component: (
          <>
            <Performance
              isPeople={isPeople}
              employee_id={employee_id ?? employeeData?.EMPLOYEE_ID}
              filterType={filterType}
              handleClose={handleClose}
            />
          </>
        ),
      },
      {
        id: "obj",
        label: "Objective",
        value: 6,

        component: (
          <Suspense
            fallback={
              <div className="flex-center wh-100">
                <CircularProgress />
              </div>
            }
          >
            <ObjectiveNew
              propsObj={{
                list_for: "employee",
                employeeId: employee_id ?? employeeData?.EMPLOYEE_ID,
              }}
            />
          </Suspense>
        ),
      },
      {
        id: "continuous_feedback",
        label: "Feedback",
        value: 2,
        component: (
          <>
            <ContinuosFeedback
              employee_id={employee_id ?? employeeData?.EMPLOYEE_ID}
            />
          </>
        ),
      },

      {
        id: "timeline",
        label: "Timeline",
        value: 5,
        component: (
          <EmployeeTimeline
            employeeData={employeeData}
            employee_id={employee_id}
          />
        ),
      },

      {
        id: "career_progression",
        label: "Career progression",
        value: 7,
        component: (
          <CareerProgression
            employeeData={employeeData}
            employee_id={employee_id}
          />
        ),
      },
    ]?.filter((el) => !hide_tabs?.includes(el?.id));
  }, [
    employeeDetails,
    employee_id,
    isPeople,
    hide_tabs,
    employeeData,
    filterType,
  ]);

  return (
    <Box>
      <div
        className={`${styles?.details_card} flex-start-center`}
        style={{ marginBottom: "0px" }}
      >
        <Loader visible={isFetching} />
        <CustomAvatar
          alt={employeeDetails?.EMPLOYEE_NAME}
          src={
            employeeDetails?.USER_PROFILE_IMAGE ||
            `https://robohash.org/${employeeDetails?.EMPLOYEE_NAME}`
          }
          sx={{
            height: "7.5rem",
            width: "7.5rem",
            background: employeeDetails?.USER_PROFILE_IMAGE ? "" : "grey",
          }}
        />

        <Grid container spacing={1.5} className={styles?.detail_content}>
          <Grid item xs={6} md={4} lg={4} className="flex-start-center g-10">
            <div className={styles?.emp_name_details}>
              {employeeDetails?.EMPLOYEE_NAME ?? "--"}
            </div>{" "}
            <div className={styles?.emp_code_details}>
              {" "}
              {employeeDetails?.EMPLOYEE_CODE ?? "--"}{" "}
            </div>
          </Grid>

          <Grid item xs={6} md={8} lg={8} className="flex-start-center g-10">
            <CustomChip
              className={styles?.chip_style_common}
              text={employeeStatus?.[employeeDetails?.EMPLOYEE_STATUS] ?? "--"}
              style={{
                color: "#00CD37",
                background: "#00CD371A",
                border: "1px solid #00CD37",
                width: "20%",
              }}
            />
            {!isPeople && (
              <>
                {" "}
                <CustomChip
                  className={styles?.chip_style_common}
                  text={"Evaluation Cycle : Monthly-1, 2023-2024"}
                  style={{
                    color: "#F2A200",
                    background: "#F2A2001A",
                    border: "1px solid #F2A200",
                  }}
                />
                <CustomChip
                  className={styles?.chip_style_common}
                  text={"Quarter Status : Yearly"}
                  style={{
                    color: "#F2A200",
                    background: "#F2A2001A",
                    border: "1px solid #F2A200",
                  }}
                />
              </>
            )}
            {isMyTeam && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "right",
                  width: "100%",
                }}
                onClick={(event) => {
                  setanchorEl({ top: event.pageY, left: event.pageX });
                  setIsPopoverOpen(true);
                }}
              >
                <MoreVertIcon sx={{ cursor: "pointer" }} />
              </div>
            )}
          </Grid>

          <Grid item xs={12} md={6} lg={4} className="flex-start-center g-10">
            <div className={styles?.detail_desg}>
              {employeeDetails?.DESIGNATION_NAME ?? "--"}
            </div>
            <FiberManualRecordIcon
              style={{ width: "10px", height: "10px", color: "#D2D1D4" }}
            />
            <div className={styles?.detail_desg}>
              <TextReducingTooltipComponent
                data={employeeDetails?.DEPARTMENT_NAME ?? "--"}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={4} className="flex-start-center g-10">
            <div className={styles?.detail_label}>Reporting Manager : </div>
            <div className={styles?.detail_value}>
              {employeeDetails?.REPORTING_TO_NAME ?? "--"}(
              {employeeDetails?.REPORTING_TO_CODE ?? "--"}){" "}
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={4} className="flex-start-center g-10">
            <div className={styles?.detail_label}>Joining Date : </div>
            <div className={styles?.detail_value}>
              {get_formated_date(
                employeeDetails?.JOINING_DATE,
                user.ng_date_str
              ) ||
                employeeDetails?.JOINING_DATE ||
                "--"}
            </div>
          </Grid>

          {isPeople && (
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              className="flex-start-center g-10"
            >
              <div className={styles?.detail_desg}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <LocalPhoneIcon
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "10px",
                    }}
                    color="secondary"
                  />
                  {employeeDetails?.MOBILE_PHONE ?? "--"}
                </div>
              </div>

              <FiberManualRecordIcon
                style={{ width: "10px", height: "10px", color: "#D2D1D4" }}
              />
              <div className={styles?.detail_desg}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <EmailIcon
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "10px",
                    }}
                    color="secondary"
                  />

                  {employeeDetails?.EMPLOYEE_EMAIL ?? "--"}
                </div>
              </div>
              <FiberManualRecordIcon
                style={{ width: "10px", height: "10px", color: "#D2D1D4" }}
              />
              <div className={styles?.detail_desg}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <LocationOnIcon
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "10px",
                    }}
                    color="secondary"
                  />
                  <TextReducingTooltipComponent
                    data={employeeDetails?.LOCATION_NAME ?? "--"}
                    limit={25}
                  />
                </div>
              </div>
            </Grid>
          )}
        </Grid>

        {isPopoverOpen && (
          <CustomPopover
            isPopoverOpen={isPopoverOpen}
            anchorPosition={anchorEl}
            PopoverMenu={PopoverMenu}
            onClose={() => setIsPopoverOpen(false)}
          />
        )}
      </div>
      <div className="flex-start-center" style={{ padding: "10px" }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div>
              <CustomTabs
                filterType={filterType}
                handleChange={handleTabChange}
                tabData={tabData}
                unMountPanelsOnTabChange={true}
              />
            </div>
          </Grid>
        </Grid>
      </div>

      < AppreciationModal
        open={isAppreciationModalOpen}
        setOpen={setIsAppreciationModalOpen}
        employee={employeeData}
        empIdKey="EMPLOYEE_ID"
        empCodeKey="EMPLOYEE_CODE"
        empNameKey="EMPLOYEE_NAME"
        empProfileImageKey="empProfileImageKey"
      />

      <ContinuousFeedbackModal
        setOpen={setIsFeedbackModalOpen}
        open={isFeedbackModalOpen}
        employee={employeeData}
        empIdKey="EMPLOYEE_ID"
        empCodeKey="EMPLOYEE_CODE"
        empNameKey="EMPLOYEE_NAME"
        empProfileImageKey="empProfileImageKey"
      />

      <OneToOneModal
        setOpen={setIsOneToOneModalOpen}
        open={isOneToOneModalOpen}
        employee={employeeData}
        empIdKey="EMPLOYEE_ID"
        empCodeKey="EMPLOYEE_CODE"
        empNameKey="EMPLOYEE_NAME"
        empProfileImageKey="empProfileImageKey"
      />
      
      {isInitiatePipModalOpen && (
        <InitiatePipModal
          isOpen={isInitiatePipModalOpen}
          setIsOpen={setIsInitiatePipModalOpen}
          employeeData={employeeData}
        />
      )}
    </Box>
  );
};

export default EmployeeProfile;
