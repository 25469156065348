import {
  ACTIVE_DRAWER_LISTTEM,
  COLLASE_ORGANISATION_DRAWER_LIST,
  ACCESS_GROUP_OPEN_CREATE_MODAL,
  OPEN_TRANSFER_EMPLOYEE_MODAL,
  OPEN_SUSPEND_EMPLOYEE_MODAL,
  OPEN_PROBATION_UPDATE_EMPLOYEE_MODAL,
  SET_EMPLOYEE_DATA,
  OPEN_BULK_CREATION,
  OPEN_BULK_MODIFICATION,
  OPEN_BULK_UPDATION,
  OPEN_BULK_CREATION_CONFIG,
  OPEN_BULK_UPDATION_CONFIG,
  OPEN_BULK_MODIFICATION_CONFIG,
  OPEN_EXCLUDE_EMPLOYEE_MODAL,
  IS_LOADING_PAGE,
  IS_USER_AUTHENICATED,
  UPDATE_ADMIN_USER,
  EDIT_SUSPEND_EMPLOYEE_DETAILS,
  ORG_MASTER_ORG_UNIT_PAGE,
  ORG_MASTER_FORM_LIBRARY_PAGE,
  ORG_MASTER_EMAIL_LIBRARY_PAGE,
  ORGANISATION_FORM_PAGE,
  // delete that stuff
  ORGANISATION_ENTITES_PAGE,
  ORGANISATION_BUSINESS_UNIT_PAGE,
  ORGANISATION_REGIONS_PAGE,
  ORGANISATION_DEPARTMENT_PAGE,
  ORGANISATION_DESIGNATIONS_PAGE,
  ORGANISATION_EMPLOYEE_TYPE_PAGE,
  ORGANISATION_LOCATION_PAGE,
  ORGANISATION_GRADE_PAGE,
  ORGANISATION_ZONE_PAGE,
  ORGANISATION_CHANNEL_PAGE,
  ORGANISATION_SUB_DEPARTMENT_PAGE,
  COLLAPSE_LEFTBAR,
  ORGANISATION_CONST_CENTER_PAGE,
  OPEN_LMS_CRED_MODAL,
  OPEN_AADHAR_VERIFY_MODAL,
  PARENT_TABS_DATA,
  CHILDREN_TABS_DATA,
  VIEW_RELAXED_GEO_EMPLOYEE,
  VIEW_GEO_EMPLOYEE,
  OPEN_RELAXED_GEO_MODAL
} from "./constant";
const initialState = {
  activeListItem: "apps",
  collapseOrganisationListItem: false,
  accessGroupOpenModal: false,
  openTransferModal: false,
  openSuspendModal: false,
  openProbationModal: false,
  employeeData: {},
  openCreationModal: false,
  openModificationModal: false,
  openUpdationModal: false,
  openCreationConfigModal: false,
  openUpdationConfigModal: false,
  openModificationConfigModal: false,
  openExcludeEmployeeModal: false,
  loadingPage: false,
  authencatedUser: false,
  editSuspendModal: false,
  orgUnitPage: 0,
  orgFormLibraryPage: 0,
  orgEmailLibraryPage: 0,
  gradePage: 0,
  zonePage: 0,
  channelPage: 0,
  subDepartmentPage: 0,
  costCenterPage: 0,
  locationPage: 0,
  employeeTypePage: 0,
  designationPage: 0,
  enititesPage: 0,
  businsessUnitPage: 0,
  regionsPage: 0,
  departmentPage: 0,
  organsationPage: {},
  isAdmin: false,
  openLmsCredModal: false,
  openAdharVerifyModal: false,
  parentTabsData: [],
  childrenTabsData: [],
  openRelaxedGeoModal: false,
  viewRelaxGeoEmployeeModal : false,
  viewGeoEmployeeModal : false,
};
const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case COLLAPSE_LEFTBAR:
      return { ...state, collapseOrganisationListItem: payload };
    case ORGANISATION_ENTITES_PAGE:
      return { ...state, enititesPage: payload };
    case ORGANISATION_BUSINESS_UNIT_PAGE:
      return { ...state, businsessUnitPage: payload };
    case ORGANISATION_REGIONS_PAGE:
      return { ...state, regionsPage: payload };
    case ORGANISATION_DEPARTMENT_PAGE:
      return { ...state, departmentPage: payload };
    case ORGANISATION_DESIGNATIONS_PAGE:
      return { ...state, designationPage: payload };
    case ORGANISATION_EMPLOYEE_TYPE_PAGE:
      return { ...state, employeeTypePage: payload };
    case ORGANISATION_LOCATION_PAGE:
      return { ...state, locationPage: payload };
    case ORGANISATION_GRADE_PAGE:
      return { ...state, gradePage: payload };
    case ORGANISATION_ZONE_PAGE:
      return { ...state, zonePage: payload };
    case ORGANISATION_CHANNEL_PAGE:
      return { ...state, channelPage: payload };
    case ORGANISATION_SUB_DEPARTMENT_PAGE:
      return { ...state, subDepartmentPage: payload };
    case ORGANISATION_CONST_CENTER_PAGE:
      return { ...state, costCenterPage: payload };

    // delete above stuff
    case ORGANISATION_FORM_PAGE:
      return {
        ...state,
        organsationPage: { ...state.organsationPage, ...payload },
      };
    case ORG_MASTER_EMAIL_LIBRARY_PAGE:
      return { ...state, orgEmailLibraryPage: payload };
    case ORG_MASTER_FORM_LIBRARY_PAGE:
      return { ...state, orgFormLibraryPage: payload };
    case ORG_MASTER_ORG_UNIT_PAGE:
      return { ...state, orgUnitPage: payload };
    case IS_LOADING_PAGE:
      return { ...state, loadingPage: payload };
    case IS_USER_AUTHENICATED:
      return { ...state, authencatedUser: payload };
    case UPDATE_ADMIN_USER:
      return { ...state, ...payload };
    case ACTIVE_DRAWER_LISTTEM:
      return { ...state, activeListItem: payload };
    case COLLASE_ORGANISATION_DRAWER_LIST:
      return { ...state, collapseOrganisationListItem: payload };
    case ACCESS_GROUP_OPEN_CREATE_MODAL:
      return { ...state, accessGroupOpenModal: !state.accessGroupOpenModal };
    case OPEN_PROBATION_UPDATE_EMPLOYEE_MODAL:
      return { ...state, openProbationModal: !state.openProbationModal };
    case SET_EMPLOYEE_DATA:
      return { ...state, employeeData: { ...payload } };
    case OPEN_TRANSFER_EMPLOYEE_MODAL:
      return { ...state, openTransferModal: !state.openTransferModal };
    case VIEW_RELAXED_GEO_EMPLOYEE:
      return {...state,viewRelaxGeoEmployeeModal:!state.viewRelaxGeoEmployeeModal};
    case VIEW_GEO_EMPLOYEE : 
      return {...state,viewGeoEmployeeModal:!state.viewGeoEmployeeModal};
    case OPEN_SUSPEND_EMPLOYEE_MODAL:
      return { ...state, openSuspendModal: !state.openSuspendModal };
    case OPEN_AADHAR_VERIFY_MODAL:
      return { ...state, openAdharVerifyModal: !state.openAdharVerifyModal };
    case OPEN_BULK_CREATION:
      return { ...state, openCreationModal: !state.openCreationModal };

    case OPEN_BULK_MODIFICATION:
      return { ...state, openModificationModal: !state.openModificationModal };

    case OPEN_BULK_UPDATION:
      return { ...state, openUpdationModal: !state.openUpdationModal };
    case OPEN_BULK_CREATION_CONFIG:
      return {
        ...state,
        openCreationConfigModal: !state.openCreationConfigModal,
      };
    case OPEN_BULK_UPDATION_CONFIG:
      return {
        ...state,
        openUpdationConfigModal: !state.openUpdationConfigModal,
      };
    case OPEN_BULK_MODIFICATION_CONFIG:
      return {
        ...state,
        openModificationConfigModal: !state.openModificationConfigModal,
      };
    case OPEN_EXCLUDE_EMPLOYEE_MODAL:
      return {
        ...state,
        openExcludeEmployeeModal: !state.openExcludeEmployeeModal,
      };
    case OPEN_LMS_CRED_MODAL:
      return { ...state, openLmsCredModal: !state.openLmsCredModal };
    case EDIT_SUSPEND_EMPLOYEE_DETAILS:
      return { ...state, editSuspendModal: !state.editSuspendModal };
    case PARENT_TABS_DATA:
      return { ...state, parentTabsData: payload };
    case CHILDREN_TABS_DATA:
      return { ...state, childrenTabsData: payload };
    case OPEN_RELAXED_GEO_MODAL:
      return {...state,openRelaxedGeoModal:!state.openRelaxedGeoModal}
    default:
      return { ...state };
  }
};
export default reducer;
