import React from 'react'
import doc from './assets/doc.png'
import docx from './assets/docx.png'
import jpg from './assets/jpg.png'
import pdf from './assets/pdf.png'
import png from './assets/png.png'
import ppt from './assets/ppt.png'
import pptx from './assets/pptx.png'
import xls from './assets/xls.png'
import xlsx from './assets/xlsx.png'
import style from './styles.module.scss'

const FileType = (props) => {

    const getFileExtention = (filename) => {
        let ext =  filename?.split('.')?.pop();
        if (ext === 'doc') {
            return doc
        } else if (ext === 'docx') {
            return docx
        } else if (ext === 'jpg') {
            return jpg
        } else if (ext === 'pdf') {
            return pdf
        } else if (ext === 'png') {
            return png
        } else if (ext === 'ppt') {
            return ppt
        } else if (ext === 'pptx') {
            return pptx
        } else if (ext === 'xls') {
            return xls
        } else if (ext === 'xlsx') {
            return xlsx
        } else {
            return doc
        }
    }

    return <React.Fragment>
        <div className={style?.fileImg} style={{
            backgroundImage: `url(${getFileExtention(props?.file)})`,
            ...props.styleOverrides
            }}>
        </div>
    </React.Fragment>
}

export default FileType;
