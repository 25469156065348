// import * as CryptoJS from "crypto-js";

export const updateFilterObj = (value, key, filter) => {
  const modifiedFilter = { ...filter };

  if (modifiedFilter.hasOwnProperty(key)) {
    const filteredItems = modifiedFilter[key].filter(item => item.id !== value.id);
    if (filteredItems.length === modifiedFilter[key].length) {
      modifiedFilter[key] = [...modifiedFilter[key], value];
    } else {
      modifiedFilter[key] = filteredItems;
    }
  } else {
    modifiedFilter[key] = [];
    modifiedFilter[key] = [...modifiedFilter[key], value];
  }
  return modifiedFilter;
};


