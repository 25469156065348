import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { getConfigLabels } from "../../utitlies/HelpersFunction";
import { useIntl } from 'react-intl';
import { clearCookies } from "../../utils/Utils";


const defaultConfigLabelMenuSlugs = {
  ORG_KRAS: "",
  ORG_KPIS: "",
  ORG_COMPETENCIES: "",
  ORG_POTENTIAL: "",
};

const PrimaryHeader = () => {
  const location = useLocation();
  const history = useNavigate();
  const intl = useIntl();
  const { user = {} } = useSelector((state) => state.adminUserReducer, isEqual);
  const { type = "", message = "" } = useSelector(
    (state) => state.adminMessageReducer,
    isEqual
  );
  const { menu_access = [] } = useSelector(
    (state) => state.adminUserReducer,
    isEqual
  );
  const { parentTabsData = [] } = useSelector(
    (state) => state.adminLocalStateReducer,
    isEqual
  );


  let { PRODUCT_THEME_COLOR: ptc } = user;
  const dispatch = useDispatch();

  const [lightBackground, setLightBackground] = useState(false);

  const getItemName = (item) => {
    if (
      item?.menu_slug &&
      Object.keys(defaultConfigLabelMenuSlugs).includes(item?.menu_slug)
    ) {
      let {
        KRA_LABEL,
        KPI_LABEL,
        COMPETENCY,
        POTENTIALS,
      } = getConfigLabels();

      const configLabelMenuSlugs = {
        ORG_KRAS: KRA_LABEL,
        ORG_KPIS: KPI_LABEL,
        ORG_COMPETENCIES: COMPETENCY,
        ORG_POTENTIAL: POTENTIALS,
      };
      return configLabelMenuSlugs?.[item?.menu_slug] || item?.name;
    }
    return intl.formatMessage({ id: `navbar.${item.name}`, defaultMessage: item.name });
  };

  useEffect(() => {
    if (
      ptc === "#E5B099" ||
      ptc === "#E0BCBE" ||
      ptc === "#E4D1C2" ||
      ptc === "#FFFFFF" ||
      ptc === null ||
      ptc?.length === 0
    ) {
      setLightBackground(true);
    }

  }, [user]);

  useEffect(() => {
    if (
      type === "error" &&
      (message === "Your session has expired, kindly login again." ||
        message === "Your session has expired, kindly login again.")
    ) {
      clearCookies()
      window.location.href = '/'
    }
  }, [message, type]);


  const [updateName, setUpdateStateName] = useState(-1);

  const onTabChange = (item) => {
    if (!isEmpty(parentTabsData)) {
      let paths = location?.pathname?.split("/");
      let SelectedItem =
        paths.length > 2
          ? parentTabsData?.find(
            (item) => item.url === `${paths[1]}/${paths[2]}`
          )
          : menu_access?.find((item) => item.url === `/${paths[1]}`);

      if (!isEmpty(SelectedItem)) {
        const index = parentTabsData.findIndex(
          (item) => item.id === SelectedItem.id
        );
        setUpdateStateName(index === -1 ? parentTabsData.length - 1 : index);
      } else {
        setUpdateStateName(parentTabsData.length - 1);
      }

    }
  };
  useEffect(() => {
    if (!isEmpty(parentTabsData)) {

      let paths = window.location?.pathname?.split("/");
      let SelectedItem =
        paths.length > 2
          ? parentTabsData?.find(
            (item) => item.url === `${paths[1]}/${paths[2]}`
          )
          : parentTabsData?.find((item) => item.url === `/${paths[1]}`);

      if (!isEmpty(SelectedItem)) {
        const index = parentTabsData.findIndex(
          (item) => item.id === SelectedItem.id
        );
        setUpdateStateName(index === -1 ? parentTabsData.length - 1 : index);
      } else {
        setUpdateStateName(0);

      }

    }
  }, [dispatch, parentTabsData, menu_access, location]);
  return (
    <>
      <Toolbar
        disableGutters
        style={{ height: "45px", color: "none", width: "100%" }}
      >
        <Box
          sx={{
            sm: 3,
            md: 5,
            lg: 6.4,
            backgroundColor: ptc,
            borderTopLeftRadius: 80,
            width: "100%",
          }}
          className="flex-end-center header-box"
        >
          <Grid
            container
            className="wh-100 flex-around-center"
          >
            <Grid item md={10} className="flex-end-center">
              <Tabs
                className={"flex-center wh-100 header-tabs"}
                centered
                value={updateName}
                TabIndicatorProps={{
                  style: {
                    background: "none",
                  },
                }}
              >
                {parentTabsData.map((item, index) => (
                  <>
                    <Tab
                      className="tab-header"
                      id={`${item?.name}`}
                      key={item?.id + index + Math.floor(Math.random() * 100)}
                      onClick={(e) => {
                        let url = item?.children?.length ? item.children[0]?.url : item.url;
                        if (url) {
                          history(`/${url}`);
                        }
                        onTabChange(item);
                      }}
                      value={index === -1 ? false : index}
                      sx={{
                        opacity: "1 !important",
                        minWidth: "40px !important",
                        mx: { lg: 0.75, md: 0.5, sm: 0.3 },
                        p: 0,
                        minHeight: "45px !important",
                        height: "45px !important",
                      }}
                      label={
                        <Box
                          sx={{
                            color:
                              index === updateName
                                ? "secondary.main"
                                : "primary.main",
                            "&:hover": {
                              color: "secondary.main",
                            },
                          }}
                          className="wh-100 flex-column"
                        >
                          <Box
                            sx={{ height: 45 }}
                            className="w-100 flex-center"
                          >
                            <Typography
                              sx={{
                                mr: 0.5,
                                ml: 0.5,
                                color: lightBackground
                                  ? index === updateName
                                    ? ptc === "#FFFFFF"
                                      ? "#335AFF"
                                      : "black"
                                    : "rgba(35, 31, 32, 0.5)"
                                  : index === updateName
                                    ? ptc === "#FFFFFF"
                                      ? "#335AFF"
                                      : "white"
                                    : "#ffffff80",
                              }}
                              color={"inherit"}
                              variant="h3"
                              className="header-name"
                            >
                              {getItemName(item)}
                            </Typography>
                          </Box>
                          <Box
                            className="box-radius"
                            style={
                              updateName === index
                                ? {
                                  backgroundColor: "none",
                                }
                                : { backgroundColor: "transparent" }
                            }
                          />
                        </Box>
                      }
                    />
                  </>
                ))}
              </Tabs>
            </Grid>
          </Grid>
        </Box>
      </Toolbar>
    </>
  );
};
export default React.memo(PrimaryHeader);
