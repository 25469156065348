import {  Box, Grid, IconButton, TextField, Typography } from "@mui/material";
import { FieldArray, Field, Formik, Form } from "formik";
import { CustomAvatar, CustomButton, CustomInput, CustomModal, CustomSelect } from "@zimyo/components";
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import '../../../modules/continuous_feedback/employeeAppreciation/EmployeeAppreciation.css'
import { ReasonsToInitatePIPOptions, InitatePIPModalInitialValues, pip__details__error } from '../constants'
import { useState } from "react";
import {
    KeyboardTimePicker,
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { createStyles, makeStyles } from "@mui/styles";
import Schedule from "@material-ui/icons/Schedule";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import * as yup from 'yup'

const useStyles = makeStyles({
    timePicker: {
        "& .MuiOutlinedInput-root": {
            minHeight: "40px",
            height: "40px",
        },
    },
    keyboardDatePicker: {
        "& .MuiOutlinedInput-root": {
            height: "40px",
            minHeight: "40px",
        },
    },
});

const InitiatePipModal = ({ isOpen, setIsOpen, employeeData }) => {
    const [pipPlan, setPipPlan] = useState("");
    const [selectedDates, setSelectedDates] = useState({
        startDate: null,
        endDate: null,
    });
    const newStyles = useStyles();
    const handleModalClose = () => {
        setIsOpen(false)
    }
    const handleModalSubmit = () => {
        setIsOpen(false)
    }

    yup.addMethod(yup.array, "unique", function (message, mapper) {
        return this.test("unique", message, function (list) {
            return list.length === new Set(list.map(mapper)).size;
        });
    });

    const initiatePipSchema = yup.object({
        ReasonsToInitiatePip: yup.object().shape({
            label: yup.string().required("Please select a reason"),
            value: yup.string().required("Please select a reason"),
        }),
        PlanDescription: yup.string()
            .max(500, "max length is 500")
            .required("Please Enter Plan Description"),
        StartDate: yup
            .date().required("Please select a start date"),
        EndDate: yup.date().when("StartDate", (StartDate) => {
            if (StartDate?.[0]) {
                return yup
                    .date()
                    .min(StartDate?.[0], "End date cannot be less than start date")
                    .required("Please select an end date");
            } else {
                return yup.date().required("Please select an end date");
            }
        }),
        ReviewOnDates: yup
            .array(
                yup.object().shape({
                    ReviewDate: yup
                        .date()
                        .test(
                            "date-range-test",
                            "ReviewDates should lie between start date and end date",
                            (value) => {
                                if (selectedDates?.startDate && selectedDates?.endDate) {
                                    let newDate = moment(value, "DD/MM/YYYY");
                                    return newDate.isBetween(
                                        selectedDates.startDate,
                                        selectedDates.endDate
                                    );
                                }
                            }
                        )
                        .required("Please select a review date"),
                    Time: yup.string().required("Please select a time"),
                })
            )
            .unique("Review Dates should be unique", (obj) =>
                new Date(obj.ReviewDate).toDateString()
            ),
    });

    const CustomHeaderTitle = (title) => {
        return (
            <Box display="flex" flexDirection="row" alignItems="center">
                <IconButton>
                    <LeaderboardIcon color="primary" />
                </IconButton>
                <Typography component="div" variant="h2">
                    {title}
                </Typography>
            </Box>
        );
    };
    return (
        <Formik
            initialValues={InitatePIPModalInitialValues}
            validationSchema={initiatePipSchema}
            onSubmit={async (values) => {
                console.log(values, 'values')
                setIsOpen(false)
            }}
        >
            {({
                values,
                errors,
                handleChange,
                handleBlur,
                touched,
                handleSubmit,
            }) => (
                <CustomModal
                    title={CustomHeaderTitle('Initiate Performance Improvement Plan (PIP)')}
                    size='md'
                    open={isOpen}
                    onSubmit={handleSubmit}
                    onModalclose={() => handleModalClose()}
                    cancelButton='Cancel'
                    submitButton='Submit'
                >
                    <Form autoComplete="off">
                        <Box padding={1} sx={{ maxHeight: 'calc(100vh - 210px)', overflow: 'auto' }}>
                            <Box className="w-100 start-flex schedule__employee__heading">
                                Employee Name: <span className="schedule__employee">
                                    <CustomAvatar alt=""
                                        sx={{
                                            width: 24,
                                            height: 24,
                                            marginRight: "10px"
                                        }}
                                        src={
                                            employeeData?.USER_PROFILE_IMAGE ??
                                            `https://robohash.org/${employeeData?.EMPLOYEE_NAME}`
                                        }
                                    />
                                    {employeeData?.EMPLOYEE_NAME ?? '--'} ({employeeData?.EMPLOYEE_CODE ?? '--'})
                                </span>
                            </Box>
                            <Box display={'flex'} flexDirection={'column'} pt={1} gap={1} >
                                <Box>
                                    <CustomSelect
                                        id="ReasonsToInitiatePip"
                                        options={ReasonsToInitatePIPOptions}
                                        name="ReasonsToInitiatePip"
                                        placeholder="Select Reasons"
                                        label="Resons to initiate pip"
                                        value={values?.ReasonsToInitiatePip}
                                        onChange={(selectedOption) => {
                                            console.log(selectedOption, 'pip options')
                                            let event = {
                                                target: {
                                                    name: "ReasonsToInitiatePip",
                                                    value: selectedOption,
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        error={
                                            Boolean(errors?.["ReasonsToInitiatePip"]) &&
                                            Boolean(touched?.["ReasonsToInitiatePip"])
                                        }
                                        helperText={errors?.["ReasonsToInitiatePip"]?.label}
                                        className="w-100"
                                        required
                                    />
                                </Box>
                                <Box>
                                    <CustomInput
                                        name="PlanDescription"
                                        id="PlanDescription"
                                        required
                                        multiline
                                        minRows={3}
                                        color="secondary"
                                        label="Performance Improvement Plan"
                                        maxLength={500}
                                        value={values?.PlanDescription?.substring(0, 500)}
                                        onChange={(e) => {
                                            setPipPlan(e?.target?.value)
                                            console.log(e?.target?.value, 'pip plan')
                                            let event = {
                                                target: {
                                                    name: "PlanDescription",
                                                    value: e.target.value,
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        FormHelperTextProps={{
                                            style: pip__details__error,
                                        }}
                                        helperText={errors?.["PlanDescription"]}
                                        error={Boolean(errors?.["PlanDescription"] &&
                                            Boolean(touched?.["PlanDescription"]))}
                                        className="w-100"
                                    />
                                    <div style={{ display: 'flex', justifyContent: 'right' }}>{pipPlan?.length}/500</div>
                                </Box>
                                <Box>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sx={{ fontWeight: 600, fontSize: '16px' }} >
                                            Select Start Date and End Date
                                        </Grid>
                                        {[
                                            {
                                                name: "StartDate",
                                                id: "StartDate",
                                                label: "Choose start date",
                                                value: values?.StartDate,
                                                error:
                                                    Boolean(errors?.["StartDate"]) &&
                                                    Boolean(touched?.["StartDate"]),
                                                helperText: errors?.["StartDate"],
                                                onChange: (e) => {
                                                    let event = {
                                                        target: {
                                                            name: "StartDate",
                                                            value: moment(e)
                                                                .format("YYYY-MM-DD HH:MM:SS")
                                                                .slice(0, 10),
                                                        },
                                                    };
                                                    setSelectedDates((pre) => {
                                                        return {
                                                            ...pre,
                                                            startDate: moment(e)
                                                                .format("YYYY-MM-DD HH:MM:SS")
                                                                .slice(0, 10),
                                                        };
                                                    });
                                                    handleChange(event);
                                                },
                                            },
                                            {
                                                name: "EndDate",
                                                id: "EndDate",
                                                label: "Choose end date",
                                                value: values?.EndDate,
                                                error:
                                                    Boolean(errors?.["EndDate"]) &&
                                                    Boolean(touched?.["EndDate"]),
                                                helperText: errors?.["EndDate"],
                                                onChange: (e) => {
                                                    let event = {
                                                        target: {
                                                            name: "EndDate",
                                                            value: moment(e)
                                                                .format("YYYY-MM-DD HH:MM:SS")
                                                                .slice(0, 10),
                                                        },
                                                    };
                                                    setSelectedDates((pre) => {
                                                        return {
                                                            ...pre,
                                                            endDate: moment(e)
                                                                .format("YYYY-MM-DD HH:MM:SS")
                                                                .slice(0, 10),
                                                        };
                                                    });
                                                    handleChange(event);
                                                },
                                            },
                                        ].map((fields, index) => (
                                            <Grid xs={6} item>
                                                <MuiPickersUtilsProvider
                                                    key={fields.id}
                                                    utils={DateFnsUtils}
                                                >
                                                    <KeyboardDatePicker
                                                        {...fields}
                                                        required
                                                        inputVariant="outlined"
                                                        format="yyyy/MM/dd"
                                                        autoComplete="off"
                                                        color="secondary"
                                                        animateYearScrolling={true}
                                                        views={["month", "date"]}
                                                        openTo={"date"}
                                                        FormHelperTextProps={{
                                                            style: pip__details__error,
                                                        }}
                                                        className={`w-100 ${newStyles.keyboardDatePicker}`}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                error={fields?.error}
                                                                helperText={fields?.helperText}
                                                            />
                                                        )}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                        ))}
                                        <Grid item xs={12} sx={{ fontWeight: 600, fontSize: '16px' }}>
                                            Review On
                                        </Grid>
                                        <FieldArray name="ReviewOnDates">
                                            {({ push, remove }) => (
                                                <>
                                                    {values?.ReviewOnDates?.map((__, index) => (
                                                        <>
                                                            <Field
                                                                fullWidth
                                                                name={`ReviewOnDates.${index}.ReviewDate`}
                                                                render={({ form }) => (
                                                                    <Grid item xs={6}>
                                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                            <KeyboardDatePicker
                                                                                name={`ReviewOnDates.${index}.ReviewDate`}
                                                                                id={`ReviewOnDates.${index}.ReviewDate`}
                                                                                value={
                                                                                    form?.values?.ReviewOnDates?.[index]
                                                                                        ?.ReviewDate
                                                                                }
                                                                                error={
                                                                                    Boolean(
                                                                                        form?.errors?.ReviewOnDates?.[
                                                                                        index
                                                                                        ]?.["ReviewDate"]
                                                                                    ) &&
                                                                                    Boolean(
                                                                                        form?.touched?.ReviewOnDates?.[
                                                                                        index
                                                                                        ]?.["ReviewDate"]
                                                                                    )
                                                                                }
                                                                                helperText={
                                                                                    form?.errors?.ReviewOnDates?.[index]?.[
                                                                                    "ReviewDate"
                                                                                    ]
                                                                                }
                                                                                label="Review Date"
                                                                                required
                                                                                inputVariant="outlined"
                                                                                format="yyyy/MM/dd"
                                                                                autoComplete="off"
                                                                                color="secondary"
                                                                                FormHelperTextProps={{
                                                                                    style: pip__details__error,
                                                                                }}
                                                                                onChange={(e) => {
                                                                                    form.handleChange({
                                                                                        target: {
                                                                                            name: `ReviewOnDates.${index}.ReviewDate`,
                                                                                            value: moment(e)
                                                                                                .format("YYYY-MM-DD")
                                                                                                .slice(0, 10),
                                                                                        },
                                                                                    });
                                                                                }}
                                                                                animateYearScrolling={true}
                                                                                views={["year", "month", "date"]}
                                                                                openTo={"date"}
                                                                                className={`w-100 ${newStyles.keyboardDatePicker}`}
                                                                                renderInput={(params) => (
                                                                                    <TextField {...params} />
                                                                                )}
                                                                            />
                                                                        </MuiPickersUtilsProvider>
                                                                    </Grid>
                                                                )}
                                                            />

                                                            <Field
                                                                fullWidth
                                                                name={`ReviewOnDates.${index}.Time`}
                                                                render={({ form }) => (
                                                                    <Grid item xs={6}>
                                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                            <KeyboardTimePicker
                                                                                inputVariant="outlined"
                                                                                name={`ReviewOnDates.${index}.Time`}
                                                                                id={`ReviewOnDates.${index}.Time`}
                                                                                label="Time"
                                                                                required
                                                                                value={
                                                                                    form?.values?.ReviewOnDates?.[index]
                                                                                        ?.Time
                                                                                }
                                                                                onChange={(date, value) => {
                                                                                    form.handleChange({
                                                                                        target: {
                                                                                            name: `ReviewOnDates.${index}.Time`,
                                                                                            value:
                                                                                                moment(date).format(
                                                                                                    "YYYY-MM-DD HH:mm"
                                                                                                ),
                                                                                        },
                                                                                    });
                                                                                }}
                                                                                onBlur={() =>
                                                                                    form.handleBlur({
                                                                                        target: {
                                                                                            name: `ReviewOnDates.${index}.Time`,
                                                                                        },
                                                                                    })
                                                                                }
                                                                                FormHelperTextProps={{
                                                                                    style: pip__details__error,
                                                                                }}
                                                                                color="secondary"
                                                                                error={
                                                                                    Boolean(
                                                                                        form?.errors?.["ReviewOnDates"]?.[
                                                                                        index
                                                                                        ]?.["Time"]
                                                                                    ) &&
                                                                                    Boolean(
                                                                                        form?.touched?.["ReviewOnDates"]?.[
                                                                                        index
                                                                                        ]?.["Time"]
                                                                                    )
                                                                                }
                                                                                helperText={
                                                                                    form?.errors?.["ReviewOnDates"]?.[
                                                                                    index
                                                                                    ]?.["Time"]
                                                                                }
                                                                                ampm={true}
                                                                                KeyboardButtonProps={{ size: "small" }}
                                                                                keyboardIcon={
                                                                                    <Schedule
                                                                                        style={{
                                                                                            width: 18,
                                                                                            height: 18,
                                                                                            color: "#172B4D",
                                                                                        }}
                                                                                    />
                                                                                }
                                                                                className={`w-100 ${newStyles.timePicker}`}
                                                                                renderInput={(params) => (
                                                                                    <TextField {...params} />
                                                                                )}
                                                                            />
                                                                        </MuiPickersUtilsProvider>
                                                                    </Grid>
                                                                )}
                                                            />
                                                            {index !== 0 && <Grid xs={12} item>
                                                                <DeleteOutlinedIcon
                                                                    sx={{
                                                                        color: "#A5A3A9",
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() => {
                                                                        remove(index);
                                                                    }}
                                                                /></Grid>}
                                                        </>
                                                    ))}
                                                    {typeof errors?.["ReviewOnDates"] === "string" ? (
                                                        <p className="pip__initiate_errors">
                                                            {errors?.["ReviewOnDates"]}
                                                        </p>
                                                    ) : (
                                                        ""
                                                    )}
                                                    < Grid item xs={12}>
                                                        <CustomButton
                                                            label="Add Another Field"
                                                            variant="text"
                                                            color={'secondary'}
                                                            endIcon={<AddCircleOutlineIcon />}
                                                            size="sm"
                                                            onClick={() => push({ ReviewDate: null, Time: null })}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                        </FieldArray>
                                    </Grid>
                                </Box>
                            </Box>
                        </Box>
                    </Form>
                </CustomModal>
            )
            }
        </Formik >
    )
}

export default InitiatePipModal;