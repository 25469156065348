import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./rootSaga";
import ReduxPromise from "redux-promise";
import { composeWithDevTools } from "redux-devtools-extension";

import rootReducer from "./rootReducer";

import "regenerator-runtime/runtime";
import SecureLocalStorage from "../utitlies/SecureLocalStorage";
const tokenError = [
  "Error: Invalid Token",
  "Error: Your session has expired, kindly relogin.",
  "Error: Your session has been expired, kindly relogin.",
  "Error: Unauthorized access.",
];

// Stores store data in LocalStorage
const saveToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    SecureLocalStorage.setItem("state", serializedState);
  } catch (e) {

  }
};

// Gets store data from LocalStorage
const loadFromLocalStorage = () => {
  try {
    const serializedState = SecureLocalStorage.getItem("state");

    if (serializedState === null) {
      return undefined;
    } else {
      return JSON.parse(serializedState);
    }
  } catch (e) {

    return undefined;
  }
};
const updateRootReducer = (state, action) => {
  if (state) {
    const { message = "" } = state.adminMessageReducer;
    if (tokenError.indexOf(message) > -1) {
      // SecurelocalStorage.clear();
      state = undefined;
      window.location.replace(`${window.location.origin}/`);
    }
    if (action.type === "LOGOUT_USER" || action.type === "LOGIN_USER") {
      // SecurelocalStorage.clear();
      state = undefined;
    }
  }
  return rootReducer(state, action);
};

// const persistedState = loadFromLocalStorage();

// const sagaMiddleware = createSagaMiddleware();
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const store = createStore(
//   updateRootReducer,
//   persistedState,
//   applyMiddleware(sagaMiddleware)
// );
const sagaMiddleware = createSagaMiddleware();

const createStoreWithMiddleware = applyMiddleware(ReduxPromise)(createStore);
const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });

// const store = createStore(
//   updateRootReducer,
//   persistedState,
//   process.env.NODE_ENV === "development"
//     ? composeEnhancers(applyMiddleware(sagaMiddleware))
//     : applyMiddleware(sagaMiddleware)
// );
const store = createStoreWithMiddleware(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(rootSaga);

// Everytime the store changes, it will update the local storage
// store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;
