import { useMutation, useQueryClient } from "react-query";

/**
 * @author Manish Kumar
 * @param {*} mutationFunction 
 * @param {*} queryKey 
 * @param {*} config 
 * @returns 
 */
export default function useConfigurableMutation(mutationFunction, queryKey, config = {}) {
    const queryClient = useQueryClient();
    const { onSuccess, onError, onSettled, ...rest } = config;

    return useMutation(mutationFunction, {
        ...rest,
        onSuccess: async (data, variables, context) => {
            await queryClient.invalidateQueries(queryKey);
            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        onError: (error, variables, context) => {
            if (onError) {
                onError(error, variables, context);
            }
        },
        onSettled: (data, error, variables, context) => {
            if (onSettled) {
                onSettled(data, error, variables, context);
            }
        },
        onMutate: async (variables) => {
            const controller = new AbortController();
            const signal = controller.signal;
            // Add the abort signal to the mutation context
            return { variables, context: { signal } };
        },
    });
}