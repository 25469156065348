import {
  FormControlLabel,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  InputLabel,
  OutlinedInput,
  FormControl,
  Divider,
} from "@material-ui/core";
import * as React from "react";
import { useState } from "react";
import { useFormik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import RateReviewIcon from "@mui/icons-material/RateReview";
import "./stylesModal.css";
import {
  CustomAvatar,
  CustomButton,
  CustomInput,
  CustomModal,
  DynamicForm,
} from "@zimyo/components";
import { alpha, styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Rating from "@mui/material/Rating";
import { employeeFeedbackValidate } from "../../../components/schemas/index";
import ImageIcon from "@mui/icons-material/Image";
import DescriptionIcon from "@mui/icons-material/Description";
import { ReactComponent as PdfView } from "../../../icons/pdfView.svg";
import TextReducingTooltipComponent from "../../../components/TextReducingTooltipComponent";
import useConfigurableMutation from "../../../hooks/useMutation";
import useLoadingIndicator from "../../../hooks/useLoadingIndicator";
import { useDispatch, useSelector } from "react-redux";
import {
  createEmployeeFeedback,
  fetchEmployeeNameIdFeedback,
  fetchFeedbackListData,
  fetchSearchFieldOptions,
} from "./../../apiResponse";
import { useQueryClient } from "react-query";
import {
  SUCCESS_MESSAGE_FROM_SERVER,
  ERROR_MESSAGE_FROM_SERVER,
} from "../../../redux/message/constant";
import isEqual from "lodash/isEqual";
import CustomSelect from "../../../components/CustomSelect";
import MultiSelect from "../../../components/MultiSelect";
import { debounce } from "lodash";
import { useFetch } from "../../../hooks/useFetch";
import { CustomFullScreenModal } from "@zimyo/components";
import apiPaths from "../../../config/apiPath.json";
import {
  getAxiosForuseFetch,
  postAxiosForuseFetch,
} from "../../../Api/axiosPrivate";
import { useForm } from "react-hook-form";
import {
  convertFormMasterTypeToDynamicFormType,
  validateDataType,
  VALID_DATA_TYPES,
} from "../../../components/FormInputType";
import isEmpty from "lodash/isEmpty";
import { jsonToFormData } from "../../../utitlies/commonFunction";
import Loader from "../../../components/Loader";

const StyledSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#80ACFF",
    "&:hover": {
      backgroundColor: alpha("#80ACFF", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#80ACFF",
  },
}));


const initialValues = {
  feedbacktitle: "",
  feedbackdescription: "",
};

const MAX_COUNT = 10;
const FeedbackModal = ({
  isModalOpen,
  handleModalState,
  handleFeedbackAvailableData,
  employeeData = {},
}) => {
  const dispatch = useDispatch();
  const { user, token } = useSelector(
    (state) => state.adminUserReducer,
    isEqual
  );
  const [employeeSkillRate, setEmployeeSkillRate] = useState(false);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [addSkillRating, setAddSkillRating] = useState([]);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [isEmployeeSearchLoading, setIsEmployeeSearchLoading] = useState(false);

  // const [skillDataOptions, setSkillDataOptions] = useState(skillData);

  const [ratingSkillOptions, setRatingSkillOptions] = useState([]);
  const [skillValue, setSkillValue] = useState({});
  const [skillName, setSkillName] = useState("");

  const queryClient = useQueryClient();

  const {
    control,
    register,
    trigger,
    watch,
    reset: resetFormValues,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {},
  });

  const { data: getFormFields, isFetching: isFormFieldsFetching } = useFetch(
    getAxiosForuseFetch,
    {
      token,
      url: apiPaths.GET_FORM_FIELDS,
      params: {
        form_id: 71,
      },
    },
    "formFieldValues"
  );

  const { data: getSearchFieldValue = {} } = useFetch(
    fetchSearchFieldOptions,
    { token },
    "fetchSearchFieldOptions",
    {
      cacheTime: 50 * 60 * 1000, // 50 minutes
      staleTime: 50 * 60 * 1000,
    }
  );

  const { data: ratingSkillsList } = useFetch(
    getAxiosForuseFetch,
    {
      token,
      url: apiPaths.RATING_SKILLS_LIST,
      params: {

      },
      reqToEngage: true
    },
    "ratingSkillsList",
    {
      enabled: Boolean(isModalOpen),
      onError: ({ response }) => {
        dispatch({
          type: ERROR_MESSAGE_FROM_SERVER,
          payload: response?.data?.message,
        });
      },
    }
  );

  const handleSelectFieldOptions = (options) => {
    return options && validateDataType(options, VALID_DATA_TYPES.ARRAY)
      ? options.map((el) => {
        return { value: el?.id, label: el?.name };
      })
      : [];
  };

  const formFields = React.useMemo(() => {
    const options =
      !isEmpty(getFormFields) &&
      validateDataType(getFormFields, VALID_DATA_TYPES.OBJECT) &&
      Object.keys(getFormFields).map((fields) => {
        return (
          !isEmpty(getFormFields[fields]) &&
          validateDataType(getFormFields[fields], VALID_DATA_TYPES.OBJECT) &&
          Object.keys(getFormFields[fields]).map((item) => {
            const fieldOptions = handleSelectFieldOptions(
              getSearchFieldValue[
              getFormFields[fields][item]?.MASTER_TYPE !== 0
                ? getFormFields[fields][item]?.MASTER_TYPE
                : getFormFields[fields][item]?.FIELD_SLUG
              ] ?? []
            );
            return {
              name: getFormFields[fields][item]?.FIELD_SLUG,
              type: convertFormMasterTypeToDynamicFormType(
                getFormFields[fields][item]?.FEILD_TYPE
              ),
              required: Boolean(getFormFields[fields][item]?.IS_MANDATORY),
              id: getFormFields[fields][item]?.FIELD_SLUG,
              label: getFormFields[fields][item]?.FIELD_NAME,
              placeholder: getFormFields[fields][item]?.FIELD_NAME,
              options: fieldOptions,
              value: "",
              itemLayout: {
                lg: 12,
                xs: 12,
                md: 12,
                sm: 12,
              },
            };
          })
        );
      });

    return options.length > 0 ? options?.[0] : [];
  }, [getFormFields, getSearchFieldValue]);

  React.useEffect(() => {
    if (employeeData?.hasOwnProperty("EMPLOYEE_NAME")) {
      setAssignedUsers({
        label: employeeData?.EMPLOYEE_NAME,
        value: employeeData?.EMPLOYEE_ID,
      });
    }
  }, []);

  const getAutoComplete = debounce(async (inputValue, callback) => {
    try {
      setIsEmployeeSearchLoading(true);
      const data = await fetchEmployeeNameIdFeedback({
        token,
        employeeName: inputValue,
      });
      if (data?.data?.error) {
        setIsEmployeeSearchLoading(false);
        dispatch({
          type: "ERROR_MESSAGE_FROM_SERVER",
          payload: data?.data?.message ?? "Something Went Wrong",
        });
        return [];
      }
      if (data?.data?.data && Array.isArray(data?.data?.data)) {
        callback(
          data?.data?.data?.map((employee) => {
            return {
              value: employee?.EMPLOYEE_ID,
              label: `${employee?.NAME} (${employee?.EMAIL_ID})`,
            };
          })
        );
        setIsEmployeeSearchLoading(false);
      }
    } catch (error) {
      setIsEmployeeSearchLoading(false);
    }
  }, 300);

  const createFeedback = useConfigurableMutation(
    postAxiosForuseFetch,
    "createEmployeeFeedback",
    {
      onSuccess: (response) => {
        setIsSubmitButtonDisabled(false);

        if (response?.data?.error) {
          dispatch({
            type: ERROR_MESSAGE_FROM_SERVER,
            payload: response?.data?.message ?? "Something Went Wrong",
          });
        } else {
          queryClient.invalidateQueries("FeedbackDetails");
          dispatch({
            type: SUCCESS_MESSAGE_FROM_SERVER,
            payload: response?.data?.message || "Success",
          });
          handleModalClose()
        }
      },
      onError: ({ response }) => {
        setIsSubmitButtonDisabled(false);
        dispatch({
          type: ERROR_MESSAGE_FROM_SERVER,
          payload: response?.data?.message || "Error",
        });
      },
    }
  );

  React.useEffect(() => { handleSetRatingSkillOptions(ratingSkillsList) }, [ratingSkillsList]);


  const handleSetRatingSkillOptions = (ratingSkillsList) => {
    if (validateDataType(ratingSkillsList?.ROWS, VALID_DATA_TYPES.ARRAY) && !createFeedback.isLoading) {
      const options = ratingSkillsList?.ROWS
        .filter((item) => item?.STATUS !== 0)
        .map((el) => ({
          value: el?.ID,
          label: el?.SKILL_NAME,
        }));

      setRatingSkillOptions([...options]);
      setSkillValue(options?.[0] ?? {});
      setSkillName(options?.[0]?.label ?? "");
    }
  }


  const {
    values,
    errors: errorsFormik,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: employeeFeedbackValidate,
    onSubmit: async (values) => {
      let otherValues = {};
      try {
        let val = await trigger();
        if (val) {
          otherValues = JSON.stringify(watch());
        } else {
          // in case val is empty OR trigger results in false
          return;
        }
      } catch (err) {
        console.log({ err });
        return;
      }

      const { feedbacktitle, feedbackdescription } = values;
      if (assignedUsers.length <= 0) {
        dispatch({
          type: "ERROR_MESSAGE_FROM_SERVER",
          payload: "Please Search And Select Employee Name",
        });
        return;
      }

      const filesArray = [...uploadedFiles, ...selectedImages];
      // filesArray.forEach((file) => {
      //   form.append(file.name, file);
      // });
      let postData = {
        FILES: filesArray,
        FEEDBACK_TITLE: feedbacktitle,
        FEEDBACK_CONTENT: feedbackdescription,
        FEEDBACK_FOR: assignedUsers?.value ?? employeeData?.EMPLOYEE_ID,
        HAS_SKILLS: employeeSkillRate ? 1 : 0,
        SKILL_RATING: JSON.stringify(addSkillRating),
        MODULE_TYPE: 1,
        SEND_EMAIL: 1,
        CUSTOM_FORM: otherValues,
      };

      const payload = jsonToFormData(postData);
      // form.append("FILES", JSON.stringify(filesArray));
      // form.append("FEEDBACK_TITLE", feedbacktitle);
      // form.append("FEEDBACK_CONTENT", feedbackdescription);
      // form.append(
      //   "FEEDBACK_FOR",
      //   assignedUsers?.value ?? employeeData?.EMPLOYEE_ID
      // );
      // form.append("HAS_SKILLS", employeeSkillRate ? 1 : 0);
      // form.append("SKILL_RATING", JSON.stringify(addSkillRating));
      // form.append("MODULE_TYPE", 1);
      // form.append("SEND_EMAIL", 1);
      // form.append("CUSTOM_FORM", otherValues);
      createFeedback.mutate({
        token,
        url: apiPaths.CREATE_FEEDBACK_FORM,
        data: payload,
        reqToEngage: true
      });
    },
  });

  // File upload
  const onSelectFile = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    setSelectedImages((previousImages) =>
      previousImages.concat(selectedFilesArray)
    );
    event.target.value = "";
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    files.forEach((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) setUploadedFiles(uploaded);
  };

  function deleteHandlerFile(file) {
    setUploadedFiles(uploadedFiles.filter((e) => e !== file));
    URL.revokeObjectURL(file);
  }
  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  const handleSkillName = (e, value) => {
    console.log("handleSkillName", e)
    setSkillName(e?.label);
    setSkillValue(e);
  };
  const handleAddSkill = () => {
    setAddSkillRating([...addSkillRating, { NAME: skillName, RATING: 1 }]);
    const filteredArray = ratingSkillOptions.filter(
      (item) => item?.value !== skillValue?.value
    );
    setRatingSkillOptions(filteredArray);
    if (filteredArray.length > 0) {
      setSkillName(filteredArray[0]?.label);
      setSkillValue(filteredArray[0]);
    }
  };
  const handleRatingChange = (e, i) => {
    addSkillRating[i].RATING = Number(e?.target?.value);
    setAddSkillRating([...addSkillRating]);
  };


  const handleDeleteSkill = (i) => {
    let total = [...addSkillRating];
    const deleteItem = total[i]
    total.splice(i, 1);
    setAddSkillRating([...total]);
    const filteredItem = ratingSkillsList.ROWS.find((item) => item.SKILL_NAME === deleteItem.NAME);
    if (filteredItem) setRatingSkillOptions((prev) => [...prev, { label: filteredItem?.SKILL_NAME, value: filteredItem?.ID }])

  };

  const handleModalClose = () => {
    setSelectedImages([]);
    setUploadedFiles([]);
    setEmployeeSkillRate(false);
    handleModalState(false);
    setUploadedFiles([]);
    setSelectedImages([]);
    setAddSkillRating([]);
    handleSetRatingSkillOptions(ratingSkillsList);
    let availabelormFields = {};

    formFields.forEach((el) => {
      availabelormFields[el?.name] = "";
    });
    resetFormValues({
      ...availabelormFields,
    });
    resetForm()
  };

  return (
    <>

      <Loader visible={createFeedback.isLoading} />

      <CustomFullScreenModal
        open={isModalOpen}
        handleClose={handleModalClose}
        title={
          <Box className="w-100 flex-start-center g-10">
            <RateReviewIcon />
            <Typography variant="body2">Employee Feedback</Typography>
          </Box>
        }
      >
        <div
          style={{
            height: "calc(100vh - 100px)",
            overflow: "hidden",
            display: "flex",
          }}
        >
          <div
            className="w-100"
            style={{
              gap: "10px",
              padding: "1.5rem",
              overflow: "auto",
              flex: 7.5,
            }}
          >
            <Box className="w-100 flex-start-center">
              <div>Feedback for: </div>

              {typeof employeeData === "object" &&
                employeeData?.hasOwnProperty("EMPLOYEE_NAME") ? (
                <div className="schedule__employee">
                  <CustomAvatar
                    alt=""
                    sx={{
                      width: 24,
                      height: 24,
                      marginRight: "10px",
                    }}
                    src={
                      employeeData?.USER_PROFILE_IMAGE ??
                      `https://robohash.org/${employeeData?.EMPLOYEE_NAME}`
                    }
                  />
                  {employeeData?.EMPLOYEE_NAME ?? "--"} (
                  {employeeData?.EMPLOYEE_CODE ?? "--"})
                </div>
              ) : (
                <div
                  className="schedule__employee_search"
                  style={{ width: "300px" }}
                >
                  <MultiSelect
                    className="w-100"
                    placeholder="Choose employee"
                    isLoading={isEmployeeSearchLoading}
                    cacheOptions={false}
                    isMulti={false}
                    onChange={(value) => {
                      setAssignedUsers(value);
                    }}
                    value={assignedUsers}
                    loadOptions={getAutoComplete ?? []}
                    controlStyles={{ minHeight: "40px" }}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 99999999999,
                      }),
                    }}
                    menuPortalTarget={document.body}
                  />
                </div>
              )}
            </Box>

            <Box sx={{ pt: 2, pb: 1 }} className="wh-100 flex-column g-10">
              <Box className="w-100">
                <FormControl
                  variant="outlined"
                  style={{ height: "auto" }}
                  className="w-100 "
                >
                  <CustomInput
                    style={{ height: "auto" }}
                    margin="none"
                    color={"secondary"}
                    autoComplete="off"
                    className="w-100 "
                    id="feedbacktitle"
                    label="Feedback Title"
                    variant="outlined"
                    name="feedbacktitle"
                    value={values?.feedbacktitle}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></CustomInput>
                  <div className="flex-between-center">
                    <p className="form-error">{errorsFormik?.feedbacktitle}</p>
                    <span
                      style={{
                        textAlign: "right",
                        color: "#172b4d",
                        float: "right",
                        marginTop: "10px",
                      }}
                    >
                      {values?.feedbacktitle?.length || 0}/100.
                    </span>
                  </div>
                </FormControl>
              </Box>

              <Box className="w-100">
                <FormControl
                  variant="outlined"
                  style={{ height: "auto" }}
                  className="w-100 "
                >
                  <CustomInput
                    style={{ height: "auto" }}
                    margin="none"
                    multiline
                    rows={3}
                    color={"secondary"}
                    autoComplete="off"
                    className="w-100 "
                    id="feedbackdescription"
                    label="Feedback Content"
                    variant="outlined"
                    name="feedbackdescription"
                    value={values?.feedbackdescription}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></CustomInput>
                  {errorsFormik.feedbackdescription &&
                    touched.feedbackdescription ? (
                    <p className="form-error">
                      {errorsFormik.feedbackdescription}
                    </p>
                  ) : null}
                </FormControl>
              </Box>

              <Box className="w-100">
                <FormControlLabel
                  style={{ marginLeft: "0px" }}
                  control={
                    <StyledSwitch checked={Boolean(employeeSkillRate)} />
                  }
                  label="Rate Employee Skills"
                  labelPlacement="start"
                  onChange={(e) => {
                    setEmployeeSkillRate(e.target.checked);
                  }}
                />
                {employeeSkillRate && (
                  <Box
                    style={{ display: employeeSkillRate ? "block" : "none" }}
                  >
                    <Box className=" w-100 flex-start-center g-10">
                      <CustomSelect
                        width={400}
                        id="Skill"
                        label="Skill"
                        placeholder="Choose Skill"
                        name="name"
                        value={skillValue}
                        onChange={(e, value) => {
                          handleSkillName(e, value);
                        }}
                        options={ratingSkillOptions}
                        styles={{
                          indicatorSeparator: () => ({ display: "none" }),
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 99999999999,
                            width: "600px",
                          }),
                          control: (base) => ({
                            ...base,
                            minHeight: "40px",
                            width: "600px",
                          }),
                        }}
                        menuPortalTarget={document.body}
                      />

                      {ratingSkillOptions.length ? (
                        <Button className="add_skill" onClick={handleAddSkill}>
                          Add Skill
                        </Button>
                      ) : (
                        <></>
                      )}
                    </Box>
                    {Array.isArray(addSkillRating) && addSkillRating.length ? (
                      <>
                        <Grid container className="mt-20">
                          <Grid item md={4}>
                            <Typography>Skill(s)</Typography>
                          </Grid>
                          <Grid item md={4}>
                            <Typography>Rate Skill</Typography>
                          </Grid>
                        </Grid>
                        {addSkillRating.map((item, i) => (
                          <Grid
                            container
                            className="mt-20"
                            key={i}
                            alignItems="center"
                          >
                            <Grid item md={4}>
                              <div className="skill__btns">
                                <TextReducingTooltipComponent
                                  data={item?.NAME}
                                  limit={30}
                                />
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <Box className="item__centerdirector">
                                <Stack spacing={1}>
                                  <Rating
                                    value={item?.RATING}
                                    onChange={(e, newValue) => {
                                      handleRatingChange(e, i);
                                    }}
                                  />
                                </Stack>
                                <div
                                  className="closeRatingicon"
                                  onClick={() => handleDeleteSkill(i)}
                                >
                                  <CloseIcon />
                                </div>
                              </Box>
                            </Grid>
                          </Grid>
                        ))}
                      </>
                    ) : null}
                  </Box>
                )}
              </Box>

              <div className="footer__upload_assets">
                <label style={{ cursor: "pointer", marginRight: "10px" }}>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={onSelectFile}
                    multiple
                    accept="image/png , image/jpeg, image/webp"
                  />
                  <span>
                    <ImageIcon />
                  </span>
                </label>

                <label style={{ cursor: "pointer" }}>
                  <input
                    id="fileUpload"
                    type="file"
                    multiple
                    accept="application/pdf, application/vnd.ms-excel"
                    onChange={handleFileEvent}
                    disabled={fileLimit}
                    style={{ display: "none" }}
                  />
                  <span>
                    <DescriptionIcon />
                  </span>
                </label>
              </div>

              {selectedImages.length > 0 && (
                <Box className="main__upload__box w-100">
                  {selectedImages.map((image, index) => {
                    return (
                      <Box key={image} className="all__upload__images">
                        <img
                          src={URL.createObjectURL(image)}
                          height="200"
                          alt="upload"
                        />
                        <button onClick={() => deleteHandler(image)}>
                          <CloseIcon
                            style={{ width: "20px", height: "20px" }}
                          />
                        </button>
                      </Box>
                    );
                  })}
                </Box>
              )}

              {uploadedFiles.length > 0 && (
                <Box className="main__upload__box w-100">
                  {uploadedFiles.map((file) => (
                    <Box className="pdfView__preview">
                      <Box style={{ marginRight: "10px" }}>
                        <p>
                          <PdfView style={{ width: "30px", height: "30px" }} />
                        </p>{" "}
                      </Box>
                      <Box>
                        <p>
                          <TextReducingTooltipComponent
                            data={file?.name}
                            limit={20}
                          />
                        </p>
                      </Box>
                      <button onClick={() => deleteHandlerFile(file)}>
                        <CloseIcon style={{ width: "20px", height: "20px" }} />
                      </button>
                    </Box>
                  ))}
                </Box>
              )}

              {/* <div className="feedback_alert">
                Note: 1. Feedback will only be visible to managers and admin
              </div> */}
            </Box>
          </div>

          {Array.isArray(formFields) && formFields.length > 0 && (
            <>
              <Divider orientation="vertical" flexItem />

              <div
                style={{
                  gap: "10px",
                  padding: "1.5rem",
                  overflow: "auto",
                  flex: 3.5,
                }}
              >
                <DynamicForm
                  containerLayout={{
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 12,
                    xl: 12,
                  }}
                  control={control}
                  register={register}
                  errors={errors}
                  fields={formFields}
                />
              </div>
            </>
          )}
        </div>

        <Box
          className="w-100 flex-end-center g-10"
          style={{ paddingRight: "1.5rem" }}
        >
          <CustomButton
            disableElevation
            variant="outlined"
            size="small"
            onClick={() => {
              handleModalClose();
            }}
            label="Cancel"
          />
          <CustomButton
            disableElevation
            variant="contained"
            color="primary"
            size="small"
            // onClick={handleSubmitCombine}
            onClick={handleSubmit}
            disabled={isSubmitButtonDisabled}
            label="Submit"
          />
        </Box>
      </CustomFullScreenModal>
    </>
  );
};

export default FeedbackModal;