import {
  GET_ORG_UNITS_LISTS,
  GET_ORG_UNITS_LISTS_SUCCESS,
  ORG_UNITS_PAGE_NUMBER,
  CREATE_ORG_UNITS_SUCCESS,
  ORG_UNITS_RESET_SAVE_FROM_DATA_SUCCESS,
  GET_ORG_MENU_SUCCESS,
  GET_ORGANISATION_STRUCTURE_SUCCESS,
} from "./constant";

const initialValue = {
  orgUnitData: {},
  saveFormRequestCompleted: false,
  page: 1,
  orgMenusAccess: [],
  orgStructureData: [],
};

const reducer = (state = initialValue, action) => {
  const { type, payload } = action;
  // 
  switch (type) {
    case GET_ORG_UNITS_LISTS_SUCCESS:
      return {
        ...state,
        orgUnitData: payload.orgUnitData,
      };
    case GET_ORG_MENU_SUCCESS:
    case ORG_UNITS_PAGE_NUMBER:
      return { ...state, ...payload };
    case CREATE_ORG_UNITS_SUCCESS:
      return { ...state, saveFormRequestCompleted: true };
    case ORG_UNITS_RESET_SAVE_FROM_DATA_SUCCESS:
      return { ...state, saveFormRequestCompleted: false };
    case GET_ORGANISATION_STRUCTURE_SUCCESS:
      return { ...state, orgStructureData: payload };
    default:
      return { ...state };
  }
};
export default reducer;
