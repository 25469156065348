import { List, ListItem, ListItemText, Popover } from "@mui/material";
import "./CustomPopover.css";

const CustomPopover = ({
  isPopoverOpen,
  anchorPosition,
  onClose = () => {},
  PopoverMenu,
}) => {
  return (
    <Popover
      open={isPopoverOpen}
      anchorPosition={anchorPosition}
      anchorReference={"anchorPosition"}
      onClose={onClose}
    >
      <List className="popover-list">
        {Array.isArray(PopoverMenu) &&
          PopoverMenu.map((item, index) => (
            <ListItem
              key={index}
              onClick={item.onClick}
              sx={{ cursor: "pointer" }}
            >
              <ListItemText
                primaryTypographyProps={{
                  style: {
                    fontWeight: 520,
                    color: "inherit",
                  },
                }}
                primary={item.title}
              />
            </ListItem>
          ))}
      </List>
    </Popover>
  );
};

export default CustomPopover;
