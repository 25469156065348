import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { START_LOADER, END_LOADER } from "../redux/auth/constant";

const useLoadingIndicator = (isFetching) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (isFetching) {
      dispatch({ type: START_LOADER });
    } else {
      dispatch({ type: END_LOADER });
    }
  }, [isFetching , dispatch ]);
};

export default useLoadingIndicator;
