import { all, fork } from "redux-saga/effects";

import authSaga from "./auth/saga";
import sessionSaga from "./session/saga";
export default function* root() {
  yield all([
    fork(authSaga),
    fork(sessionSaga)
  ]);
}
