import { Beacon } from "@beacon.li/bar";
import { Box } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import { EngageProvider } from "@zimyo/engage";
import "antd/dist/antd.css";
import axios from "axios";
import React, { Suspense, useCallback, useEffect, useMemo, useRef } from "react";
import { IntlProvider } from "react-intl";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import AccountHelperComponent from "./AccountHelperComponent";
import { PlaceHolderComponet } from "./PlaceHolderComponent";
import AlertComponent from "./components/AlertComponent";
import Header from "./components/Header";
import SecondaryHeader from "./components/Header/secondaryHeader";
import LeftNavigation from "./components/LeftSideNaviagtion/LeftNavigation";
import Loader from "./components/Loader";
import { rootDictionary } from "./language/index";
import ErrorPage from "./pages/ErrorPage";
import { redirection } from "./redux/auth/action";
import { ADMIN_LOGOUT_USER_SUCCESS } from "./redux/auth/constant";
import { CLEAR_MESSAGE_FROM_SERVER } from "./redux/message/constant";
import {
  authenticateUser,
  getOrgMenu,
  isLoadingPage,
  isUserAuthenicated,
  logoutUser,
  onRefresh,
} from "./redux/rootAction";
import { PublicRoute, internalFixedAuthRoutes, routesMapping } from "./routes";
import "./style/appStyles.scss";
import "./style/pms.style.css";
import { clearCookies, flattenMessages } from "./utils/Utils";
import { getEssAndAdminUrls, redirectToInitialEss } from "./utitlies/commonFunction";

const AppRoute = React.lazy(() => import("./routes/AppRoutes"));

const App = () => {
  const dispatch = useDispatch();

  const queryClient = useQueryClient();
  const previousMenuAccess = useRef();
  const {
    token,
    user,
    loader_parent,
    menu_access,
    menu_access_tree,
    message,
    type,
    loadingPage,
    authencatedUser,
    language: { locale }
  } = useSelector((state) => ({
    token: state.adminUserReducer.token,
    user: state.adminUserReducer.user,
    loader_parent: state.adminUserReducer.loader_parent,
    menu_access: state.adminUserReducer.menu_access,
    menu_access_tree: state.adminUserReducer.menu_access_tree,
    message: state.adminMessageReducer.message,
    type: state.adminMessageReducer.type,
    loadingPage: state.adminLocalStateReducer.loadingPage,
    authencatedUser: state.adminLocalStateReducer.authencatedUser,
    language: state.languageReducer
  }));
  const localeLanguage = useMemo(() => { return flattenMessages(rootDictionary[locale]) }, [locale]);

  const urlToken = useRef(
    new URL(window.location.href).searchParams.get("token") || ""
  ).current;

  useEffect(() => {
    if (token) {
      dispatch(
        authenticateUser({
          headers: { token: token },
        })
      );
    }
  }, [token, urlToken, type, message, dispatch]);

  const getUrlOrMenus = useCallback(() => {
    if (user?.v2_login === 1) {
      dispatch(getOrgMenu({ headers: { token: urlToken || token } }));
    }

    loadingPage && dispatch(isLoadingPage(false));
  }, [dispatch, loadingPage, token, urlToken, user]);

  useEffect(() => {
    Beacon.load("TVYBWY");
  }, []);

  useEffect(() => {
    const checkAuth = (token, dispatch) => {
      clearCookies();
      axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}guest/user/authenticateToken`,
          {
            headers: { token: token },
          }
        )
        .then(function (response) {
          dispatch(redirection({ headers: { token: token } }));
        })
        .catch(function (error) {
          clearCookies()
          dispatch(logoutUser({ headers: { token } }));
          window.location.href = "/";
        });
    };

    if (urlToken.length > 0) {
      checkAuth(urlToken, dispatch);
    }
  }, [dispatch, urlToken]);

  useEffect(() => {
    if (!token && !urlToken && !localStorage.getItem("adminUserDetails")) {
      const { essUrl, adminUrl, } = getEssAndAdminUrls()
      redirectToInitialEss(essUrl, adminUrl,)
    }
  }, [token, urlToken])

  useEffect(() => {
    const updateMessage = (timeout = 2000) => {
      if (message || type) {
        if (message === "Logged out successfully.") {
          const clear_timeout = setTimeout(() => {
            clearTimeout(clear_timeout);
            dispatch({ type: CLEAR_MESSAGE_FROM_SERVER });
            dispatch({ type: ADMIN_LOGOUT_USER_SUCCESS });
            clearCookies()
          }, 2000);
        } else if (
          type === "error" &&
          (message === "Your session has expired, kindly login again." ||
            message === "Your session has expired, kindly login again." || message === 'Invalid Token.')
        ) {
          clearCookies()
          window.location.href = "/";
        } else {
          const clear_timeout = setTimeout(() => {
            clearTimeout(clear_timeout);
            dispatch({ type: CLEAR_MESSAGE_FROM_SERVER });
            if (message === "Logged out successfully.") {
              dispatch({ type: ADMIN_LOGOUT_USER_SUCCESS });
              clearCookies()
            }
          }, timeout);
        }
      }
    };

    if (type || message) {
      updateMessage(1200);
    }
  }, [dispatch, type, message]);

  useEffect(() => {
    if (typeof window.process === "undefined") {
      window.process = {};
    }

    if (!token && !urlToken && localStorage.getItem("adminUserDetails")) {
      dispatch(onRefresh());
      getUrlOrMenus();
    }

    if (!token && !localStorage.getItem("adminUserDetails")) {
      dispatch(isUserAuthenicated(false));
      clearCookies()
    }
  }, [dispatch, token, urlToken, getUrlOrMenus]);

  useEffect(() => {
    if (authencatedUser) {
      getUrlOrMenus();
    }
  }, [authencatedUser, getUrlOrMenus]);

  useEffect(() => {
    if (token) {
      dispatch(
        authenticateUser({
          headers: { token: token },
        })
      );
    }
  }, [token, urlToken, type, message, dispatch]);

  useEffect(() => {
    const currentMenuAccess = menu_access;
    if (
      previousMenuAccess.current?.length === 0 &&
      Array.isArray(currentMenuAccess) &&
      currentMenuAccess?.length > 0
    ) {
      Beacon.loadUser(user?.user_emp_id, {
        menu_slugs_allowed: currentMenuAccess?.map((el) => el?.menu_slug),
      });
    }
    previousMenuAccess.current = currentMenuAccess;
  }, [menu_access, user]);

  useEffect(() => {
    console.log("app js mounted");
  }, []);

  return loadingPage ? (
    <div className="wh-vw-vh flex-center">
      <CircularProgress />
    </div>
  ) : (
    <IntlProvider messages={localeLanguage} locale={locale}>
      <EngageProvider queryClient={queryClient} token={token} API_BASE_URL={process.env.REACT_APP_ENGAGE_SERVER_URL} ASSETS_BASE_URL={process.env.REACT_APP_S3_URL} ADMIN_BASE_URL={`${process.env.REACT_APP_HRMS_API_URL}apiv2/`} PMS_API_BASE_URL={process.env.REACT_APP_SERVER_URL}>
        <BrowserRouter basename="/">
          {message ? <AlertComponent type={type} message={message} /> : null}
          {loader_parent ? <Loader visible={loader_parent} /> : null}

          {token && user ? (
            <AccountHelperComponent token={token} user={user} />
          ) : null}

          <Container
            maxWidth={false}
            disableGutters={true}
            className="wh-vw-vh start-flex flex-column overflow-hidden"
          >
            {token && user ? (
              <>
                <Header />
                <SecondaryHeader />
                <div className="w-100" style={{ height: '1.5px' }} />
              </>
            ) : null}

            <Box sx={{ zIndex: "9999" }} className="start-flex wh-100">
              {token && user ? (
                <Box sx={{ zIndex: 1 }} className="h-100">
                  <LeftNavigation />
                </Box>
              ) : null}

              <Suspense
                fallback={
                  <div
                    className="wh-vw-vh flex-center"
                    style={{
                      zIndex: 9999999999,
                    }}
                  >
                    <CircularProgress />
                  </div>
                }
              >
                <Routes>
                  {authencatedUser && Object.keys(routesMapping).length ? (
                    <>
                      {(menu_access || []).map((menuData, index) => {
                        let routeValue = routesMapping?.[menuData?.menu_layout];
                        // if(!routeValue?.component){
                        //   console.log({routeValue , menuData})
                        // }

                        return routeValue ? (
                          <Route
                            path={
                              menuData.url ? `/${menuData.url}` : routeValue.path
                            }
                            key={`auth_` + index}
                            element={
                              <AppRoute
                                propsObj={{
                                  token,
                                  leftNavigation: true,
                                  ...routeValue,
                                  name: menuData?.name || routeValue?.name,
                                }}
                                component={routeValue.component}
                                exact={routeValue.exact}
                                isAuthProtected={true}
                              />
                            }
                          />
                        ) : (
                          <Route
                            path={menuData.url || ""}
                            element={<ErrorPage />}
                            key={"error" + index}
                          />
                        );
                      })}

                      {internalFixedAuthRoutes.map((routeValue, index) => {
                        // console.log({routeValue})
                        return (
                          <Route
                            path={routeValue.path}
                            key={`auth_` + index}
                            element={
                              <AppRoute
                                propsObj={{
                                  token,
                                  leftNavigation: true,
                                  ...routeValue,
                                }}
                                component={routeValue.component}
                                exact={routeValue.exact}
                                isAuthProtected={true}
                              />
                            }
                          />
                        );
                      })}
                    </>
                  ) : null}

                  {PublicRoute.map((routeValue, index) => (
                    <Route
                      path={routeValue.path}
                      key={`guest_` + index}
                      element={
                        <AppRoute
                          propsObj={{
                            token,
                            withHeader: false,
                            rpaths:
                              menu_access_tree?.[0]?.children?.[0]?.url ||
                              menu_access_tree?.[0]?.url,
                          }}
                          component={routeValue.component}
                          exact={routeValue.exact}
                          isAuthProtected={false}
                          path={routeValue.path}
                        />
                      }
                    />
                  ))}

                  {token && !loadingPage ? (
                    <Route
                      path="/"
                      element={
                        <Navigate
                          replace
                          to={`/${menu_access_tree?.[0]?.children?.[0]?.url ||
                            menu_access_tree?.[0]?.url ||
                            ""
                            }`}
                        />
                      }
                    />
                  ) : !token && !localStorage.getItem("adminUserDetails") ? (
                    <Route path="*" element={<PlaceHolderComponet />} />
                  ) : null}
                </Routes>
                {/* </BrowserRouter> */}
              </Suspense>
            </Box>
          </Container>
        </BrowserRouter>
      </EngageProvider>
    </IntlProvider>
  );
};

export default App;
