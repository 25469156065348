import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Stack from "@material-ui/core/Stack";
import ArrowForward from "@material-ui/icons/ArrowForward";
import axios from "axios";
import isEqual from "lodash/isEqual";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormInputType } from "../../components/FormInputType";
import config from "../../config/configFile.json";
import { makeStyles } from "@material-ui/styles";
import { loginUser } from "../../redux/rootAction";
import { validateEmail } from "../../components/utitlies/commonFunction";
import AppStoreImage from '../../assests/svg/AppStore.svg';
import PlayStoreImage from '../../assests/svg/PlayStore.svg';
import LogoImage from '../../assests/svg/Logo.svg';
import { FormattedMessage, useIntl } from "react-intl";

const HRMS_BASE_URL = process.env.REACT_APP_HRMS_BASE_URL


const LoginPage = () => {
  const history = useNavigate();
  const url = new URL(window.location.href);
  const app_callback_url = url.searchParams.get("app_callback_url");
  const { type, message = "", show_otp_input = false } = useSelector(
    (state) => state.adminMessageReducer,
    isEqual
  );

  // const [logo, setLogo] = useState("../../public/img/svg/Logo.svg");
  const [timeLeft, setTimeLeft] = useState(60);

  const styles = makeStyles(() => ({
    inputLabel: {
      color: "black",
      "&.focused": {
        color: "#335AFF",
      },
    },
  }));



  const dispatch = useDispatch();
  const intl = useIntl();
  const [disableButton, setDisableButton] = useState(false);
  const [loginInfo, setloginInfo] = useState({
    username: "",
    password: ""
  });

  const [error, seterror] = useState({ username: "", password: "", otp: "" });



  useEffect(() => {
    if (
      type === "error" &&
      (message === "Your session has expired, kindly relogin." ||
        message === "Your session has been expired, kindly relogin.")
    ) {
      window.location.href = '/'
      // window.open([`${window.ZIMYO.CONFIG.ADMIN_AUTH_API}`], "_self");
    }
  }, [message, type]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    if (show_otp_input && loginInfo?.otp) {
      loginInfo['otp'] = "";
    }
    loginInfo[`${name}`] = value;
    if (error[`${name}`]) {
      if (error.username === "Enter the valid Email" && validateEmail(loginInfo.username)) {
        seterror({ ...error, username: "" });
      } else {
        error[`${name}`] = "";
        seterror({ ...error });
      }
    }

    setloginInfo({ ...loginInfo });
  };

  const buttonReset = () => {
    setTimeout(function () {
      setDisableButton(false);
    }, 5000);
  };

  const onLoginIn = (e, button_type = 'login') => {
    e.preventDefault();
    const { username, password } = loginInfo;
    if (show_otp_input && !loginInfo.hasOwnProperty('otp')) {
      loginInfo['otp'] = '';
    }
    if (button_type === 'resend_otp') {
      delete loginInfo['otp'];
    }
    if (username.trim().length === 0) {
      return seterror({ ...error, username: "Please enter the username" });
    }
    if (password.trim().length === 0) {
      return seterror({ ...error, password: "Please enter the password." });
    }
    if (show_otp_input && loginInfo.hasOwnProperty('otp') && loginInfo.otp.length === 0) {
      return seterror({ ...error, otp: "Please enter otp." });
    }
    if (show_otp_input && loginInfo.hasOwnProperty('otp') && loginInfo?.otp && loginInfo.otp.length < 6) {
      return seterror({ ...error, otp: "Please enter valid otp." });
    }
    setDisableButton(true);
    buttonReset();
    let postData = { username: username, password: password };
    if (show_otp_input && loginInfo?.otp) {
      postData = { ...postData, otp: loginInfo?.otp };
    }
    if (button_type === 'resend_otp' && show_otp_input) {
      setTimeLeft(60);
      delete postData['otp'];
      postData = { ...postData, resend_otp: true }
    }
    dispatch(
      loginUser({
        data: postData,
        headers: { app_name: "account" },
        callback_url: app_callback_url,
        history,
      })
    );
  };

  const onKeyUp = (e) => {
    if (e.charCode === 13 || e.which === 13 || e.keyCode === 13) {
      onLoginIn({ preventDefault: () => { } });
    }
  };

  useEffect(() => {
    if (show_otp_input) {
      if (!timeLeft) return;
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [timeLeft, show_otp_input]);

  useEffect(() => {
    if (type) {
      setDisableButton(false);
    }
  }, [type]);

  return (
    <React.Fragment>
      <Grid
        sx={{ pl: 7, pr: 7, pt: 4, pb: 4 }}
        container
        className="wh-100 wrap-flex start-flex overflow-overlay"
      >
        <Grid item className="w-100 flex-end-start">
          <img src={LogoImage} alt="Logo" />
        </Grid>
        <Grid item sx={{ mt: 4 }} className="w-100 flex-column start-flex">
          <Typography variant="subtitle1" sx={{ fontSize: "1.75rem" }} id="body">
            <FormattedMessage id="login.title" defaultMessage="Login" />
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "1rem", mt: 2 }}>
            <FormattedMessage id="login.notRegistered" defaultMessage="Not yet registered?" />&nbsp;
            <Link
              underline="none"
              variant="body1"
              color="secondary"
              component="button"
              sx={{ fontSize: "1rem" }}
              onClick={() => history("/register")}
            >
              <FormattedMessage id="login.signUp" defaultMessage="Sign Up" />
            </Link>
          </Typography>
          <form className='w-100' autoComplete="on">
            <Box className="w-100" sx={{ mt: 5 }}>
              <TextField
                autoFocus
                required
                type="text"
                InputLabelProps={{
                  classes: {
                    root: styles().inputLabel,
                    focused: "focused",
                  },
                }}
                defaultValue=" "
                value={loginInfo.username || " "}
                // style={{ marginBottom: 25 }}
                // onChange={(e) =>
                //   setNewEmployee({
                //     ...newEmployee,
                //     EMP_CODE: e.target.value,
                //   })
                // }
                onChange={(e) => handleChange(e)}
                style={error.username ? {} : { marginBottom: 30 }}
                name="username"
                variant="outlined"
                className="w-100"
                label={intl.formatMessage({ id: "login.emailLabel", defaultMessage: "Email or Mobile number" })}
                placeholder={intl.formatMessage({ id: "login.emailLabel", defaultMessage: "Email or Mobile number" })}
                autoComplete="on"
                helperText={error.username}
                error={error.username ? true : false}
                FormHelperTextProps={{
                  style: {
                    fontWeight: 400,
                    fontSize: 12,
                    color: "#ED3833",
                    marginBottom: 10,
                    marginTop: 4,
                    textAlign: "right",
                  },
                }}

              // error={error.username && error.username.trim() !== ""}
              // {...(error.username && {
              //   error: true,
              //   helperText: <p style={{ fontSize: 8 }}>{error.username}</p>,
              // })}
              />
              {/* <form onSubmit={onLoginIn} autoComplete="off" noValidate> */}
              {[
                // {
                //   label: "Email or Mobile number",
                //   name: "username",
                //   type: "input",
                //   value: loginInfo.username || "",
                //   error: error.username,
                //   className: "w-100",
                //   IS_MANDATORY: 1,
                // },
                {
                  label: intl.formatMessage({ id: "login.passwordLabel", defaultMessage: "Password" }),
                  name: "password",
                  type: "password",
                  value: loginInfo.password || "",
                  error: error.password,
                  className: "w-100",
                  IS_MANDATORY: 1,
                },
              ].map((item, index) => (
                <FormInputType
                  key={index}
                  FIELD_NAME={item.label}
                  FEILD_TYPE={item.type}
                  FIELD_SLUG={item.name}
                  FEILD_VALUES={item.value || ""}
                  handleChange={(e) => handleChange(e)}
                  IS_MANDATORY={item.IS_MANDATORY}
                  error={item.error}
                  labelWidth={item.labelWidth}
                  helperText={item.error}
                  className={item.className}
                  style={item.style}
                  onKeyUp={onKeyUp}
                />
              ))}

              {show_otp_input ? (
                <FormInputType
                  FIELD_NAME={"Enter otp"}
                  FEILD_TYPE={"text"}
                  FIELD_SLUG={"otp"}
                  FEILD_VALUES={loginInfo?.otp}
                  handleChange={(e) => handleChange(e)}
                  error={error?.otp}
                  labelWidth={100}
                  className={"w-100"}
                  style={{ marginBottom: error ? 11 : 30 }}
                  IS_MANDATORY={true}
                  helperText={error?.otp}
                />
              ) : null}

              {/* <input
              ref={ref}
              type="submit"
              value="Submit"
              style={{ display: "none" }}
            />
          </form> */}
            </Box>
          </form>
          <Box className="w-100 flex-end-center">
            <Link
              underline="none"
              variant="body1"
              component="button"
              className="w-auto"
              align="right"
              sx={{ color: "#757575", mb: 3, mr: timeLeft && show_otp_input ? 5 : '' }}
              onClick={() => window.location.replace(`${HRMS_BASE_URL}forgotpassword`)}
            >
              <FormattedMessage id="login.forgotPassword" defaultMessage="Forgot Password" />
            </Link>
            {
              timeLeft && show_otp_input ? (
                <Link
                  underline="none"
                  variant="body1"
                  component="button"
                  className="w-auto"
                  align="right"
                  sx={{ color: "#757575", mb: 3 }}
                >
                  {'Resend Otp In ' + timeLeft + ' Second'}
                </Link>
              ) : null
            }
          </Box>
          {
            show_otp_input && timeLeft <= 0 ? (
              <Button
                className="w-100"
                variant="contained"
                // color="secondary"
                id="resend_otp"
                size="large"
                disableElevation
                onClick={(e) => onLoginIn(e, 'resend_otp')}
                disabled={disableButton}
                style={{
                  marginBottom: 20,
                  color: "#172B4D",
                  fontSize: 16,
                  fontWeight: 400,
                  backgroundColor: "white",
                  border: "1.5px solid #EBECF0",
                }}
              >
                Resend Otp
                <Box className="flex-center">
                  <ArrowForward sx={{ ml: 1 }} />
                </Box>
              </Button>
            ) : ''
          }
          <Button
            className="w-100"
            variant="contained"
            color="secondary"
            size="large"
            disableElevation
            onClick={(e) => onLoginIn(e, 'login')}
            disabled={disableButton}
            id="login"
          >
            <FormattedMessage id="login.title" defaultMessage="Login" />
            <Box className="flex-center">
              <ArrowForward sx={{ ml: 1 }} />
            </Box>
          </Button>

          {/* <Button
          className="w-100"
          variant="outlined"
          color="secondary"
          size="large"
          disableElevation
          style={{ marginTop: 20 }}
          onClick={() => window.location.replace(`${ config["hrms-url"]}`)}
        >
          Login to HRMS 1.0
          <Box className="flex-center">
            <ArrowForward sx={{ ml: 1 }} />
          </Box>
        </Button> */}
          <Button
            className="w-100"
            variant="contained"
            size="large"
            disableElevation
            style={{
              marginTop: 20,
              color: "#172B4D",
              fontSize: 16,
              fontWeight: 400,
              backgroundColor: "white",
              border: "1.5px solid #EBECF0",
            }}
            onClick={() => window.location.replace(`${config["okta-url"]}`)}
          >
            <FormattedMessage id="login.loginWithOkta" defaultMessage="Login With Okta" />
          </Button>
          <Button
            className="w-100"
            variant="contained"
            size="large"
            disableElevation
            style={{
              marginTop: 20,
              color: "#172B4D",
              fontSize: 16,
              fontWeight: 400,
              backgroundColor: "white",
              border: "1.5px solid #EBECF0",
            }}
            onClick={() => {
              history("/login-sso");
            }}
          >
            <FormattedMessage id="login.loginWithSso" defaultMessage="Login With SSO" />
          </Button>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6} sx={{ mt: 3 }}>
              <Button
                variant="text"
                href="https://apps.apple.com/us/app/zimyo/id1579008301"
                target="_blank"
                fullWidth
              >
                <img
                  src={AppStoreImage}
                  alt="app store"
                  style={{ height: "auto", maxWidth: "100%" }}
                />
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={6} sx={{ mt: 3 }}>
              <Button
                variant="text"
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.zimyo.hrms"
                fullWidth
              >
                <img
                  src={PlayStoreImage}
                  alt="app store"
                  style={{ height: "auto", maxWidth: "100%" }}
                />
              </Button>
            </Grid>
          </Grid>

          {/*<Stack spacing={1} direction="row" style={{ marginTop: 30 }}>*/}
          {/*  <Button*/}
          {/*    sx={{ ml: 1 }}*/}
          {/*    variant="text"*/}
          {/*    href="https://apps.apple.com/us/app/zimyo/id1579008301"*/}
          {/*    target="_blank"*/}
          {/*  >*/}
          {/*    <img src="/img/svg/AppStore.svg" alt="app store" />*/}
          {/*  </Button>*/}
          {/*  <Button*/}
          {/*    variant="text"*/}
          {/*    target="_blank"*/}
          {/*    href="https://play.google.com/store/apps/details?id=com.zimyo.hrms"*/}
          {/*  >*/}
          {/*    <img src="/img/svg/PlayStore.svg" alt="app store" />*/}
          {/*  </Button>*/}
          {/*</Stack>*/}
        </Grid>
      </Grid>
    </React.Fragment>

  );
};

export default LoginPage;
