import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  GET_ACTIVE_SESSION,
  GET_ACTIVE_SESSION_SUCCESS,
  GET_SESSION_HISTORY,
  GET_SESSION_HISTORY_SUCCESS,
  KILL_SESSION,
  KILL_SESSION_SUCCESS,
} from "./constant";
import {
  ERROR_MESSAGE_FROM_SERVER,
  SUCCESS_MESSAGE_FROM_SERVER,
} from "../message/constant";
import axios from "../../config/connection";

function* getSessionHistory({ payload }) {
  try {
    const response = yield call(axios, {
      ...payload,
      nextUrl: "apiv2/auth/login-history",
    });
    if (response.error) {
      yield put({ type: ERROR_MESSAGE_FROM_SERVER, payload: response.message });
    } else {
      yield put({
        type: GET_SESSION_HISTORY_SUCCESS,
        payload: response.data.login_history,
      });
    }
  } catch (err) { }
}
function* getActiveSession({ payload }) {
  try {
    const response = yield call(axios, {
      ...payload,
      nextUrl: "apiv2/auth/active-session",
    });

    if (response.error) {
      yield put({ type: ERROR_MESSAGE_FROM_SERVER, payload: response.message });
    } else {
      yield put({
        type: GET_ACTIVE_SESSION_SUCCESS,
        payload: response.data.active_sessions,
      });
    }
  } catch (err) { }
}
function* killSession({ payload }) {
  try {
    const response = yield call(axios, {
      method: "post",
      ...payload,
      nextUrl: "apiv2/auth/kill-session",
    });
    if (response.error) {
      yield put({ type: ERROR_MESSAGE_FROM_SERVER, payload: response.message });
    } else {
      yield put({
        type: SUCCESS_MESSAGE_FROM_SERVER,
        payload: response.message,
      });
      yield put({
        type: KILL_SESSION_SUCCESS,
        payload: payload.data.token,
      });
    }
  } catch (err) { }
}
export default function* root() {
  yield all([
    takeLatest(GET_SESSION_HISTORY, getSessionHistory),
    takeLatest(GET_ACTIVE_SESSION, getActiveSession),
    takeLatest(KILL_SESSION, killSession),
  ]);
}
