import { Box } from "@mui/material";
import { isEqual } from "lodash";

import "../myTeam.styles.scss";
import "../../../modules/continuous_feedback/employeeAppreciation/EmployeeAppreciation.css";
import useLoadingIndicator from "../../../hooks/useLoadingIndicator";
import EmployeeAppreciationList from "../../../modules/continuous_feedback/employeeAppreciation/EmployeeAppreciationList";

import apiPaths from '../../../config/apiPath.json'
import { FEEDBACK_TABS_ALL } from "../constants";
import { useState } from "react";
import { APPRECIATION_LIST } from '../../../modules/continuous_feedback/employeeAppreciation/apiPath'
import { ERROR_MESSAGE_FROM_SERVER } from "../../../redux/message/constant";
import { useDispatch, useSelector } from "react-redux";
import { useFetch } from "../../../hooks/useFetch";
import EmployeeAppreciationFilters from "../../continuous_feedback/employeeAppreciation/EmployeeAppreciationFilters";
import FeedbackCard from "./FeedbackCard";

import OneToOneCard from "./OneToOneCard";
import CustomToggleButton from "../../../components/CustomToggleButtons/CustomToggleButton";
import {
  VALID_DATA_TYPES,
  validateDataType,
} from "../../../components/FormInputType";
import { getAxiosForuseFetch, postAxiosForuseFetch } from "../../../Api/axiosPrivate";

import { AppreciationList, OneToOneList, ContinuousFeedbackList } from "@zimyo/engage"

const ToggleButtonData = [
  {
    label: "Appreciation",
    value: FEEDBACK_TABS_ALL.APPRECIATION_TAB,
  },

  {
    label: "One To One",
    value: FEEDBACK_TABS_ALL.ONE_TO_ONE_TAB,
  },

  {
    label: "Continuous Feedback",
    value: FEEDBACK_TABS_ALL.FEEDBACK_TAB,
  },
];

const ContinuosFeedback = ({ employee_id }) => {
  const dispatch = useDispatch();
  const { user, token } = useSelector(
    (state) => state.adminUserReducer,
    isEqual
  );
  const [menuSelected, setMenuSelect] = useState(
    FEEDBACK_TABS_ALL.APPRECIATION_TAB
  );
  const [appreciationFilters, setAppreciationFilters] = useState({
    MONTH_FILTER: 0,
  });

  const handleChangetab = (e, index) => {
    setMenuSelect(index);
    setAppreciationFilters((pre) => ({
      ...pre,
      MONTH_FILTER: 0,
    }));
  };

  const {
    data: appreciationList,
    isFetching: isAppreciationListFetching,
  } = useFetch(
    getAxiosForuseFetch,
    {
      token,
      url: APPRECIATION_LIST,
      params: {
        FILTER_TYPE: 4,
        EMPLOYEE_ID: employee_id,
        ...appreciationFilters
      },
      reqToEngage: true,
    },
    [
      "appreciationEmployeeList",
      FEEDBACK_TABS_ALL.APPRECIATION_TAB,
      appreciationFilters,
    ],
    {
      enabled: menuSelected === FEEDBACK_TABS_ALL.APPRECIATION_TAB,
      onSuccess: (res) => {
        if (res?.data?.error) {
          dispatch({
            type: ERROR_MESSAGE_FROM_SERVER,
            payload: res?.data?.message ?? "Something Went Wrong",
          });
        }
      },
      onError: ({ response }) => {
        dispatch({
          type: ERROR_MESSAGE_FROM_SERVER,
          payload: response?.data?.message ?? "Something Went Wrong",
        });
      },
    }
  );

  const {
    data: meetingList,
    isFetching: fetchingMeetingList,
  } = useFetch(
    postAxiosForuseFetch,
    {
      token,
      data: {
        employee_id,
        ...appreciationFilters,
      },
      url: apiPaths.GET_ONE_TO_ONE_MEETINGLIST,
      reqToEngage: true,
    },
    [
      "fetchOneToOneMeetingList",
      appreciationFilters,
      menuSelected === FEEDBACK_TABS_ALL.ONE_TO_ONE_TAB,
    ],
    {
      enabled: menuSelected === FEEDBACK_TABS_ALL.ONE_TO_ONE_TAB,
      onSuccess: (res) => {
        if (res?.data?.error) {
          dispatch({
            type: ERROR_MESSAGE_FROM_SERVER,
            payload: res?.data?.message ?? "Something Went Wrong",
          });
        }
      },
      onError: ({ response }) => {
        dispatch({
          type: ERROR_MESSAGE_FROM_SERVER,
          payload: response?.data?.message ?? "Something Went Wrong",
        });
      },
    }
  );

  const {
    data: feedbackData,
    isFetching: isLoadingFeedData,
  } = useFetch(
    getAxiosForuseFetch,
    {
      token,
      params: {
        FILTER_TYPE: 4,
        employee_id,
        CONCERN: 'self',
        ...appreciationFilters,
      },
      url: apiPaths.GET_FEEDBACK_LIST,
      reqToEngage: true
    },

    "FeedbackDetails",
    {
      enabled: menuSelected === FEEDBACK_TABS_ALL.FEEDBACK_TAB,
      onSuccess: (res) => {
        if (res?.data?.error) {
          dispatch({
            type: ERROR_MESSAGE_FROM_SERVER,
            payload: res?.data?.message ?? "Something Went Wrong",
          });
        }
      },
      onError: ({ response }) => {
        dispatch({
          type: ERROR_MESSAGE_FROM_SERVER,
          payload: response?.data?.message ?? "Something Went Wrong",
        });
      },
    }
  );

  useLoadingIndicator(
    fetchingMeetingList || isLoadingFeedData || isAppreciationListFetching
  );

  return (
    <div>
      <div className="continus___feedback">
        <CustomToggleButton
          tabValue={menuSelected}
          handleChangetab={handleChangetab}
          toggleData={ToggleButtonData}
        />
        <Box
          sx={{
            minHeight: "75vh",
            height: "100%",
          }}
        >
          {menuSelected === FEEDBACK_TABS_ALL.ONE_TO_ONE_TAB && (
            <Box mt={2}>
              <OneToOneList CONCERN="self" employee_id={employee_id} />
            </Box>
          )}
          {menuSelected === FEEDBACK_TABS_ALL.FEEDBACK_TAB && (
            <Box mt={2}>
              <ContinuousFeedbackList CONCERN="self" employee_id={employee_id} />
            </Box>
          )}
          {menuSelected === FEEDBACK_TABS_ALL.APPRECIATION_TAB && (
            <Box mt={2}>
              <AppreciationList CONCERN="self" employee_id={employee_id} />
            </Box>
          )}
        </Box>
      </div>
    </div>
  );
};

export default ContinuosFeedback;
