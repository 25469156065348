
const initialState = {
    locale: (localStorage.getItem('locale') || 'en'),
};

const languageReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_LANGUAGE':
            return {
                ...state,
                locale: action.payload,
            };
        default:
            return state;
    }
};

export default languageReducer;
