const ERROR = {
  NETWORK_ERROR: "Network Error - Make Sure API is Running",
  SERVER_ERROR: "Server Error - Please check the terminal",
  COMMON_ERROR: "Something went wrong.",
};

export const receivedGivenData = [
  {label:'Received' , value:'received'},
  {label:'Given' , value:'given'}
]

export const monthFilterOption = [
  { label: 'All', value: undefined, },
  { label: 'This Month', value: 0 },
  { label: 'Last Month', value: 1 },
  { label: 'This Quarter', value: 2 },
  { label: 'Last Quarter', value: 3 },
  { label: 'This Year', value: 4 },
  { label: 'Last Year', value: 5 }
];



export const current_step = {
  1: "Target Setting",
  2: "Review Level-1",
  3: "Review Level-2",
  4: "Approval Level-1",
  5: "Approval Level-2",
  6: "Self Assessment",
  7: "Peer Assessment",
  8: "Untagged Structure",
  11: "KRA/KPI Mapping",
  12: "KRA/KPI Approval",
  13: "Target Approval"
};

export const concernMap = {
  3: "self",
  2: "team",
  1: "organization",
};

export const kraDesc =
  "KRA (Key Result Area) define the key roles and responsibilities associated with employee's job role.";
export const kpiDesc =
  "KPI (Key Performance Indicator) is a measurable metric used to evaluate performance/progress in achieving the goals.";
export const competencyDesc =
  "Competency refers to the specific skills, knowledge, and abilities an individual possesses and can apply effectively in their role";
export const potentialDesc =
  "Potential represents an individual's capacity and readiness for growth, development, and taking on more significant responsibilities within an organization.";

export const MethodologiesArray = [
  {
    label: "Review (KRA/KPI)",
    description:
      "Evaluating an individual or team's performance by assessing specific Key Result Areas (KRAs) and using Key Performance Indicators (KPIs) as measurable metrics",
    slug: "REVIEW",
    sub_items: [
      {
        label: "KRA",
        description: kraDesc,
        slug: "KRA_LABEL",
        default: "KRA",
      },
      {
        label: "KPI",
        description: kpiDesc,
        slug: "KPI_LABEL",
        default: "KPI",
      },
    ],
  },
  {
    label: "Objectives and Key Results (OKR)",
    forObjectiveOnly : true,
    description:
      "Objectives and Key Results (OKR) is a goal-setting framework that defines clear and measurable objectives alongside key results to track progress and achieve alignment within organizations.",
    slug: "OBJECTIVE",
    sub_items: [
      {
        label: "Objective",
        description:
          "Objectives are clear and specific goals that an individual or organization aims to achieve.",
        slug: "OBJECTIVE_LABEL",
        default: "Objective",
      },
      {
        label: "Key Results",
        description:
          "Key Results (KRs) are measurable and quantifiable outcomes used to assess progress toward achieving objectives.",
        slug: "KEY_RESULT_LABEL",
        default: "Key Results",
      },
    ],
  },
  {
    label: "Competency",
    description: competencyDesc,
    slug: "COMPETENCY",
    default: "Competency",
  },
  {
    label: "Potential",
    description: potentialDesc,
    slug: "POTENTIALS",
    default: "Potential",
  },
];

export const reactSelectStylesDefault = {
  indicatorSeparator: () => ({ display: "none" }),
  menu: (base) => ({
    ...base,
    zIndex: 9999,
    minWidth: "120px",
  }),
  control: (base) => ({
    ...base,
    height: 35,
    minHeight: 35,
    minWidth: "120px",
    border: "1px solid #F5F5F5",
  }),

  singleValue: (provided, state) => ({
    ...provided,
    // color: "#335AFF !important",
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: "32px",
    padding: "0 6px",
  }),

  input: (provided, state) => ({
    ...provided,
    margin: "0px",
    height: "35px",
  }),

  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "35px",
  }),
};

export const SelfSelected = "2";
export const DepartmentSelected = "4";
export const OrganizationSelected = "1";

export const obj_type_mapping = {
  [SelfSelected]: "Individual",
  [DepartmentSelected]: "Department",
  [OrganizationSelected]: "Organization",
};

export const optionsForMyTeam = [
  { label: "Self", value: 1 },
  { label: "RM", value: 2 },
  { label: "Team", value: 3 },
  { label: "Organization", value: 4 },
  { label: "Peer", value: 5 },
  { label: "Department", value: 6 },
  { label: "Designation", value: 7 },
];

export default ERROR;
