import { Typography } from "@mui/material";
import axios from "axios";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import CustomFilter from "../../../../../CustomFilter/CustomFilter";
import { CustomTable } from "@zimyo/components";
import CustomToggleButton from "../../../../../components/CustomToggleButtons/CustomToggleButton";
import { useFetch } from "../../../../../hooks/useFetch";
import { Base64 } from "../../../../../utils/general_pms";
import { fetchSearchFieldOptions } from "../../../../apiResponse";
import "../../../style/reviewLeft.css";
import "../../../style/structure.css";
import { filterMappingForNameKeysMasterObject } from "../../../../../CustomFilter/constants";
import useFilterParsedValue from "../../../../../hooks/useFilterParsedValue";
import { ReactComponent as ExcelIcon } from '../../../../../assests/excel.svg';
import { useIntl } from "react-intl";
import { downloadBufferFile } from "../../../../../utitlies/HelpersFunction";
import API_PATHS from "../../../../../config/apiPath.json"
import { useDispatch } from "react-redux";

export const fetchReviewList = async ({ isAdmin = false, token, params }) => {
  return await axios.get(
    `${process.env.REACT_APP_SERVER_URL}${isAdmin ? "admin" : "employee"
    }/reviewList`,
    { headers: { token }, params: { ...params, adminView: isAdmin ? 1 : 0 } }
  );
};

const filterMappingForNameKeys =
  filterMappingForNameKeysMasterObject?.["filterReviewList"];

const AllSelfAssessmentTableComponent = ({
  assessmentType = "all-self-assessments",
  expired = "0",
  isAdmin = 1,
  approvalStatus = "1",
  completed = "1",
  status = "completed",
  pages_key = null,
  propsObj,
}) => {
  let location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const { user = {} } = useSelector((state) => state.adminUserReducer, isEqual);
  const locale = useSelector((state) => state.languageReducer.locale);
  const [initData, setInitData] = useState({
    approval_status: approvalStatus,
    expired: expired,
    completed: completed,
    page_type: assessmentType,
    APPROVAL_BY: user["user_id"],
    search: "",
    employee_id: user["employee_id"],
    org_id: user["org_id"],
    IS_ADMIN: isAdmin,
    draw: 0,
    status: 0,
    pages_key,
  });
  const [tHeader, setTHeader] = useState(status);
  const [assesmentStatus, setAssesmentStatus] = useState("pending");
  const [tablePagination, setTablePagination] = useState({
    perPage: 20,
    page: 0,
  });

  const intl = useIntl();

  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.adminUserReducer);
  const [filter, setFilter] = useState({});

  const showPreviousStageReportDownload = useMemo(() => propsObj?.menu_layout === "REVIEW_TEAM_APPROVAL" || propsObj?.menu_layout === "REVIEW_TEAM_REVIEW", [pages_key, propsObj]);

  const ToggleData = useMemo(() => {
    return [
      {
        value: "pending",
        label: intl.formatMessage({
          id: "tabs.pending",
          defaultMessage: "Pending",
        }),
      },
      {
        value: "completed",
        label: intl.formatMessage({
          id: "tabs.completed",
          defaultMessage: "Completed",
        }),
      },
      {
        label: intl.formatMessage({
          id: "tabs.expired",
          defaultMessage: "Expired",
        }),
        value: "expired",
      },
    ];
  }, [locale]);

  const apiParams = useMemo(() => propsObj?.apiParams || {}, [propsObj]);

  useEffect(() => {
    if (assessmentType) {
      setInitData((pre) => ({
        ...pre,
        page_type: assessmentType,
      }));
    }
  }, [assessmentType]);

  const filterParsedValue = useFilterParsedValue(
    filter,
    filterMappingForNameKeys
  );

  const {
    data: dataFromApi,
    isFetching,
    refetch,
  } = useFetch(
    fetchReviewList,
    {
      isAdmin,
      token,
      params: {
        ...initData,
        ...filterParsedValue,
        ...apiParams,
        page: String(tablePagination?.page),
        limit: String(tablePagination?.perPage),
      },
    },
    "fetchReviewList",
    {
      // cacheTime: 0, // 5 minutes
      // staleTime: 0,
      cacheTime: 15 * 60 * 1000, // 5 minutes
      staleTime: 15 * 60 * 1000,
      // refetchOnMount: true,
    }
  );

  const [customOptionsMapping, setCustomOptionsMapping] = useState({});

  const { data: getSearchFieldValue = {} } = useFetch(
    fetchSearchFieldOptions,
    { token },
    "fetchSearchFieldOptions",
    {
      cacheTime: 50 * 60 * 1000, // 50 minutes
      staleTime: 50 * 60 * 1000,
    }
  );

  const formFieldValue = useMemo(() => {
    return {
      ...getSearchFieldValue,
    };
  }, [getSearchFieldValue]);

  useEffect(() => {
    if (formFieldValue) {
      setCustomOptionsMapping(formFieldValue);
    }
  }, [formFieldValue]);

  const columns = [
    {
      header: intl.formatMessage({
        id: "table.staticHeaders.employeeCode",
        defaultMessage: "Employee Code",
      }),
      accessorKey: " ",
      id: "EMPLOYEE_CODE",
      cell: (data, value) => {
        let id = Base64.encode(
          value["EMPLOYEE_ID"] +
          "-" +
          value["CYCLE_ID"] +
          "-" +
          value["STR_ID"] +
          "-" +
          value["CYCLE_QUARTER"]
        );
        return (
          <>
            {isAdmin ? (
              <NavLink
                to={`${process.env.REACT_APP_SUBDIR_PATH}review/organization/employee_details/${id}`}
              >
                {value["EMPLOYEE_CODE"]}
              </NavLink>
            ) : (
              value["EMPLOYEE_CODE"]
            )}
          </>
        );
      },
    },
    {
      header: intl.formatMessage({
        id: "table.staticHeaders.employeeName",
        defaultMessage: "Employee Name",
      }),
      accessorKey: "EMPLOYEE_NAME",
      id: "EMPLOYEE_NAME",
    },
    {
      header: intl.formatMessage({
        id: "table.staticHeaders.cycle",
        defaultMessage: "Cycle",
      }),
      accessorKey: "CYCLE_NAME",
      id: "CYCLE_NAME",
    },
    {
      header: intl.formatMessage({
        id: "table.staticHeaders.startDate",
        defaultMessage: "Start Date",
      }),
      accessorKey: "EMPLOYEE_APPROVAL_START_DATE",
      id: "START_DATE",
      cell: (data, value) => {
        let startData = value?.EMPLOYEE_APPROVAL_START_DATE
          ? moment(value?.EMPLOYEE_APPROVAL_START_DATE)
          : "";
        return (
          <>
            {startData ? <>{startData?.format("D MMM Y")}</> : <div>N/A</div>}
          </>
        );
      },
    },
    {
      header: intl.formatMessage({
        id: "table.staticHeaders.endDate",
        defaultMessage: "End Date",
      }),
      accessorKey: "EMPLOYEE_APPROVAL_END_DATE",
      id: "END_DATE",
      cell: (data, value) => {
        let endData = value?.EMPLOYEE_APPROVAL_END_DATE
          ? moment(value?.EMPLOYEE_APPROVAL_END_DATE)
          : "";
        return (
          <>{endData ? <>{endData?.format("D MMM Y")}</> : <div>N/A</div>}</>
        );
      },
    },
    {
      header: intl.formatMessage({
        id: "table.staticHeaders.cycleYear",
        defaultMessage: "Cycle Year",
      }),
      accessorKey: "CYCLE_YEAR",
      id: "CYCLE_YEAR",
    },
    {
      header: intl.formatMessage({
        id: "table.staticHeaders.cycleQuarter",
        defaultMessage: "Cycle Quarter",
      }),
      accessorKey: "CYCLE_QUARTER",
      id: "CYCLE_QUARTER",
    },

    {
      header: intl.formatMessage({
        id: "table.staticHeaders.structureName",
        defaultMessage: "Structure Name",
      }),
      accessorKey: "STRUCTURE_NAME",
      id: "STRUCTURE_NAME",
    },
    {
      header: intl.formatMessage({
        id: "table.staticHeaders.action",
        defaultMessage: "Action",
      }),
      accessorKey: "operation",
      cell: (data, value) => {
        const currentDate = new Date();
        const assessmentStartDate = new Date(
          value["START_DATE"] ? value["START_DATE"] : null
        );

        const startProcess =
          currentDate < assessmentStartDate && value["KPI_KRA_VISIBILITY"] == 1
            ? 0
            : 1;

          const ids = Base64.encode(
            value["ACTION_USER_ID"] +
            "-" +
            value["EMPLOYEE_ID"] +
            "-" +
            value["CYCLE_ID"] +
            "-" +
            value["STR_ID"] +
            "-" +
            value["CYCLE_QUARTER"] +
            "-" +
            value["modules"] +
            "-" +
            startProcess +
            "-" +
            (isAdmin ? "organisation" : "pending_on_me") +
            "-" +
            `${initData?.status}` +
            "-" +
            (value["EMPLOYEE_STAGE"] ?? "")
          );

        const idsForStructureList = Base64.encode(
          value["EMPLOYEE_ID"] +
          "-" +
          value["CYCLE_ID"] +
          "-" +
          value["STR_ID"] +
          "-" +
          value["APP_ID"]
        );

        const isInitiate = !initData?.status && !isAdmin;
        const showDownloadExcel = initData?.status === 1;

        const handleNavigate = () => {
          if (propsObj?.apiParams?.review_stage === 12) {
            if (user?.role_ids?.includes(7)) {
              navigate(
                `${process.env.REACT_APP_SUBDIR_PATH}review/organization/evaluation-structure/view-structure/${idsForStructureList}`,
                { state: { isEmployee: true } }
              );
            } else {
              navigate(
                `${process.env.REACT_APP_SUBDIR_PATH}review/organization/evaluation-structure/view-structure/${idsForStructureList}`,
                { state: { isEmployee: false } }
              );
            }
          } else {
            // const midPath = location.pathname.split("/")?.[2] || "self"
            // navigate(`${process.env.REACT_APP_SUBDIR_PATH}review/${midPath}/${ids}`, {
            //   state: { showDownloadExcel },
            // });
            navigate(`${process.env.REACT_APP_SUBDIR_PATH}review/${ids}`, {
              state: { showDownloadExcel },
            });
          }
        };

        return (
          <div class="btn-group">
            <button
              style={{
                backgroundColor: "rgba(51, 90, 255, 0.05)",
                color: "#4f44e0",
                fontWeight: "600",
                borderRadius: "3px",
                fontSize: "12px",
                height: "auto",
                cursor: "pointer",
                border: "1px solid",
                padding: "4px",
              }}
              onClick={handleNavigate}
            >
              {isInitiate
                ? intl.formatMessage({
                  id: "buttons.initiate",
                  defaultMessage: "Initiate",
                })
                : intl.formatMessage({
                  id: "buttons.viewDetails",
                  defaultMessage: "View Details",
                })}
            </button>
          </div>
        );
      },
    },
  ];

  const downloadPreviousStageReport = async () => {
    try {
      downloadBufferFile(
        dispatch,
        API_PATHS.SINGLE_COLUMN_KRA_KPI_REPORT,
        token,
        `previous_stage_report.xlsx`,
        "post",
        {
          requestType: "excel",
          ...initData,
          ...filterParsedValue,
          ...apiParams,
        }
      );
    } catch (error) {
      console.log(error);
    }
  };


  const handleStatus = useCallback((headerStatus) => {
    setAssesmentStatus(headerStatus);
    switch (headerStatus) {
      case "pending": {
        setInitData((prev) => {
          return {
            ...prev,
            status: 0,
          };
        });
        setTHeader(headerStatus);
        break;
      }
      case "expired": {
        setInitData((prev) => ({
          ...prev,
          status: 2,
        }));
        setTHeader(headerStatus);
        break;
      }
      case "completed": {
        setInitData((prev) => ({
          ...prev,
          status: 1,
        }));
        setTHeader(headerStatus);
        break;
      }
      default: {
        break;
      }
    }
  }, []);

  // useEffect(() => {
  //   if (Array.isArray(state) && state.length > 0) {
  //     setFilter((pre) => ({
  //       ...pre,
  //       "Current Stage": state[0],
  //     }));
  //   }
  // }, [state]);

  useEffect(() => {
    // console.log({state , location})
    if (state?.isExpiredActive) {
      handleStatus("expired");
    }
  }, [state]);

  return (
    <React.Fragment>
      <div
        style={{
          width: "100%",
        }}
      >
        <div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{ marginBottom: "10px" }}
              className="flex-between-center"
            >
              <div>
                <CustomFilter
                  showMainSearch={true}
                  filter={filter}
                  setFilter={setFilter}
                  customOptionsMapping={customOptionsMapping}
                  formFieldValue={formFieldValue}
                  showMoreOptions={true}
                  filterKey={"filterReviewList"}
                  enableURLCaching={false}
                />
              </div>
              <div style={{ display: 'flex', gap: "20px", alignItems: "center", flexGrow: 0, minWidth: "150px" }}>

                {showPreviousStageReportDownload && (
                  <div className="flex-center" style={{ cursor: "pointer" }} onClick={downloadPreviousStageReport}>
                    <ExcelIcon />
                  </div>
                )}
                <CustomToggleButton
                  tabValue={assesmentStatus}
                  handleChangetab={(e, index) => {
                    handleStatus(e.target.value);
                  }}
                  toggleData={ToggleData}
                />
              </div>
            </div>

            <div className="row" style={{ display: "flex" }}>
              <div style={{ width: "100%", height: "calc(100vh - 215px)" }}>
                <CustomTable
                  columns={columns ?? []}
                  data={dataFromApi?.result ?? []}
                  wrapByTooltipDefault
                  // tableLayoutType="fixed"
                  loading={isFetching}
                  pagination={{
                    show: true,
                    totalDataCount: dataFromApi?.total ?? 0,
                    perPageOptions: [20, 30, 50, 100],
                    perPage: tablePagination?.perPage,
                    showFirstButton: true,
                    showLastButton: true,
                    page: tablePagination?.page,
                    handlePageChange: (value) => {
                      setTablePagination({ ...tablePagination, page: value });
                    },
                    handlePerPageChange: (value) => {
                      setTablePagination({
                        ...tablePagination,
                        perPage: value,
                      });
                      // setInitData({ ...initData, limit: value });
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AllSelfAssessmentTableComponent;
