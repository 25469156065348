import {
  SUCCESS_MESSAGE_FROM_SERVER,
  ERROR_MESSAGE_FROM_SERVER,
  CLEAR_MESSAGE_FROM_SERVER,
  START_LOADING,
  END_LOADING,
} from "./constant";
import conf from "../../config/configFile.json";
import { clearCookies } from "../../utils/Utils";

const initialState = {
  type: "",
  message: "",
  globalLoader:false,
};
const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ERROR_MESSAGE_FROM_SERVER:
      if (payload == "Your session has expired, kindly login again.") {
        clearCookies();
        window.location.href = "/";
        return {
          ...state,
          type: "error",
          message:
            typeof payload === "string"
              ? payload
              : Array.isArray(payload)
              ? payload.join(",")
              : "Error",
        };
      } else {
        return {
          ...state,
          type: "error",
          message:
            typeof payload === "string"
              ? payload
              : Array.isArray(payload)
              ? payload.join(",")
              : "Error",
        };
      }
    case SUCCESS_MESSAGE_FROM_SERVER:
      return {
        ...state,
        type: "success",
        message:
          typeof payload === "string"
            ? payload
            : Array.isArray(payload)
            ? payload.join(",")
            : "Success",
      };
    case CLEAR_MESSAGE_FROM_SERVER: {
      return { ...state, type: "", message: "" };
    }
    case START_LOADING: {
      return { ...state, globalLoader: true };
    }
    case END_LOADING: {
      return { ...state, globalLoader: false };
    }
    default:
      return { ...state };
  }
};
export default reducer;
