import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from '@mui/icons-material/Info';
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { alpha, useTheme } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Close from "@material-ui/icons/Close";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useMemo, useState } from "react";
import axios from "../config/connection";
import FileType from "./FileType";
import SelectWithLabel from "./HorizontalAccordion/SelectWithLabel";
import isEmpty from "lodash/isEmpty";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { CustomFileUpload, CustomInput } from "@zimyo/components";
import _ from "lodash";
import moment from "moment";
import TextReducingTooltipComponent from "./TextReducingTooltipComponent";
import {
  getIdsObject,
  isFileExtensionValid,
  titleCase,
  convertArrayToObjectWithKey,
} from "./utitlies/commonFunction";
import { Tooltip } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  noBorder: {
    borderColor: "red",
  },
  textfieldColor: {
    "& .css-pxmnq-MuiInputBase-root-MuiOutlinedInput-root": {
      color: "currentColor",
    },
  },
  datePickerTextColor: {
    "& .css-1ctj566-MuiInputBase-root-MuiOutlinedInput-root": {
      color: "#757575",
    },
  },
  chipStyle: {
    borderRadius: "4px !important",
    color: "#4C70FF !important",
    background: "#E6ECFF !important",
    boxSizing: "border-box !important",
    borderColor: "#bddaff !important",
  },
  tag: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5, 1),
    marginRight: theme.spacing(1),
  },
}));
export const InputPasswordType = (props) => {
  const {
    FIELD_NAME = "",
    FEILD_VALUES = "",
    handleChange = () => { },
    error = "",
    FIELD_SLUG = "",
    className = "w-100",
    IS_MANDATORY = 0,
    onKeyUp = () => { },
    size = "",
  } = props;
  const [showPassword, setShowPassword] = useState(false);
  return (
    <TextField
      onKeyUp={onKeyUp}
      id="outlined-required"
      variant="outlined"
      margin="none"
      type={showPassword ? "text" : "password"}
      color={"secondary"}
      value={FEILD_VALUES}
      className={className}
      name={FIELD_SLUG}
      label={titleCase(`${FIELD_NAME}`)}
      size={size}
      error={error.length > 0}
      required={IS_MANDATORY === 1}
      style={error ? {} : { marginBottom: 30 }}
      onChange={handleChange}
      inputProps={{
        autoComplete: "off",
      }}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      FormHelperTextProps={{
        style: {
          fontWeight: 400,
          fontSize: 12,
          color: "#ED3833",
          marginBottom: 10,
          marginTop: 4,
          textAlign: "right",
        },
      }}
      helperText={error}
    />
  );
};
export const InputTextType = (props) => {
  const {
    FEILD_TYPE = "text",
    FIELD_NAME = "",
    FEILD_VALUES = "",
    handleChange = () => { },
    error = "",
    FIELD_SLUG = "",
    className = "w-100",
    disabled = false,
    IS_MANDATORY = 0,
    multiline = false,
    rows = 1,
    MAX_LENGTH = null,
    MIN_LENGTH = null,
    startAdornment = null,
    endAdornment = null,
    min = 0,
    max = null,
    placeholder = "",
    onKeyUp = () => { },
    size = "",
    inpPropsPlaceholder = "", // default html input placeholder without mui animation
    noMargin = false, //by default is false so previous code doesnt break
    styles = {},
    helperTextStyles = {},
    // register = false,
  } = props;

  return (
    <CustomInput
      autoFocus
      sx={styles}
      onKeyUp={onKeyUp}
      id="outlined-required"
      variant="outlined"
      label={`${FIELD_NAME}`}
      rows={rows}
      multiline={multiline}
      disabled={disabled}
      placeholder={placeholder}
      className={className}
      type={FEILD_TYPE === "input" ? "text" : FEILD_TYPE}
      required={IS_MANDATORY === 1}
      style={error ? {} : { marginBottom: noMargin ? 0 : 30 }}
      value={FEILD_VALUES || ""}
      onChange={(e) => {
        const regex = /^[0-9\b]+$/;
        if (FEILD_TYPE === VALID_DATA_TYPES.NUMBER) {
          if (e.target.value === "" || regex.test(e.target.value)) {
            handleChange(e);
          }
        } else {
          if (MAX_LENGTH && MAX_LENGTH >= `${e.target.value}`.length) {
            handleChange(e);
          } else if (!MAX_LENGTH) {
            handleChange(e);
          }
        }

      }}
      size={size}
      name={FIELD_SLUG}
      color={"secondary"}
      inputProps={{
        maxLength: MAX_LENGTH,
        minLength: MIN_LENGTH ? MIN_LENGTH : 0,
        size: MAX_LENGTH,
        min: min,
        max: max,
        autoComplete: "off",
        placeholder: inpPropsPlaceholder,
      }}
      InputProps={{
        startAdornment: startAdornment,
        endAdornment: endAdornment,
      }}
      FormHelperTextProps={{
        style: {
          ...helperTextStyles,
          fontWeight: 400,
          fontSize: 12,
          color: "#ED3833",
          marginBottom: 10,
          marginTop: 4,
          textAlign: "right",
        },
      }}
      error={error ? true : false}
      helperText={error}
    />
  );
};
const BACKSPACE_CONST = "Backspace";
export const InputDataTimeType = (props) => {
  const classes = useStyles();
  const {
    FIELD_NAME = "",
    FEILD_VALUES = "",
    handleChange = () => { },
    FIELD_SLUG = "",
    disabled = false,
    FUTURE_DATE_SELECT = 0,
    className = `${classes.datePickerTextColor} textField-input"`,
    // style = { width: "100%" },
    error = "",
    IS_MANDATORY = 0,
    dateFormat = "YYYY-MM-DD HH:MM:SS",
    dateWidth = "",
    marginError = "",
    alwaysPickMarginError = false,
    max = "",
    min = "",
  } = props;

  //handling keydown . This way manual input isn't allowed but backspace will work
  const handleKeyDown = (e) => {
    if (e.key === BACKSPACE_CONST) {
      handleChange({
        target: {
          value: "",
          name: FIELD_SLUG,
          type: "Date",
        },
      });
    }
    e.preventDefault();
    return false;
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disabled={disabled}
        onKeyDown={handleKeyDown}
        inputVariant="outlined"
        format="yyyy/MM/dd"
        label={FIELD_NAME}
        minDate={
          Object.prototype.toString.call(min) === "[object Date]"
            ? min
            : undefined
        }
        minDateMessage="Date shouldnt be less than todays date"
        required={IS_MANDATORY ? true : false}
        error={error}
        disableFuture={FUTURE_DATE_SELECT === 2}
        disablePast={FUTURE_DATE_SELECT === 1}
        InputLabelProps={{
          style: { color: "#757575" },
        }}
        style={
          error
            ? {
              marginBottom: marginError ? marginError : 30,
              width: dateWidth ? dateWidth : "100%",
            }
            : {
              marginBottom: alwaysPickMarginError ? marginError : 30,
              width: dateWidth ? dateWidth : "100%",
            }
        }
        helperText={error}
        name={FEILD_VALUES ? FEILD_VALUES : FIELD_SLUG}
        color="secondary"
        animateYearScrolling={true}
        views={["year", "month", "date"]}
        openTo={"date"}
        value={FEILD_VALUES ? FEILD_VALUES : null}
        variant="dialog"
        // style={FEILD_VALUES ? {} : { marginBottom: 30 }}
        // className={classes.datePickerTextColor}
        className={className}
        FormHelperTextProps={{
          style: {
            fontWeight: 400,
            fontSize: 12,
            height: 30,
            color: "#ED3833",
            marginBottom: 10,
            marginTop: 4,
            textAlign: "right",
          },
        }}
        onChange={(e) =>
          handleChange({
            target: {
              value: moment(e).format(dateFormat),
              name: FIELD_SLUG,
              type: "Date",
            },
          })
        }
      />
    </MuiPickersUtilsProvider>
  );
};

export const InputSelectType = (props) => {
  const {
    FIELD_NAME = "",
    FEILD_VALUES = "",
    FIELD_SLUG = "",
    handleChange = () => { },
    className = "w-100",
    disabled = false,
    customOption = [],
    IS_MANDATORY = 0,
    error,
    options = [],
    id = "value",
    name = "label",
    hideError = false,
    size = "",
    width = "175px",
    placeholder = "",
  } = props;

  // console.log({ options });

  const value = (
    props.hasOwnProperty("options") && !_.isEmpty(options)
      ? options
      : customOption
  ).find(
    (item) =>
      `${item[id]}` === `${FEILD_VALUES}` || `${item}` === `${FEILD_VALUES}`
  );
  return (
    <SelectWithLabel
      stickyLabel={FIELD_NAME}
      placeholder={placeholder}
      width={width}
      options={Array.isArray(options) ? options : []}
      value={value}
      onChange={(val) => {
        handleChange({ target: { value: val?.[id] } });
      }}
      error={error}
    />
  );
};

export const DownloadFileType = (props) => {
  const {
    fileName = "",
    showFileName = true,
    handleFileClick = () => { },
  } = props;

  return (
    <div
      className={"flex-start-center g-5 cursor-pointer"}
      onClick={handleFileClick}
    >
      <FileType
        file={fileName}
        styleOverrides={{
          position: "relative",
          top: "initial",
          left: "initial",
        }}
      />
      {showFileName && (
        <div>
          <TextReducingTooltipComponent data={fileName} limit={30} />
        </div>
      )}
    </div>
  );
};

export const UploadFileType = (props) => {
  const {
    FIELD_NAME,
    handleChange,
    error,
    isDrop = true,
    dragNDrop = false,
    disabled = false,
    styleOverrides = {},
    multiple = false,
  } = props;

  const handleFileUpload = (e) => {
    if (isFileExtensionValid(e.target?.files?.[0]?.name)) handleChange(e);
    else e.target.value = ""; // bad file extension
  };

  return (
    <div style={{ ...styleOverrides }}>
      <CustomFileUpload
        isDrop={isDrop}
        dragNDrop={dragNDrop}
        disabled={disabled}
        label={FIELD_NAME}
        error={error}
        onChange={handleFileUpload}
        multiple={multiple}
      />
    </div>
  );
};

export const InputFileType = (props) => {
  const {
    is_download_with_upload = false, // Add the new prop
    FIELD_NAME = "",
    handleChange = () => { },
    error = "",
    // FIELD_SLUG = "",
    // FEILD_VALUES = null,
    // className = "w-100",
    // style = {},
    // IS_MANDATORY = 0,
    // size = "",
    disabled = false,
    isDrop = true,
    is_download = false,
    showFileName = true,
    handleFileClick = () => { },
    fileName = "",
    dragNDrop = false,
    styleOverrides = {
      minWidth: "300px",
    },

    // Other props...
  } = props;

  return (
    <>
      {is_download_with_upload ? (
        <div className="flex-start-center g-10">
          <UploadFileType
            FIELD_NAME={FIELD_NAME}
            handleChange={handleChange}
            error={error}
            isDrop={isDrop}
            dragNDrop={dragNDrop}
            disabled={disabled}
            styleOverrides={styleOverrides}
          />
          <DownloadFileType
            fileName={fileName}
            showFileName={showFileName}
            handleFileClick={handleFileClick}
          />
        </div>
      ) : is_download ? (
        <DownloadFileType
          fileName={fileName}
          showFileName={showFileName}
          handleFileClick={handleFileClick}
        />
      ) : (
        <UploadFileType
          FIELD_NAME={FIELD_NAME}
          handleChange={handleChange}
          error={error}
          isDrop={isDrop}
          dragNDrop={dragNDrop}
          disabled={disabled}
          styleOverrides={styleOverrides}
        />
      )}
    </>
  );
};

// export const InputFileType = (props) => {
//   const {
//     FIELD_NAME = "",
//     handleChange = () => {},
//     error = "",
//     FIELD_SLUG = "",
//     FEILD_VALUES = null,
//     className = "w-100",
//     style = {},
//     IS_MANDATORY = 0,
//     size = "",
//     disabled = false,
//     isDrop = true,
//     is_download = false,
//     showFileName = true,
//     handleFileClick = () => {},
//     fileName = "",
//     dragNDrop = false,
//     styleOverrides = {
//       minWidth: "300px",
//     },
//   } = props;
//   return (
//     <>
//       {is_download ? (
//         <div
//           className={"flex-start-center g-5 cursor-pointer"}
//           onClick={handleFileClick}
//         >
//           <FileType
//             file={fileName}
//             styleOverrides={{
//               position: "relative",
//               top: "initial",
//               left: "initial",
//             }}
//           />
//           {showFileName && (
//             <div>
//               <TextReducingTooltipComponent data={fileName} limit={30} />
//             </div>
//           )}
//         </div>
//       ) : (
//         <div
//           style={{
//             ...styleOverrides,
//           }}
//         >
//           <CustomFileUpload
//             isDrop={isDrop}
//             dragNDrop={dragNDrop}
//             disabled={disabled}
//             label={FIELD_NAME}
//             error={error}
//             onChange={(e) => {
//               if (isFileExtensionValid(e.target?.files?.[0]?.name))
//                 handleChange(e);
//               else e.target.value = ""; // bad file extension
//             }}
//           />
//         </div>
//       )}
//     </>
//   );
// };

export const InputCheckBoxType = (props) => {
  const {
    FIELD_NAME = "",
    FEILD_VALUES = "",
    handleChange = () => { },
    FIELD_SLUG = "",
    options = [],
    error = "",
    disabled = false,
    IS_MANDATORY = 0,
  } = props;
  const checkData = FEILD_VALUES ? FEILD_VALUES.split(",") : [];
  return (
    <FormControl
      required={IS_MANDATORY || `${IS_MANDATORY}` === "1"}
      margin="none"
      className="w-100 flex-between-start  flex-column"
      disabled={disabled}
    >
      {FIELD_NAME ? (
        <FormLabel
          required={IS_MANDATORY || `${IS_MANDATORY}` === "1"}
          component="label"
        >
          {/* {titleCase(`${FIELD_NAME}`)} */}
        </FormLabel>
      ) : null}
      <FormGroup
        className="w-100 flex-between-start  flex-column wrap-flex"
        row
        margin="none"
      >
        {options.map((item, index) => (
          <FormControlLabel
            value={FEILD_VALUES}
            key={index}
            margin="none"
            className="width-50-16px"
            onChange={(e) => {
              if (checkData.indexOf(item) > -1) {
                checkData.splice(checkData.indexOf(item), 1);
              } else {
                checkData.push(item);
              }
              handleChange({
                ...e,
                target: {
                  ...e.target,
                  value: checkData.join(","),
                  type: "checkbox",
                  name: FIELD_SLUG,
                },
              });
            }}
            control={
              <Checkbox
                color="secondary"
                checked={checkData.indexOf(item) > -1}
                sx={{
                  color:
                    checkData.indexOf(item) > -1
                      ? "secondary.main"
                      : "#EBECF0 !important",
                }}
              />
            }
            label={titleCase(`${FIELD_NAME}`)}
            labelPlacement="end"
            name={FIELD_SLUG}
          />
        ))}
      </FormGroup>
      <FormHelperText
        error={error}
        component={() => (
          <Typography
            align="start"
            variant="body1"
            sx={{
              fontSize: 12,
              color: "#ED3833",
              height: 12,
              mb: 1,
              mt: 0.4,
            }}
          >
            {error}
          </Typography>
        )}
        variant="outlined"
        id={FIELD_SLUG}
      />
    </FormControl>
  );
};
export const InputRadioType = (props) => {
  const {
    FIELD_NAME = "",
    FEILD_VALUES = false,
    handleChange = () => { },
    FIELD_SLUG = "",
    options = [],
    error = "",
    disabled = false,
    IS_MANDATORY = 0,
  } = props;
  return (
    <FormControl
      required={IS_MANDATORY || `${IS_MANDATORY}` === "1"}
      className="w-100 flex-between-start  flex-column"
      disabled={disabled}
      margin="none"
    >
      {FIELD_NAME ? (
        <FormLabel
          required={IS_MANDATORY || `${IS_MANDATORY}` === "1"}
          component="label"
        >
          {titleCase(`${FIELD_NAME}`)}
        </FormLabel>
      ) : null}
      <RadioGroup
        className="w-100 flex-between-start  flex-column wrap-flex"
        value={FEILD_VALUES}
        name={FIELD_SLUG}
      >
        <FormGroup
          className="w-100 flex-between-start  flex-column wrap-flex"
          row
          margin="none"
        >
          {options.map((item, index) => (
            <FormControlLabel
              value={FEILD_VALUES}
              key={index}
              margin="none"
              className="width-50-16px"
              onChange={(e) =>
                handleChange({
                  ...e,
                  target: {
                    ...e.target,
                    value: item,
                    type: "radio",
                    name: FIELD_SLUG,
                  },
                })
              }
              control={
                <Radio
                  color="secondary"
                  checked={FEILD_VALUES === item}
                  sx={{
                    color:
                      FEILD_VALUES === item
                        ? "secondary.main"
                        : "#EBECF0 !important",
                  }}
                />
              }
              label={titleCase(`${item}`)}
              labelPlacement="end"
              name={FIELD_SLUG}
            />
          ))}
        </FormGroup>
      </RadioGroup>
      <FormHelperText
        error={error}
        component={() => (
          <Typography
            align="start"
            variant="body1"
            sx={{
              fontSize: 12,
              color: "#ED3833",
              height: 12,
              mb: 1,
              mt: 0.4,
            }}
          >
            {error}
          </Typography>
        )}
        variant="outlined"
        id={FIELD_SLUG}
      />
    </FormControl>
  );
};
export const CheckBoxVarible = (props) => {
  const { FIELD_NAME = "", FEILD_VALUES = "", handleChange = () => { } } = props;
  return (
    <FormControlLabel
      value="end"
      control={
        <Checkbox
          color="primary"
          checked={FEILD_VALUES}
          onChange={handleChange}
        />
      }
      label={titleCase(`${FIELD_NAME}`)}
      labelPlacement="end"
    />
  );
};

export const InputAutoCompleteTextType = (props) => {
  const [searchValue, setSearchValue] = useState("");
  const {
    FIELD_SLUG = "",
    FIELD_NAME = "",
    FEILD_VALUES = "",
    handleChange = () => { },
    getValues = () => { },
    options = [],
    disabled = false,
    className = "w-100",
    IS_MANDATORY = 0,
    error,
    id = "id",
    name = "name",
  } = props;
  const value = options.find((item) => `${item[id]}` === `${FEILD_VALUES}`);
  useEffect(() => {
    if (searchValue.length) {
      getValues({ search: searchValue });
    } else {
      getValues({ search: "" });
    }
  }, [searchValue]);
  return (
    <Autocomplete
      options={[...options]}
      className={className}
      style={error ? {} : { marginBottom: 26 }}
      disabled={disabled}
      getOptionLabel={(option) =>
        option?.hasOwnProperty(name)
          ? option[name]
          : option?.hasOwnProperty("CITY_NAME")
            ? option.CITY_NAME
            : ""
      }
      value={!_.isEmpty(value) ? value : FEILD_VALUES ? FEILD_VALUES : null}
      onChange={(e, newItem) => {
        handleChange({
          ...e,
          target: {
            ...e.target,
            name: FIELD_SLUG,
            value:
              newItem && newItem.hasOwnProperty(id) ? newItem[id] : newItem,
          },
        });
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} style={{ height: 48 }}>
            <Typography variant="body1">
              {option?.hasOwnProperty(name) ? `${option[name]}` : `${option}`}
            </Typography>
          </li>
        );
      }}
      popupIcon={
        <KeyboardArrowDown sx={{ width: 24, height: 24, color: "inherit" }} />
      }
      renderInput={(params) => (
        <TextField
          {...params}
          id="outlined-required"
          variant="outlined"
          required={IS_MANDATORY ? true : false}
          color="secondary"
          label={titleCase(`${FIELD_NAME}`)}
          FormHelperTextProps={{
            style: {
              fontWeight: 400,
              fontSize: 12,
              color: "#ED3833",
              marginBottom: 10,
              marginTop: 4,
              textAlign: "right",
            },
          }}
          onChange={({ target }) => setSearchValue(target.value)}
          error={error ? true : false}
          helperText={error}
        />
      )}
    />
  );
};

export function CheckboxWithLabels({
  items = [],
  onItemChange = () => { },
  className = "",
}) {
  return (
    <div className={className}>
      {items.map((item, index) => (
        <FormControlLabel
          key={index}
          control={
            <Checkbox
              checked={item.checked}
              disabled={item.disabled}
              onChange={(event) => onItemChange(event, index, item)}
            />
          }
          label={item.label}
        />
      ))}
    </div>
  );
}

export const InputPhoneNumber = (props) => {
  const {
    FIELD_NAME = "",
    FEILD_VALUES = "",
    handleChange = () => { },
    FIELD_SLUG = "",
    className = "w-100",
    handleSelectPhone = () => { },
    defaultValue = "",
    selectValue = "",
    style = "",
    error,
    size = "",
  } = props;
  return (
    <TextField
      id="outlined-required"
      variant="outlined"
      label={titleCase(`${FIELD_NAME}`)}
      className={className}
      required
      style={style}
      value={FEILD_VALUES}
      onChange={handleChange}
      name={FIELD_SLUG}
      size={size}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Select
              variant="standard"
              labelId="demo-dialog-select-label"
              id="demo-dialog-select"
              defaultValue={defaultValue}
              onClick={handleSelectPhone}
            >
              {Array.isArray(selectValue) &&
                selectValue?.map((item, index) =>
                  item.COUNTRY_CODE ? (
                    <MenuItem key={index} value={item.COUNTRY_CODE}>
                      +{item.COUNTRY_CODE}
                    </MenuItem>
                  ) : null
                )}
            </Select>
          </InputAdornment>
        ),
      }}
      FormHelperTextProps={{
        style: {
          fontWeight: 400,
          fontSize: 12,
          color: "#ED3833",
          marginBottom: 10,
          marginTop: 4,
          textAlign: "right",
        },
      }}
      error={error ? true : false}
      helperText={error}
    />
  );
};

//JUST A TEXT SPAN TO HANDLE FOR REVIEW PAGE WHEN WE ONLY WANT TO VISUALISE
export const TextSpan = (props) => {
  let value = props?.field_value || props?.FEILD_VALUES || "";
  if (typeof value === "object") {
    return (
      <TextReducingTooltipComponent
        data={value?.[0]?.label ? value?.[0]?.label : JSON.stringify(value)}
        limit={props?.limit || "50"}
      />
    );
  }

  return (
    <TextReducingTooltipComponent data={value} limit={props?.limit || "50"} />
  );
};

export const typeSwitchCase = (type, value, limit = "30") => {
  switch (type) {
    case "bold": //kpi
    case "kpi":
      return (
        <b style={{ textWrap: "nowrap" }}>
          <TextReducingTooltipComponent data={value} limit={limit} />
        </b>
      );
    case "italic": //kra
    case "kra":
      return (
        <div
          style={{ fontStyle: "italic", fontSize: "11px", textWrap: "nowrap" }}
        >
          <TextReducingTooltipComponent
            styleOverrides={{ fontStyle: "italic", fontSize: "11px" }}
            data={value}
            limit={limit}
          />
        </div>
      );
    case "bracket": // kpi_wtg , kra_wtg
    case "kpi_wtg":
    case "kra_wtg":
      return (
        <TextReducingTooltipComponent
          data={value}
          limit={limit}
          startAdornment="("
          endAdornment=")"
        />
      );
    case "desc": //description
      return (
        validateDataType(value, VALID_DATA_TYPES.STRING) && value.length ? <Tooltip title={value}>
          <InfoIcon sx={{ color: "#ECECEC" }} className="cursor-pointer" />
        </Tooltip> : <></>
      );
    default:
      return <TextReducingTooltipComponent data={value} limit={limit} />;
  }
};

//JUST A TEXT SPAN TO HANDLE FOR REVIEW PAGE WHEN WE ONLY WANT TO VISUALISE
export const ItalicVisual = (props) => {
  let value = props?.field_value || props?.FEILD_VALUES || "";
  return Array.isArray(value)
    ? value.map((el, idx) => (
      <div
        className="flex-start-center g-5"
        key={idx}
        style={{ maxWidth: props?.width || "400px", width: "100%" }}
      >
        {Object.keys(el).map((key) =>
          typeSwitchCase(key, el[key], props?.limit)
        )}
      </div>
    ))
    : null;
};

export const InputMultipleInput = (props) => {
  const theme = useTheme();
  const {
    FIELD_NAME = "",
    FEILD_VALUES = null,
    handleChange = () => { },
    FIELD_SLUG = "",
    className = "w-100",
    disabled = false,
    IS_MANDATORY = 0,
    limitTags = 2,
    error,
    options = null,
    FIELD_ID = "id",
    NAME = "name",
    size = "",
  } = props;

  const memoizedOptionsMapping = useMemo(
    () => convertArrayToObjectWithKey(options, FIELD_ID),
    [options, FIELD_ID]
  );

  const memoizedValues = React.useMemo(
    () =>
      Array.isArray(FEILD_VALUES) && !isEmpty(memoizedOptionsMapping)
        ? FEILD_VALUES.map((el) => memoizedOptionsMapping[el] ?? "")
        : [],

    [memoizedOptionsMapping, FEILD_VALUES]
  );

  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      limitTags={limitTags}
      options={options}
      disabled={disabled}
      size={size}
      required={IS_MANDATORY || `${IS_MANDATORY}` === "1"}
      getOptionLabel={(option) =>
        option?.hasOwnProperty(NAME) ? option?.[NAME] ?? "" : option
      }
      renderTags={(options, getTagProps) =>
        options.map((item, index) => (
          <Chip
            size={"small"}
            key={index}
            clickable
            sx={{
              backgroundColor: `${alpha(
                theme.palette.secondary.main,
                0.12
              )} !important`,
              borderRadius: 1,
            }}
            deleteIcon={
              <Close
                style={{
                  width: 12,
                  height: 12,
                  color: theme.palette.primary.main,
                }}
              />
            }
            variant="filled"
            {...getTagProps({ index })}
            label={<Typography>{item?.[NAME] ?? ""}</Typography>}
          />
        ))
      }
      style={error ? {} : { marginBottom: 30 }}
      value={memoizedValues}
      popupIcon={
        <KeyboardArrowDown sx={{ width: 24, height: 24, color: "inherit" }} />
      }
      // renderOption={(props, option,) => {
      //   console.log({option})
      //   return (
      //     <li {...props} style={{ height: 48 }}>
      //       <Typography variant="body1">{option?.[NAME] ?? ''}</Typography>
      //     </li>
      //   );
      // }}
      onChange={(event, newValue) => {
        handleChange({
          ...event,
          target: {
            ...event.target,
            name: FIELD_SLUG,
            value: getIdsObject(newValue, FIELD_ID),
          },
        });
      }}
      className={className}
      renderInput={(params) => (
        <TextField
          {...params}
          label={titleCase(`${FIELD_NAME}`)}
          color="secondary"
          error={error}
          helperText={error}
          sx={{
            paddingTop: 0,
            paddingBottom: 0,
          }}
          FormHelperTextProps={{
            style: {
              fontWeight: 400,
              fontSize: 12,
              color: "#ED3833",
              marginBottom: 10,
              marginTop: 4,
              lineHeight: 1.5,
              textAlign: "right",
            },
          }}
        />
      )}
    />
  );
};
export const InputMobileNumberWithCountryCode = (props) => {
  const [countries, setcountries] = useState([]);
  const getCountry = async () => {
    try {
      const resultState = await axios({
        newUrl: true,
        nextUrl: `apiv2/dropdown/countries/`,
      });
      setcountries(resultState.data.countries);
    } catch (error) { }
  };
  const [countrydata, setCountryData] = useState({
    COUNTRY_ID: "101",
    COUNTRY_CODE: props?.COUNTRY_CODE ?? "",
  });
  useEffect(() => {
    if (countries.length === 0) {
      getCountry();
    } else {
      const getCountryDetails = countries?.find(
        (item) => item.COUNTRY_NAME === "India"
      );
      setCountryData({
        ...countrydata,
        COUNTRY_CODE: getCountryDetails?.COUNTRY_CODE,
        COUNTRY_ID: getCountryDetails?.COUNTRY_ID,
      });
    }
  }, [countries]);

  const {
    FIELD_NAME = "",
    FEILD_VALUES = "",
    handleChange = () => { },
    error = "",
    FIELD_SLUG = "",
    className = "w-100",
    disabled = false,
    IS_MANDATORY = 0,
    multiline = false,
    rows = 1,
    MAX_LENGTH = null,
    MIN_LENGTH = null,
    endAdornment = null,
    min = 0,
    max = null,
    placeholder = "",
    onKeyUp = () => { },
    size = "",
    country_data = () => { },
  } = props;
  return (
    <TextField
      onKeyUp={onKeyUp}
      id="outlined-required"
      variant="outlined"
      label={`${FIELD_NAME}`}
      rows={rows}
      multiline={multiline}
      disabled={disabled}
      placeholder={placeholder}
      className={className}
      type={"numbr"}
      required={IS_MANDATORY === 1}
      style={error ? {} : { marginBottom: 30 }}
      value={FEILD_VALUES || ""}
      onChange={(e) => {
        handleChange(e);
      }}
      size={size}
      name={FIELD_SLUG}
      color={"secondary"}
      inputProps={{
        maxLength: MAX_LENGTH,
        minLength: MIN_LENGTH ? MIN_LENGTH : 0,
        size: MAX_LENGTH,
        min: min,
        max: max,
        autoComplete: "off",
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Select
              variant="standard"
              labelId="demo-dialog-select-label"
              id="demo-dialog-select"
              defaultValue={countrydata?.COUNTRY_CODE}
              renderValue={(value) => {
                return <span>(+{value.replace("+", "")})</span>;
              }}
              IconComponent={() => (
                <ArrowDropDownIcon
                  sx={{
                    color: "#2C3246",
                    position: "absolute !important",
                    right: "0 !important",
                    pointerEvents: "none !important",
                  }}
                />
              )}
              onChange={(e, val) => {
                const getCountryId = countries?.find(
                  (item) => item.COUNTRY_CODE === e.target.value
                );
                setCountryData({
                  ...countrydata,
                  COUNTRY_ID: getCountryId ? getCountryId.COUNTRY_ID : null,
                  COUNTRY_CODE: e.target.value,
                });
                country_data({
                  ...countrydata,
                  COUNTRY_ID: getCountryId ? getCountryId.COUNTRY_ID : null,
                  COUNTRY_CODE: e.target.value,
                });
              }}
            >
              {Array.isArray(countries) &&
                countries?.map((item, index) =>
                  item.COUNTRY_CODE ? (
                    <MenuItem
                      key={index}
                      value={item.COUNTRY_CODE.replace("+", "")}
                    >
                      {item.COUNTRY_CODE.split("").includes("+")
                        ? `${item.COUNTRY_NAME} (${item.COUNTRY_CODE})`
                        : `${item.COUNTRY_NAME} (+${item.COUNTRY_CODE})`}
                    </MenuItem>
                  ) : null
                )}
            </Select>
          </InputAdornment>
        ),
        endAdornment: endAdornment,
      }}
      // InputLabelProps={{ shrink: !register || FIELD_NAME === "Mobile Number" }}
      // InputLabelProps={{ shrink: true }}
      FormHelperTextProps={{
        style: {
          fontWeight: 400,
          fontSize: 12,
          color: "#ED3833",
          marginBottom: 10,
          marginTop: 4,
          textAlign: "right",
        },
      }}
      error={error ? true : false}
      helperText={error}
    />
  );
};
export const FormInputType = (props) => {
  const { FEILD_TYPE = "" } = props;
  switch (FEILD_TYPE) {
    case "password":
      return <InputPasswordType {...props} />;
    case "input":
    case "email":
    case "number":
    case "textarea":
    case "text":
      return <InputTextType {...props} />;
    case "mobile":
      return <InputMobileNumberWithCountryCode {...props} />;
    case "multiple":
      return <InputMultipleInput {...props} />;
    case "select":
    case "changeable":
      return <InputSelectType {...props} />;
    case "onchangetype":
    case "autocomplete":
      return <InputAutoCompleteTextType {...props} />;
    case "calander":
      return <InputDataTimeType {...props} />;
    case "checkbox":
      return <InputCheckBoxType {...props} />;
    case "radio":
      return <InputRadioType {...props} />;
    case "file":
      return <InputFileType {...props} />;
    case "phone_number":
      return <InputPhoneNumber {...props} />;
    case "italic_visual":
      return <ItalicVisual {...props} />;
    case "na":
      return <TextSpan {...props} />;
    default:
      return <TextSpan {...props} />;
  }
};

// for new dynamic forms

export const DYNAMIC_FORM_INPUT_TYPES = {
  TEXT: "text",
  NUMBER: "number",
  TEXTAREA: "textarea",
  SELECT: "select",
  MULTI_SELECT: "multi-select",
  CHECKBOX: "checkbox",
  RADIO: "radio",
  FILE: "file",
  TIME: "time",
  DATE: "date",
  DATE_TIME: "date-time",
  DATE_RANGE: "date-range",
  SWITCH: "switch",
  TEL: "tel",
  URL: "url",
  EMAIL: "email",
  NUMERIC: "numeric",
  DECIMAL: "decimal",
  SEARCH: "search",
  EDITOR: "editor",
};

export const FORM_MASTER_TYPES = {
  TEXT: "input",
  NUMBER: "number",
  TEXTAREA: "textarea",
  SELECT: "select",
  MULTI_SELECT: "multiple",
  CHECKBOX: "checkbox",
  RADIO: "radio",
  FILE: "file",
  TIME: "time",
  DATE: "calander",
  DATE_TIME: "date-time",
  DATE_RANGE: "date-range",
  SWITCH: "switch",
  TEL: "tel",
  URL: "url",
  EMAIL: "email",
  NUMERIC: "numeric",
  DECIMAL: "decimal",
  SEARCH: "search",
  EDITOR: "editor",
};

export const VALID_DATA_TYPES = {
  NUMBER: "number",
  STRING: "string",
  ARRAY: "array",
  OBJECT: "object",
  NULL: "null",
  UNDEFINED: "undefined",
  BOOLEAN: "boolean",
  FUNCTION: "function",
};

export function validateDataType(value, expectedType) {
  if (!value) {
    return false;
  }

  const valueType = typeof value;

  switch (expectedType) {
    case VALID_DATA_TYPES.STRING:
    case VALID_DATA_TYPES.NUMBER:
    case VALID_DATA_TYPES.BOOLEAN:
    case VALID_DATA_TYPES.FUNCTION:
      return valueType === expectedType && value;
    case VALID_DATA_TYPES.ARRAY:
      return Array.isArray(value) && value;
    case VALID_DATA_TYPES.OBJECT:
      return Object.keys(value).length && !Array.isArray(value) && value;
    default:
      return false;
  }
}

export const convertFormMasterTypeToDynamicFormType = (type) => {
  switch (type) {
    case FORM_MASTER_TYPES.TEXT:
      return DYNAMIC_FORM_INPUT_TYPES.TEXT;
    case FORM_MASTER_TYPES.NUMBER:
      return DYNAMIC_FORM_INPUT_TYPES.NUMBER;
    case FORM_MASTER_TYPES.TEXTAREA:
      return DYNAMIC_FORM_INPUT_TYPES.TEXTAREA;
    case FORM_MASTER_TYPES.CHECKBOX:
      return DYNAMIC_FORM_INPUT_TYPES.CHECKBOX;
    case FORM_MASTER_TYPES.DATE:
      return DYNAMIC_FORM_INPUT_TYPES.DATE;
    case FORM_MASTER_TYPES.DATE_RANGE:
      return DYNAMIC_FORM_INPUT_TYPES.DATE_RANGE;
    case FORM_MASTER_TYPES.DATE_TIME:
      return DYNAMIC_FORM_INPUT_TYPES.DATE_TIME;
    case FORM_MASTER_TYPES.DECIMAL:
      return DYNAMIC_FORM_INPUT_TYPES.DECIMAL;
    case FORM_MASTER_TYPES.EMAIL:
      return DYNAMIC_FORM_INPUT_TYPES.EMAIL;
    case FORM_MASTER_TYPES.FILE:
      return DYNAMIC_FORM_INPUT_TYPES.FILE;
    case FORM_MASTER_TYPES.MULTI_SELECT:
      return DYNAMIC_FORM_INPUT_TYPES.MULTI_SELECT;
    case FORM_MASTER_TYPES.NUMERIC:
      return DYNAMIC_FORM_INPUT_TYPES.NUMERIC;
    case FORM_MASTER_TYPES.RADIO:
      return DYNAMIC_FORM_INPUT_TYPES.RADIO;
    case FORM_MASTER_TYPES.SEARCH:
      return DYNAMIC_FORM_INPUT_TYPES.SEARCH;
    case FORM_MASTER_TYPES.SELECT:
      return DYNAMIC_FORM_INPUT_TYPES.SELECT;
    case FORM_MASTER_TYPES.TEL:
      return DYNAMIC_FORM_INPUT_TYPES.TEL;
    case FORM_MASTER_TYPES.TIME:
      return DYNAMIC_FORM_INPUT_TYPES.TIME;
    case FORM_MASTER_TYPES.SWITCH:
      return DYNAMIC_FORM_INPUT_TYPES.SWITCH;
    case FORM_MASTER_TYPES.URL:
      return DYNAMIC_FORM_INPUT_TYPES.URL;
    case FORM_MASTER_TYPES.EDITOR:
      return DYNAMIC_FORM_INPUT_TYPES.EDITOR;
    default:
      return DYNAMIC_FORM_INPUT_TYPES.TEXT;
  }
};
