import "antd/dist/antd.css";
import React from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import GuestLayout from "./Layout/GuestLayout";
import LoginPage from "./pages/AuthLayout/LoginPage";
import { CircularProgress } from "@material-ui/core";

export const PlaceHolderComponet = () => {
  const [searchParams] = useSearchParams();

  // Retrieve the 'token' query parameter
  const urlToken = searchParams.get("token");

  // Now you have the 'token' value, and you can use it in your component
  console.log("Token in placeholder:", urlToken);

  return (
    <>
      {urlToken ? (
        <div className="wh-vw-vh flex-center">
          <CircularProgress />
        </div>
      ) : (
        <main>
          <GuestLayout location={window.location} component={LoginPage} />
          <Outlet />
        </main>
      )}
    </>
  );
};
