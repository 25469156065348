import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

export class Slider extends Component {
  componentDidMount() {
    this.silderMovement(0);
  }
  silderMovement = (slideIndex) => {
    const slides = document.getElementsByClassName("slider");
    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    slideIndex++;
    if (slideIndex > slides.length) {
      slideIndex = 1;
    }
    slides[slideIndex - 1].style.display = "flex";
    this.clearTimeOut = setTimeout(() => this.silderMovement(slideIndex), 3000);
  };
  componentWillUnmount() {
    clearTimeout(this.clearTimeOut);
  }
  render() {
    return (
      <Box className="start-flex flex-column wh-100 mp-0">
        {this.props?.sliderContent.map((item, index) => (
          <Grid
            container
            key={index}
            spacing={0}
            sx={{ pl: 10, pt: 5, pb: 8, pr: 15 }}
            className="slider flex-column flex-between-start"
          >
            <Grid item>
              <img
                src={item.imageIcon}
                style={{ height: "auto" , maxWidth: '100%'}}
                alt={item.title}
              />
              <Typography
                variant="subtitle1"
                sx={{ color: "#ffff", fontSize: "2.75rem", mt: 2 }}
              >
                {item.title}
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#ffff", fontSize: "1.125rem", mt: 2 }}
              >
                {item.subTitle}
              </Typography>
            </Grid>

            <Grid item className="slide-dot-container flex-between-center">
              {[0, 1, 2].map((num) => (
                <div
                  key={num}
                  className={num === index ? "dot active" : "dot"}
                />
              ))}
            </Grid>
          </Grid>
        ))}
      </Box>
    );
  }
}

export default Slider;
