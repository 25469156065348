import { useMemo } from 'react';
import  isEmpty  from 'lodash/isEmpty'; 
import { getIdsObject } from '../utitlies/commonFunction';


function useFilterParsedValue(filter, filterMappingForNameKeys) {
  return useMemo(() => {
    const val = {};
    const custom_search = {};

    if (!isEmpty(filter)) {
      Object.keys(filter).forEach((element) => {
        if (
          !Array.isArray(filter[element]) &&
          (typeof filter[element] === "string" ||
            typeof filter[element] === "number")
        ) {
          val[filterMappingForNameKeys?.[element] ?? element] = filter[element];
        } else if (Array.isArray(filter[element])) {
          const ids = getIdsObject(filter[element], "id"); // Replace with your getIdsObject function
          if (ids.length > 0) {
            val[filterMappingForNameKeys?.[element] ?? element] = ids;
          }
        } else {
          val[filterMappingForNameKeys?.[element] ?? element] = filter[element];
          return; // do nothing in the case when the value is neither array nor string case.
        }
      });
    }

    if (!isEmpty(custom_search)) {
      val["custom_search"] = custom_search;
    }

    return val;
  }, [filter, filterMappingForNameKeys]);
}

export default useFilterParsedValue;
