import { tableHeadClasses } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";

const StyledTableRow = styled(TableRow)(
  ({
    theme,
    alternate,
    tableRowStyles = {},
  }) =>{

    return ({
      ...(alternate?.show && {
        [`&:nth-of-type(${alternate?.placement})`]: {
          backgroundColor: alternate?.rowColor,
        },
      }),
      "&:last-child td, &:last-child th": {
        border: 'none',
      },
      [`&.${tableRowClasses.root}`]: tableRowStyles  
    })
  } 
);

export default StyledTableRow;
