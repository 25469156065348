const quater = "Q";
const HalfYear = "H";
const Monthly =  "M";
const Yearly = "Y"
const yearName = new Date().getFullYear();
const FinancialCalendar = {
    3: [
      { Apr: 30, May: 31, Jun: 30,startDay:'01', startMonth:'04', lastDay:30,lastMonth:'06', totalCount: 91, cycle: `Apr-Jun (${quater}1)`, quarter:1 },
      { july: 31, Aug: 31, Sep: 30,startDay:'01', startMonth:'07',lastDay:30,lastMonth:'09', totalCount: 92, cycle: `July-Sep (${quater}2)`, quarter:2},
      { Oct: 31, Nov: 30, Dec: 31, startDay:'01', startMonth:'10',lastDay:31,lastMonth:'12',totalCount: 92, cycle: `Oct-Dec (${quater}3)`, quarter:3 },
      { Jan: 31, Feb: 29, Mar: 31,startDay:'01', startMonth:'01',lastDay:31,lastMonth:'03',totalCount: 91, isCycleYearChange:'both', cycle: `Jan-Mar (${quater}4)`, quarter:4 },
    ],
    4: [
      {Apr: 30, May: 31, Jun: 30,July: 31, Aug: 31, Sep: 30,startDay:'01', startMonth:'04',lastDay:30,lastMonth:'09',totalCount: 183,cycle: `Apr-Sep (${HalfYear}1)`,quarter:1},
      {Oct: 31, Nov: 30, Dec: 31,Jan: 31, Feb: 29, Mar: 31,startDay:'01', startMonth:'10',lastDay:31,lastMonth:'03',totalCount: 183,isCycleYearChange:'last',cycle: `Oct-Mar (${HalfYear}2)`,quarter:2}
    ],
    2: [
      {Apr: 30, startDay:'01', startMonth:'04',lastDay:30,lastMonth:'04',totalCount: 30, cycle: `Apr (${Monthly }1)`,quarter:1},
      {May: 31, startDay:'01', startMonth:'05',lastDay:31,lastMonth:'05',totalCount: 31, cycle: `May (${Monthly }2)` ,quarter:2},
      {Jun: 30, startDay:'01', startMonth:'06',lastDay:30,lastMonth:'06',totalCount: 30, cycle: `Jun (${Monthly }3)`,quarter:3},
      {july: 31 ,startDay:'01', startMonth:'07',lastDay:31,lastMonth:'07',totalCount: 31, cycle: `Jul (${Monthly}4)`,quarter:4},
      {Aug: 31, startDay:'01', startMonth:'08',lastDay:31,lastMonth:'08',totalCount: 31, cycle: `Aug (${Monthly }5)`,quarter:5},
      {Sep: 30, startDay:'01', startMonth:'09',lastDay:30,lastMonth:'09',totalCount: 30, cycle: `Sep (${Monthly }6)`,quarter:6},
      {Oct: 31, startDay:'01', startMonth:'10',lastDay:31,lastMonth:'10',totalCount: 31, cycle: `Oct (${Monthly }7)`,quarter:7},
      {Nov: 30, startDay:'01', startMonth:'11',lastDay:30,lastMonth:'11',totalCount: 30, cycle: `Nov (${Monthly }8)`,quarter:8},
      {Dec: 31, startDay:'01', startMonth:'12',lastDay:31,lastMonth:'12',totalCount: 31, cycle: `Dec (${Monthly }9)`,quarter:9},
      {Jan: 31, startDay:'01', startMonth:'01',lastDay:31,lastMonth:'01',totalCount: 31, isCycleYearChange:'both',cycle: `Jan (${Monthly }10)`,quarter:10},
      {Feb: 29, startDay:'01', startMonth:'02',lastDay:29,lastMonth:'02',totalCount: 29, isCycleYearChange:'both',cycle: `Feb (${Monthly }11)`,quarter:11},
      {Mar: 31, startDay:'01', startMonth:'03',lastDay:31,lastMonth:'03',totalCount: 31, isCycleYearChange:'both',cycle: `Mar (${Monthly }12)`,quarter:12},
    ],
    5:[
      {
         Apr: 30, May: 31, Jun: 30,July: 31, Aug: 31, Sep: 30, Oct: 31, Nov: 30, Dec: 31,Jan: 31, Feb: 28, Mar: 31,startDay:'01', startMonth:'04',lastDay:31,lastMonth:'03',totalCount: 366, isCycleYearChange:'last',cycle: `Yearly`,quarter:""
      }
    ]
  };

  export default FinancialCalendar;