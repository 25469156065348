import * as CryptoJS from "crypto-js";
import axios from "axios";
import { START_LOADER, END_LOADER } from "../redux/auth/constant";
import moment from "moment";
import { ERROR_MESSAGE_FROM_SERVER } from "../redux/message/constant";

let secretKey = "a5ACEb528";
try {
  secretKey = process?.env?.REACT_DEFAULT_CRYPTO_KEY ?? "a5ACEb528";
} catch (err) {
  // console.log({ err });
}
const baseURL = process.env.REACT_APP_SERVER_URL;

const axiosInstance = axios.create({
  baseURL: baseURL,
  responseType: "blob",
});

export function isTimestamp(str) {
  const parsedDate = moment(str, moment.ISO_8601, true);
  return parsedDate.isValid() && str === parsedDate.format();
}

export function convertToStandardDateFormat(timestamp) {
  const parsedDate = moment(timestamp, moment.ISO_8601, true);
  if (parsedDate.isValid()) {
    // You can choose a different date format here, such as 'YYYY-MM-DD HH:mm:ss'
    return parsedDate.format("YYYY-MM-DD");
  } else {
    return null; // or handle the case where the timestamp is not valid
  }
}

export function handleErrorInImage(img) {
  return (img.src = "/assests/zimyologo.png");
}

export function vhToPx(vh) {
  // Get the height of the viewport in pixels
  const viewportHeightInPx = window.innerHeight;

  // Calculate the height in pixels based on the given vh value
  const heightInPx = (vh / 100) * viewportHeightInPx;

  return heightInPx;
}

export function isArraySubset(subset, superset) {
  const supersetSet = new Set(superset);
  for (const item of subset) {
    if (!supersetSet.has(item)) {
      return false;
    }
  }
  return true;
}

export const findFirstChildUrl = (data) => {
  if (!data || !Array.isArray(data) || data.length === 0) {
    return null;
  }
  const firstElement = data[0];
  if (
    firstElement.children &&
    Array.isArray(firstElement.children) &&
    firstElement.children.length > 0
  ) {
    return findFirstChildUrl(firstElement.children);
  } else {
    return firstElement.url || null;
  }
};

export const downloadBufferFileOld = async (
  dispatch,
  api_url,
  token,
  defaultFilename = "sample.xlsx",
  method = "get",
  formData = ""
) => {
  try {
    dispatch({ type: START_LOADER });

    let response = await axios({
      method: method,
      url: api_url,
      headers: {
        Token: token,
      },
      data: formData,
      responseType: "blob", // or 'blob'
      "content-type": "application/json",
      Accept: "application/json",
    });
    dispatch({ type: END_LOADER });

    const contentDisposition = response.headers["Content-Disposition"];
    let filename = defaultFilename;

    if (contentDisposition) {
      try {
        filename = contentDisposition.split("filename=")[1];
      } catch (err) {
        dispatch({ type: END_LOADER });
        console.error({ contentDisposition, headers: response.headers });
      }
    }

    const blob = new Blob([response?.data]);

    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    dispatch({
      type: ERROR_MESSAGE_FROM_SERVER,
      payload: "Error in file download,please try again or contact support",
    });
    dispatch({ type: END_LOADER });
    console.log(error);
  }
};

export const downloadBufferFile = async (
  dispatch,
  api_url,
  token,
  defaultFilename = "sample.xlsx",
  method = "get",
  formData = ""
) => {
  try {
    dispatch({ type: START_LOADER });
    let response = await axiosInstance({
      method: method,
      url: api_url,
      headers: {
        Token: token,
      },
      data: formData,
    });
    dispatch({ type: END_LOADER });

    const contentDisposition = response.headers["Content-Disposition"];
    let filename = defaultFilename;

    if (contentDisposition) {
      try {
        filename = contentDisposition.split("filename=")[1];
      } catch (err) {
        dispatch({
          type: ERROR_MESSAGE_FROM_SERVER,
          payload: "Error in file download,please try again or contact support",
        });
        dispatch({ type: END_LOADER });
        console.error({ contentDisposition, headers: response.headers });
      }
    }

    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });

    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    if (error.response) {
      // The response is a blob, so we need to convert it to text to read the message
      const reader = new FileReader();
      reader.onload = () => {
        const errorText = reader.result;
        const parseError = JSON.parse(errorText)
        dispatch({
          type: ERROR_MESSAGE_FROM_SERVER,
          payload: parseError?.message ?? "Error in file download,please try again or contact support",
        });
        dispatch({ type: END_LOADER });
      };
      reader.readAsText(error.response.data);
    } else {
      dispatch({
        type: ERROR_MESSAGE_FROM_SERVER,
        payload: "Error in file download,please try again or contact support",
      });
      dispatch({ type: END_LOADER });
    }
    console.error(error);
  }
};

export function arrayToObjectWithValueAsKeyAndIndexAsValue(array) {
  const result = {};

  array.forEach((value, index) => {
    result[value] = index;
  });

  return result;
}

export const getConfigLabels = () => {
  let labels = {
    KRA_LABEL: "KRA",
    KPI_LABEL: "KPI",
    REVIEW: "Review",
    OBJECTIVE: "Objective",
    COMPETENCY: "Competency",
    POTENTIALS: "Potential",
    KEY_RESULT_LABEL: "Key Results",
  };

  try {
    let obj = JSON.parse(localStorage.getItem("config_labels") || "{}");
    Object.keys(obj).forEach((el) => {
      labels[el] = obj[el] || labels[el];
    });
  } catch (err) {
    console.log({ err });
  }

  return labels;
};

//send an object inside and it gives you a string that you can then push into the url for preselecting the customfilter on the page
export const customFilterPrefiller = (queryObject = {}) => {
  let encryptedString = "";
  try {
    encryptedString = encryptAES(
      Object.keys(queryObject)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(queryObject[key])}`
        )
        .join("&")
    );
  } catch (err) {
    console.log({ err });
  }

  return encryptedString;
};

export const encryptAES = (text, key = secretKey) => {
  if (!text) return "";
  let encryptedString = CryptoJS.AES.encrypt(text, key).toString();
  //  });
  return encryptedString;
};

export const decryptAES = (encryptedBase64, key = secretKey) => {
  if (!encryptedBase64) return "";
  const decrypted = CryptoJS.AES.decrypt(encryptedBase64, key);
  if (decrypted) {
    try {
      const str = decrypted.toString(CryptoJS.enc.Utf8);
      //  })
      if (str.length > 0) {
        return str;
      } else {
        return "error 1";
      }
    } catch (e) {
      return `error 2${String(e)}`;
    }
  }
  return "error 3";
};

export function removeDuplicatesByProperty(array, property) {
  const uniqueValues = new Set();
  const uniqueArray = [];

  array.forEach((obj) => {
    const value = obj[property];
    if (!uniqueValues.has(value)) {
      uniqueValues.add(value);
      uniqueArray.push(obj);
    }
  });

  return uniqueArray;
}


export const convertTime12to24 = (time12h) => {
  if (time12h) {
    const [time, modifier] = time12h?.split(" ");

    let [hours, minutes] = time?.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  }
};

export function convertTime24to12(timeString) {
  if (!timeString) return "";

  const [hourString, minute] = timeString.split(":");
  const hour = +hourString % 24;
  return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
}

export const generateInputRowsValue = () => {
  const id = parseInt(Math.random() * 1000);
  // const id =null ;
  const InputRowsValue = {
    id,
    ID: id,
    key_input: "",
    unit_type: null,
    start_key: "",
    target_key: "",
    single_target: "",
    selectedConfiguration: [],
  };
  return InputRowsValue;
};

// Function to calculate the mean of an array
export const calculateMean = (data) => {
  const sum = data.reduce((acc, value) => acc + value, 0);
  return sum / data.length;
};

// Function to calculate the standard deviation of an array
export const calculateStandardDeviation = (data, mean) => {
  const squaredDifferences = data.map((value) => Math.pow(value - mean, 2));
  const sumSquaredDifferences = squaredDifferences.reduce((acc, value) => acc + value, 0);
  return Math.sqrt(sumSquaredDifferences / data.length);
};

// export const generateBellCurveDataSimple = (mean, stdDev, numPoints) => {
//   const bellCurveData = [];
//   for (let i = 0; i < numPoints; i++) {
//     const x = mean - 3 * stdDev + (i / (numPoints - 1)) * 6 * stdDev;
//     const y = (1 / (stdDev * Math.sqrt(2 * Math.PI))) * Math.exp(-0.5 * Math.pow((x - mean) / stdDev, 2));
//     bellCurveData.push({ x, y });
//   }
//   return bellCurveData;
// };

const divideRange = (mean, min, max) => {
  const interval = (mean - min) / 3;
  let divisionPoints = []
  let increment = min;
  while (increment <= max) {
    divisionPoints.push(increment)
    increment += interval
  }
  return divisionPoints;
}

export const generateBellCurveData = (mean, stdDev, numPoints, diff = 3) => {
  const minX = mean - diff * stdDev; // Adjust as needed
  const maxX = mean + diff * stdDev; // Adjust as needed
  const bellCurveData = [];
  const step = (maxX - minX) / (numPoints - 1);

  const standardRange = divideRange(mean, minX, maxX)

  for (let i = 0; i < numPoints; i++) {
    const x = minX + i * step;
    const exponent = -0.5 * Math.pow((x - mean) / stdDev, 2);
    const y = (1 / (stdDev * Math.sqrt(2 * Math.PI))) * Math.exp(exponent) * 100;
    bellCurveData.push({ x: x.toFixed(3), y: y > 3 ? Math.round(y, 2) : y })
  }

  return [bellCurveData, standardRange];
};


export const isAdmin = (role_ids) => {
  if (role_ids.includes(2) || role_ids.includes(4) || role_ids.includes(8)) {
    return true;
  } else {
    return false;
  }
}