import { Grid, Tooltip } from "@mui/material";
import styles from "./styles.module.scss";
import { InfoOutlined } from "@material-ui/icons";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import {
  VALID_DATA_TYPES,
  validateDataType,
} from "../../../components/FormInputType";
import { CustomCharts } from "@zimyo/components";
import NoDataComponent from "../../../components/NoDataComponent/NoDataComponent";
import { CHART_TYPES } from "../../../components/CustomCharts/constants";

const EmployeeDetails = ({ employeeDetails, feedbackGraphData }) => {
  const getOneToOneColors = (type) => {
    switch (type) {
      case "Canceled":
        return "#FF718B";
      case "Overdue":
        return "#FFA500";
      case "Upcoming":
        return "#000080";
      default:
        return "#009F78";
    }
  };
  return (
    <div className="w-100">
      <Grid
        container
        spacing={2}
        style={{ padding: "1rem", height: "100%", overflow: "scroll" }}
      >
        <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={2.5}>
            <div
              className={styles.employee_dashboard_card}
              style={{ "--color": "#4C8BFF" }}
            >
              <VolunteerActivismIcon
                style={{ "--bg": "rgba(76, 139, 255, 0.10)" }}
              />
              <div style={{ marginInlineStart: "1rem" }}>
                <p className={styles.employee_dashboard_card__count}>
                  {employeeDetails?.cycleAvg?.length ?? 0}
                </p>
                <p className={styles.employee_dashboard_card__title}>
                  Total Cycles
                  <Tooltip title="Appreciation Count">
                    <InfoOutlined sx={{ width: "1.5rem", height: "1.5rem" }} />
                  </Tooltip>
                </p>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={2.5}>
            <div
              className={styles.employee_dashboard_card}
              style={{ "--color": "#4C8BFF" }}
            >
              <VolunteerActivismIcon
                style={{ "--bg": "rgba(76, 139, 255, 0.10)" }}
              />
              <div style={{ marginInlineStart: "1rem" }}>
                <p className={styles.employee_dashboard_card__count}>
                  {employeeDetails?.interactions?.appreciationCount ?? 0}
                </p>
                <p className={styles.employee_dashboard_card__title}>
                  Appreciation
                  <Tooltip title="Appreciation Count">
                    <InfoOutlined sx={{ width: "1.5rem", height: "1.5rem" }} />
                  </Tooltip>
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={2.5}>
            <div
              className={styles.employee_dashboard_card}
              style={{ "--color": "#FF718B" }}
            >
              <VolunteerActivismIcon
                style={{ "--bg": "rgba(76, 139, 255, 0.10)" }}
              />
              <div style={{ marginInlineStart: "1rem" }}>
                <p className={styles.employee_dashboard_card__count}>
                  {employeeDetails?.interactions?.feedback ?? 0}
                </p>
                <p className={styles.employee_dashboard_card__title}>
                  Feedback
                  <Tooltip title="Feedback Count">
                    <InfoOutlined sx={{ width: "1.5rem", height: "1.5rem" }} />
                  </Tooltip>
                </p>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={4.5}>
            <div
              className={styles.employee_dashboard_card}
              style={{ "--color": "#009F78" }}
            >
              <VolunteerActivismIcon style={{ "--bg": "#E9FDF8" }} />
              <div style={{ marginInlineStart: "1rem" }}>
                <p className={styles.employee_dashboard_card__count}>
                  {employeeDetails?.interactions?.one_to_one?.count ?? 0}
                </p>
                <p className={styles.employee_dashboard_card__title}>
                  One To One
                  <Tooltip title="One To One Count">
                    <InfoOutlined sx={{ width: "1.5rem", height: "1.5rem" }} />
                  </Tooltip>
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "20px",
                    marginBottom: "10px",
                  }}
                >
                  {Array.isArray(
                    employeeDetails?.interactions?.one_to_one?.breakup
                  ) &&
                    employeeDetails?.interactions?.one_to_one?.breakup?.map(
                      (el) => {
                        return (
                          <div
                            style={{
                              color: getOneToOneColors(
                                el?.DISPLAY_MEETING_STATUS
                              ),
                            }}
                          >
                            {el?.DISPLAY_MEETING_STATUS}: {el?.COUNT}
                          </div>
                        );
                      }
                    )}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        padding={1.5}
        spacing={2}
        justify="space-between"
        alignItems="stretch"
      >
        {[
          {
            name: "Cycle Wise Average Rating",
            component: (
              <>
                {validateDataType(
                  employeeDetails?.cycleAvg,
                  VALID_DATA_TYPES.ARRAY
                ) ? (
                  <>
                    <CustomCharts
                      type={CHART_TYPES.AREA}
                      data={employeeDetails?.cycleAvg}
                      xField={"CYCLE_NAME"}
                      yField={"SCORE"}
                      xAxisTitle=""
                      styles={{
                        height: "310px",
                        width: "100%",
                      }}
                    />
                    <div className="w-100" style={{ height: "30px" }} />
                  </>
                ) : (
                  <NoDataComponent />
                )}
              </>
            ),
            lg: 6,
            md: 6,
            xs: 12,
          },
          {
            name: "Objective Achievements",
            component: (
              <>
                {validateDataType(
                  employeeDetails?.objectiveAchievements,
                  VALID_DATA_TYPES.ARRAY
                ) ? (
                  <>
                    <CustomCharts
                      type={CHART_TYPES.AREA}
                      data={employeeDetails?.objectiveAchievements}
                      xField={"MONTH_YEAR"}
                      yField={"ACHIEVEMENT"}
                      xAxisTitle=""
                      styles={{
                        height: "310px",
                        width: "100%",
                      }}
                    />
                    <div className="w-100" style={{ height: "30px" }} />
                  </>
                ) : (
                  <NoDataComponent />
                )}
              </>
            ),
            lg: 6,
            md: 6,
            xs: 12,
          },
          {
            name: "Month Wise Feedback",
            component: (
              <>
                {validateDataType(
                  feedbackGraphData?.feedbacks,
                  VALID_DATA_TYPES.ARRAY
                ) ? (
                  <>
                    <CustomCharts
                      type={CHART_TYPES.COLUMN}
                      data={feedbackGraphData?.feedbacks}
                      xField={"MONTH"}
                      yField={"TOTAL"}
                      xAxisTitle=""
                      styles={{
                        height: "310px",
                        width: "100%",
                      }}
                    />
                    <div className="w-100" style={{ height: "30px" }} />
                  </>
                ) : (
                  <NoDataComponent />
                )}
              </>
            ),
            lg: 6,
            md: 6,
            xs: 12,
          },
          {
            name: "Month Wise Appreciations",
            component: (
              <>
                {validateDataType(
                  feedbackGraphData?.appreciations,
                  VALID_DATA_TYPES.ARRAY
                ) ? (
                  <>
                    <CustomCharts
                      type={CHART_TYPES.COLUMN}
                      data={feedbackGraphData?.appreciations}
                      xField={"MONTH"}
                      yField={"TOTAL"}
                      xAxisTitle=""
                      styles={{
                        height: "310px",
                        width: "100%",
                      }}
                    />
                    <div className="w-100" style={{ height: "30px" }} />
                  </>
                ) : (
                  <NoDataComponent />
                )}
              </>
            ),
            lg: 6,
            md: 6,
            xs: 12,
          },
          {
            name: "Month Wise One-to-one's",
            component: (
              <>
                {validateDataType(
                  feedbackGraphData?.meetings,
                  VALID_DATA_TYPES.ARRAY
                ) ? (
                  <>
                    <CustomCharts
                      type={CHART_TYPES.COLUMN}
                      data={feedbackGraphData?.meetings}
                      xField={"MONTH"}
                      yField={"TOTAL"}
                      xAxisTitle=""
                      styles={{
                        height: "310px",
                        width: "100%",
                      }}
                    />
                    <div className="w-100" style={{ height: "30px" }} />
                  </>
                ) : (
                  <NoDataComponent />
                )}
              </>
            ),
            lg: 6,
            md: 6,
            xs: 12,
          },
          {
            name: "Appreciation as per Badges",
            component: (
              <>
                {validateDataType(
                  feedbackGraphData?.badges,
                  VALID_DATA_TYPES.ARRAY
                ) ? (
                  <>
                    <CustomCharts
                      type={CHART_TYPES.COLUMN}
                      data={feedbackGraphData?.badges}
                      xField={"BADGE_NAME"}
                      yField={"TOTAL"}
                      xAxisTitle=""
                      styles={{
                        height: "310px",
                        width: "100%",
                      }}
                    />
                    <div className="w-100" style={{ height: "30px" }} />
                  </>
                ) : (
                  <NoDataComponent />
                )}
              </>
            ),
            lg: 6,
            md: 6,
            xs: 12,
          },
        ].map((el) => (
          <Grid
            item
            xs={el.xs}
            md={el.md}
            lg={el.lg}
            sx={{ display: "flex", flexDirection: "column" }}
            key={el.name}
          >
            <div
              className={styles?.["element-card"]}
              key={el.name}
              id={`${el.name}_id`}
              style={el?.overrideStyles}
            >
              {el?.name ? (
                <div className={styles?.["edm-heading"]}>{el.name}</div>
              ) : null}

              {el.component}
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default EmployeeDetails;
