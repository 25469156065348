import { makeStyles } from "@material-ui/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Checkbox from "@material-ui/core/Checkbox";
import TablePagination from "@mui/material/TablePagination";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import StickyTableCell from "./components/StickyTableCell";
import StyledTableCell from "./components/StyledTableCell";
import StyledTableRow from "./components/StyledTableRow";
import clsx from "clsx";
import TablePaginationActions from "./components/TablePaginationActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import "./styles.css";
import TextReducingTooltipComponent from "../TextReducingTooltipComponent";
import NoDataComponent from "../NoDataComponent/NoDataComponent";
import {
  BsSortDown as SortDescIcon,
  BsSortUpAlt as SortAscIcon,
} from "react-icons/bs";
// import { Tooltip } from "chart.js";

/**
 * @author Manish Kumar, Rahul Mohta
 * @description A custom table component with configurable styling and functionalities.
 * @param {*} columns
 * @param {*} data
 * @param {*} checkbox
 * @param {*} getSelectedRows
 * @param {*} fixedHeader
 * @param {*} tableHeaderStyles
 * @param {*} tableCellHeadStyle
 * @param {*} tableCellBodyStyle
 * @param {*} contentAlignment
 * @param {*} alternate
 * @param {*} stickyColumn
 * @param {*} pagination
 * @param {*} loading
 * @param {*} wrapByTooltipDefault
 * @param {*} tableLayoutType
 * @returns
 */

const defaultColumnWidth = 150; // This is the defaultColumnWidth if table layout is fixed/sticky columns

const useStyles = makeStyles((theme) => ({
  head: {
    background: "white",
  },
  tableContainer: {
    maxHeight: "100%",
    borderRadius: "4px",
  },
  cell: {
    // width: "auto",
    background: "white",
  },
}));

const CustomTablePagination = ({
  columns = [],
  loading = false,
  data = [],
  checkbox = false,
  trackingId="REPORT_LINK",
  fixedHeader = true,
  tableRowClassName = "",
  tableHeaderStyles = {},
  contentAlignment = "center",
  alternate = {},
  stickyColumn = {
    columnIds: [],
    rightColumnIds: [],
  },
  tableBodyRowStyles = {
    borderBottom: "2px solid #F6F6F6 !important",
  },
  tableCellHeadStyle = {
    background: "#ECECEC",
    color: "#757575",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "24px",
    whiteSpace: "nowrap",
    textTransform: "capitalize",
    padding: "4px 10px",
    textAlign: "left",
  },
  tableCellBodyStyle = {
    fontSize: "13px",
    fontWeight: 400,
    fontFamily: "Segoe UI Web (West European)",
    padding: "10px 10px",
    textAlign: "left",
  },
  tableLayoutType = "auto",
  message = "No records found",
  pagination = {
    /* For pagination object reference use this . This has to be passed in parent component */
    // show: true,
    // totalDataCount: 0 ,
    // perPageOptions: [25, 50, 100, 500],
    // perPage: totalRowsPerPage ?? 25,
    // page: searchText ? searchPage : currentPage,
    // showFirstButton: true,
    // showLastButton: true,
    // handlePageChange: (newPage) => {
    //   handlePageChangeParent(newPage);
    // },
    // handlePerPageChange: (value) => handleChangeRowsPerPage(value),
  },
  wrapByTooltipDefault = false,
  onSortClickCallback = (column, order) => {},
  primaryId = "id",
  getSelectedRows = (rows) => console.log(rows),
  checkboxDisableCondition = (item) => false,
  selectedAll = false,
  setSelectedAll = () => {},
  clearSelectedRowsIds = false,
  setClearSelectedRowsIds = () => {},
  onScrollCallback = null,
}) => {
  const [selectedRowsIds, setSelectedRowsIds] = useState([]);
  //by default there exists no columnId that is sorted. Once a column is sorted by clicking we use it.
  const [sortedColumnObject, setSortedColumnObject] = useState({});
  const classes = useStyles();

  const previousSortedColumnObject = React.useRef({});

  const idsFromData = useMemo(
    () => (Array.isArray(data) ? data?.map((item) => item?.[primaryId]) : []),
    [data, primaryId]
  );

  const handleSortClick = (column, order) => {
    previousSortedColumnObject.current = sortedColumnObject; // for keeping tract of previous object
    onSortClickCallback(column, order); // gets called only when a click event occurs.
    //using it as a local state and not sharing it to parent component to prevent unexpected calls
    setSortedColumnObject({ ...column, order });
  };

  const headerContent = useCallback(
    (headerCell, header, hasSort, column) => {
      const order =
        previousSortedColumnObject.current?.accessorKey ===
        sortedColumnObject?.accessorKey // previous columnObject and current Column object are same
          ? sortedColumnObject.order === "ASC" // if previous sorting order was ASC
            ? "DESC"
            : "ASC"
          : "ASC"; // If they weren't same;

      const isActive = sortedColumnObject?.accessorKey === column.accessorKey;

      //

      return (
        <div
          className={` ${
            column?.isHeaderCentered ? "flex-center" : "flex-start-center"
          }  header-content-container`}
        >
          {headerCell && typeof headerCell === "function" ? (
            headerCell(header, column)
          ) : (
            <div>{header}</div>
          )}

          {hasSort && (
            <div
              className={
                isActive ? "sort-icon-container-active" : "sort-icon-container"
              }
              onClick={(e) => handleSortClick(column, order)}
            >
              {!isActive || order === "ASC" ? (
                <SortAscIcon className="sort-icon" />
              ) : (
                <SortDescIcon className="sort-icon" />
              )}
            </div>
          )}
        </div>
      );
    },
    [columns, sortedColumnObject] // headers , etc. are dependent only on columns
  );

  const perPageSelectedIdsCount = useMemo(() => {
    const arr =
      Array.isArray(data) &&
      data.filter(
        (item) =>
          selectedRowsIds.includes(item[primaryId]) &&
          !checkboxDisableCondition(item)
      );
    return arr?.length ?? 0;
  }, [data, checkboxDisableCondition, primaryId, selectedRowsIds]);

  const maxPossibleSelectCount = useMemo(() => {
    const arr =
      Array.isArray(data) &&
      data.filter((item) => !checkboxDisableCondition(item));
    return arr?.length ?? 0;
  }, [data, checkboxDisableCondition]);

  const filteredData = useMemo(
    () =>
      Array.isArray(data)
        ? data.filter((item) => !checkboxDisableCondition(item))
        : [],
    [data, checkboxDisableCondition]
  );

  const idsFromfilteredData = useMemo(
    () => filteredData.map((item) => item[primaryId]),
    [filteredData, primaryId]
  );

  const handleMainCheckboxChange = (e) => {
    if (
      (perPageSelectedIdsCount !== 0 &&
        perPageSelectedIdsCount !== maxPossibleSelectCount) ||
      perPageSelectedIdsCount === maxPossibleSelectCount
    ) {
      const newSelectedRowsId = selectedRowsIds.filter(
        (id) => !idsFromData.includes(id)
      );
      setSelectedRowsIds(newSelectedRowsId);
    } else if (perPageSelectedIdsCount === 0) {
      const currentPageIds = data
        .filter((item) => !checkboxDisableCondition(item))
        .map((item) => item[primaryId]);
      setSelectedRowsIds([...selectedRowsIds, ...currentPageIds]);
    }
    if (selectedAll) {
      setSelectedAll(false);
    }
  };

  const handleRowCheckboxChange = (item) => {
    if (selectedAll) {
      const newSelectedRowsId = idsFromfilteredData.filter(
        (id) => id !== item[primaryId]
      );
      setSelectedRowsIds(newSelectedRowsId);
      setSelectedAll(false);
    } else if (selectedRowsIds.includes(item[primaryId])) {
      // uncheck logic
      const newSelectedRowsId = selectedRowsIds.filter(
        (id) => id !== item[primaryId]
      );
      setSelectedRowsIds(newSelectedRowsId);
    } else {
      setSelectedRowsIds([...selectedRowsIds, item[primaryId]]);
    }
  };

  useEffect(() => {
    if (clearSelectedRowsIds) {
      setSelectedRowsIds([]);
      setClearSelectedRowsIds(false);
    }
  }, [clearSelectedRowsIds]);

  const getHeadTableCells = (
    columns,
    checkbox,
    stickyColumn,
    contentAlignment,
    data
  ) => {
    const stickyColumns = [];
    const defaultColumns = [];
    const rightStickyColumns = [];
    // let previousStickyColObject = null;
    let EffectiveLeftPosition = checkbox ? 30 : 0; // calculated based on colWidth
    let EffectiveRightPosition = 0;
    columns?.forEach((column, index) => {
      const { header, id, headerCell, hasSort, colWidth } = column;
      if (
        Array.isArray(stickyColumn?.columnIds) &&
        stickyColumn?.columnIds?.includes(id)
      ) {
        stickyColumns.push(
          <StickyTableCell
            className={clsx(
              classes.head,

              {
                "last-sticky-divider":
                  stickyColumn?.columnIds.indexOf(id) ===
                  stickyColumn?.columnIds.length - 1,
              }
            )}
            align={contentAlignment}
            // keeping it same
            stickyColumnHeadStyles={{
              ...tableCellHeadStyle,
              width: colWidth
                ? colWidth + "px"
                : tableLayoutType === "fixed"
                ? `${defaultColumnWidth}px`
                : "auto",
              left: EffectiveLeftPosition,
            }}
          >
            {headerContent(headerCell, header, hasSort, column)}
          </StickyTableCell>
        );
        EffectiveLeftPosition += colWidth ? colWidth : defaultColumnWidth;
        // previousStickyColObject = column;
      } else if (
        Array.isArray(stickyColumn?.rightColumnIds) &&
        stickyColumn?.rightColumnIds?.includes(id)
      ) {
        rightStickyColumns.push(
          <StickyTableCell
            className={clsx(classes.head)}
            align={contentAlignment}
            // keeping it same
            stickyColumnHeadStyles={{
              ...tableCellHeadStyle,
              width: colWidth
                ? colWidth + "px"
                : tableLayoutType === "fixed"
                ? `${defaultColumnWidth}px`
                : "auto",
              right: EffectiveRightPosition,
              left: "initial",
            }}
          >
            {headerContent(headerCell, header, hasSort, column)}
          </StickyTableCell>
        );
        EffectiveRightPosition += colWidth ? colWidth : defaultColumnWidth;
      } else {
        defaultColumns.push(
          <StyledTableCell
            key={id}
            align={contentAlignment}
            tableCellHeadStyle={{
              ...tableCellHeadStyle,
              width: colWidth
                ? colWidth
                : tableLayoutType === "fixed"
                ? `${defaultColumnWidth}px`
                : "auto",
            }}
            className={classes.head}
          >
            {headerContent(headerCell, header, hasSort, column)}
          </StyledTableCell>
        );
      }
    });

    return (
      <>
        {checkbox ? (
          <StickyTableCell
            className={classes.head}
            align={contentAlignment}
            style={{
              width: "30px",
              padding: "0px 0px 0px 6px",
              fontSize: "12px",
              background: tableCellHeadStyle.background ?? "",
            }}
          >
            <Checkbox
              style={{ width: "20px", height: "20px" }}
              sx={{
                "& .MuiSvgIcon-root": { width: "20px", height: "20px" },
                color:
                  (perPageSelectedIdsCount === maxPossibleSelectCount ||
                    selectedAll) &&
                  maxPossibleSelectCount !== 0
                    ? "secondary.main"
                    : "#757575 !important",
              }}
              className="custom__table__checkbox"
              checked={
                maxPossibleSelectCount === 0
                  ? false
                  : selectedAll ||
                    perPageSelectedIdsCount === maxPossibleSelectCount
              }
              indeterminate={
                perPageSelectedIdsCount !== 0 &&
                perPageSelectedIdsCount !== maxPossibleSelectCount &&
                !selectedAll
              }
              onChange={handleMainCheckboxChange}
            />
          </StickyTableCell>
        ) : null}
        {stickyColumns?.length ? stickyColumns : null}
        {defaultColumns}
        {rightStickyColumns?.length ? rightStickyColumns : null}
      </>
    );
  };

  const getBodyTableCells = (
    columns,
    checkbox,
    item,
    stickyColumn,
    contentAlignment
  ) => {
    const stickyColumns = [];
    const defaultColumns = [];
    const rightStickyColumns = [];

    // let previousStickyColObject = null;
    let EffectiveLeftPosition = checkbox ? 30 : 0; // calculated based on colWidth
    let EffectiveRightPosition = 0;

    columns?.forEach((column) => {
      const { accessorKey, id, cell, colWidth } = column;

      const bodyContent =
        cell && typeof cell === "function" ? (
          cell(item[accessorKey], item, accessorKey)
        ) : wrapByTooltipDefault ? (
          <TextReducingTooltipComponent
            data={
              item[accessorKey] !== 0 && !item[accessorKey]
                ? "-"
                : item[accessorKey]
            }
            limit={30}
          />
        ) : (
          item[accessorKey]
        );

      if (
        Array.isArray(stickyColumn?.columnIds) &&
        stickyColumn?.columnIds?.includes(id)
      ) {
        stickyColumns.push(
          <StickyTableCell
            className={clsx(
              classes.head,

              {
                "last-sticky-divider":
                  stickyColumn?.columnIds.indexOf(id) ===
                  stickyColumn?.columnIds.length - 1,
              }
            )}
            align={contentAlignment}
            stickyColumnBodyStyles={{
              ...tableCellBodyStyle,
              width: colWidth
                ? colWidth + "px"
                : tableLayoutType === "fixed"
                ? `${defaultColumnWidth}px`
                : "auto",
              left: EffectiveLeftPosition,
            }}
          >
            {bodyContent}
          </StickyTableCell>
        );
        EffectiveLeftPosition += colWidth ? colWidth : defaultColumnWidth;
        // previousStickyColObject = column;
      } else if (
        Array.isArray(stickyColumn?.rightColumnIds) &&
        stickyColumn?.rightColumnIds?.includes(id)
      ) {
        rightStickyColumns.push(
          <StickyTableCell
            className={clsx(classes.head)}
            align={contentAlignment}
            stickyColumnBodyStyles={{
              ...tableCellBodyStyle,
              width: colWidth
                ? colWidth + "px"
                : tableLayoutType === "fixed"
                ? `${defaultColumnWidth}px`
                : "auto",
              right: EffectiveRightPosition,
              left: "initial",
            }}
          >
            {bodyContent}
          </StickyTableCell>
        );
        EffectiveRightPosition += colWidth ? colWidth : defaultColumnWidth;
        // previousStickyColObject = column;
      } else {
        defaultColumns.push(
          <StyledTableCell
            className={classes.cell}
            align={contentAlignment}
            key={id}
            tableCellBodyStyle={tableCellBodyStyle}
          >
            {bodyContent}
          </StyledTableCell>
        );
      }
    });

    return (
      <>
        {checkbox ? (
          <StickyTableCell
            className={classes.head}
            align={contentAlignment}
            stickyColumnBodyStyles={stickyColumn?.bodyStyles}
            style={{
              width: "30px",
              padding: "0px 0px 0px 6px",
              fontSize: "12px",
            }}
          >
            <Checkbox
              style={{ width: "20px", height: "20px" }}
              sx={{
                visibility: false ? "hidden" : "none",
                "& .MuiSvgIcon-root": { width: "20px", height: "20px" },
                color:
                  selectedRowsIds.includes(item[primaryId]) ||
                  (selectedAll && !checkboxDisableCondition(item))
                    ? "secondary.main"
                    : checkboxDisableCondition(item)
                    ? "#ECECEC !important"
                    : "#757575 !important",
              }}
              disabled={checkboxDisableCondition(item)}
              className="custom__table__checkbox"
              checked={
                (selectedAll && !checkboxDisableCondition(item)) ||
                selectedRowsIds.includes(item[primaryId])
                  ? true
                  : false
              }
              indeterminate={false}
              onChange={() => handleRowCheckboxChange(item)}
            />
          </StickyTableCell>
        ) : null}
        {stickyColumns?.length ? stickyColumns : null}
        {defaultColumns}
        {rightStickyColumns?.length ? rightStickyColumns : null}
      </>
    );
  };

  useEffect(() => {
    getSelectedRows(selectedRowsIds ?? []);
  }, [selectedRowsIds]);

  return (
    <div className="custom__table__container">
      <TableContainer
        className={classes.tableContainer}
        style={{
          height: pagination.show
            ? `calc(100% - ${pagination.height ? pagination.height : "52px"})`
            : "100%",
        }} //For pagination 52px
        onScroll={onScrollCallback}
      >
        <Table
          stickyHeader={fixedHeader}
          aria-label={fixedHeader ? "sticky table" : "normal table"}
          sx={{
            tableLayout: tableLayoutType,
            // opacity: loading ? '0.3' : 'initial',
            // overflow: "hidden", // Hide the scrollbar by default
            // "&:hover": {
            //   overflow: "auto", // Show the scrollbar on hover
            // },
          }}
        >
          <TableHead>
            <StyledTableRow tableRowStyles={tableHeaderStyles}>
              {getHeadTableCells(
                columns,
                checkbox,
                stickyColumn,
                contentAlignment,
                data
              )}
            </StyledTableRow>
          </TableHead>

          {/* for loader */}
          {loading ? (
            <tr>
              <td
                colSpan={
                  checkbox ? columns.length + 1 ?? 1 : columns.length ?? 1
                }
              >
                <Box
                  className="flex-center"
                  style={{
                    padding: "10px",
                  }}
                >
                  <CircularProgress />
                </Box>
              </td>
            </tr>
          ) : (
            <TableBody>
              {Array.isArray(data) && data.length ? (
                data.map((item, index) => {
                  return (
                    <>
                      <StyledTableRow
                        key={index}
                        alternate={alternate}
                        tableRowStyles={tableBodyRowStyles}
                        className={tableRowClassName}
                        data-tracking-id={item?.[trackingId]}
                        id={item?.[trackingId]}
                      >
                        {getBodyTableCells(
                          columns,
                          checkbox,
                          item,
                          stickyColumn,
                          contentAlignment
                        )}
                      </StyledTableRow>
                      {/* <hr></hr> */}
                    </>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan={
                      checkbox ? columns.length + 1 ?? 1 : columns.length ?? 1
                    }
                  >
                    <NoDataComponent message={message} />
                  </td>
                </tr>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {pagination?.show ? (
        <TablePagination
          rowsPerPageOptions={pagination?.perPageOptions}
          component="div"
          count={pagination?.totalDataCount}
          rowsPerPage={pagination?.perPage}
          page={pagination?.page}
          showFirstButton={pagination?.showFirstButton}
          showLastButton={pagination?.showLastButton}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
          }}
          onPageChange={(_, page) => {
            pagination?.handlePageChange(page);
          }}
          onRowsPerPageChange={(e) => {
            const size = e.target.value ? Number(e.target.value) : 10;
            pagination?.handlePerPageChange(size);
          }}
          ActionsComponent={TablePaginationActions}
        />
      ) : null}
    </div>
  );
};

export default CustomTablePagination;
