import React from "react";
import Alert from "@material-ui/core/Alert";
import AlertTitle from "@material-ui/core/AlertTitle";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import { useDispatch } from "react-redux";
import { CLEAR_MESSAGE_FROM_SERVER } from "../redux/message/constant";

const AlertComponent = (props) => {
  const { type = "error", message = "" } = props;
  const dispatch = useDispatch();

  return (
    <div
      style={{
        zIndex: 4655000000,
        width: "auto",
        position: "absolute",
        top: 70,
        right: 40,
      }}
    >
      <Alert
        sx={{ zIndex: 4655000000 }}
        iconMapping={
          type === "error"
            ? { error: <ErrorOutline fontSize="inherit" /> }
            : { success: <CheckCircleOutline fontSize="inherit" /> }
        }
        severity={type === "error" ? "error" : "success"}
        onClose={() => dispatch({ type: CLEAR_MESSAGE_FROM_SERVER })}
      >
        <AlertTitle>{type === "error" ? "Error" : "Success"}</AlertTitle>
        {message}
      </Alert>
    </div>
  );
};

export default AlertComponent;
