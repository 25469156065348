import {
  ACTIVE_DRAWER_LISTTEM,
  COLLASE_ORGANISATION_DRAWER_LIST,
  ACCESS_GROUP_OPEN_CREATE_MODAL,
  OPEN_TRANSFER_EMPLOYEE_MODAL,
  OPEN_SUSPEND_EMPLOYEE_MODAL,
  OPEN_PROBATION_UPDATE_EMPLOYEE_MODAL,
  SET_EMPLOYEE_DATA,
  OPEN_BULK_CREATION,
  OPEN_BULK_MODIFICATION,
  OPEN_BULK_UPDATION,
  OPEN_BULK_CREATION_CONFIG,
  OPEN_BULK_UPDATION_CONFIG,
  OPEN_BULK_MODIFICATION_CONFIG,
  OPEN_EXCLUDE_EMPLOYEE_MODAL,
  IS_LOADING_PAGE,
  IS_USER_AUTHENICATED,
  EDIT_SUSPEND_EMPLOYEE_DETAILS,
  ORG_MASTER_ORG_UNIT_PAGE,
  ORG_MASTER_FORM_LIBRARY_PAGE,
  ORG_MASTER_EMAIL_LIBRARY_PAGE,
  ORGANISATION_FORM_PAGE,
  // delete that constant
  ORGANISATION_ENTITES_PAGE,
  ORGANISATION_BUSINESS_UNIT_PAGE,
  ORGANISATION_REGIONS_PAGE,
  ORGANISATION_DEPARTMENT_PAGE,
  ORGANISATION_DESIGNATIONS_PAGE,
  ORGANISATION_EMPLOYEE_TYPE_PAGE,
  ORGANISATION_LOCATION_PAGE,
  ORGANISATION_GRADE_PAGE,
  ORGANISATION_ZONE_PAGE,
  ORGANISATION_CHANNEL_PAGE,
  ORGANISATION_SUB_DEPARTMENT_PAGE,
  ORGANISATION_CONST_CENTER_PAGE,
  COLLAPSE_LEFTBAR,
  OPEN_LMS_CRED_MODAL,
  OPEN_AADHAR_VERIFY_MODAL,
  CHILDREN_TABS_DATA,
  PARENT_TABS_DATA,
  VIEW_GEO_EMPLOYEE,
  OPEN_RELAXED_GEO_MODAL,
  VIEW_RELAXED_GEO_EMPLOYEE,
} from "./constant";

export const updateOrganisationPage = (payload) => ({
  type: ORGANISATION_FORM_PAGE,
  payload,
});
export const updateOrganisationCostCenterPage = (payload) => ({
  type: ORGANISATION_CONST_CENTER_PAGE,
  payload,
});
export const updateOrganisationSubDepartmentPage = (payload) => ({
  type: ORGANISATION_SUB_DEPARTMENT_PAGE,
  payload,
});
export const updateOrganisationChannelPage = (payload) => ({
  type: ORGANISATION_CHANNEL_PAGE,
  payload,
});
export const updateOrganisationZonePage = (payload) => ({
  type: ORGANISATION_ZONE_PAGE,
  payload,
});
export const updateOrganisationGradePage = (payload) => ({
  type: ORGANISATION_GRADE_PAGE,
  payload,
});

export const updateOrganisationLocationPage = (payload) => ({
  type: ORGANISATION_LOCATION_PAGE,
  payload,
});
export const updateOrganisationEmployeeType = (payload) => ({
  type: ORGANISATION_EMPLOYEE_TYPE_PAGE,
  payload,
});
export const updateOrganisationDepartmentPage = (payload) => ({
  type: ORGANISATION_DEPARTMENT_PAGE,
  payload,
});
export const updateOrganisationDesignationsPage = (payload) => ({
  type: ORGANISATION_DESIGNATIONS_PAGE,
  payload,
});

export const updateOrganisationRegionsPage = (payload) => ({
  type: ORGANISATION_REGIONS_PAGE,
  payload,
});

export const updateOrganisationBusinessUnitPage = (payload) => ({
  type: ORGANISATION_BUSINESS_UNIT_PAGE,
  payload,
});

export const updateOrganisationEntityPage = (payload) => ({
  type: ORGANISATION_ENTITES_PAGE,
  payload,
});

export const selectActiveListItem = (payload) => ({
  type: ACTIVE_DRAWER_LISTTEM,
  payload,
});
export const collapseOrganisationInDrawer = (payload) => ({
  type: COLLASE_ORGANISATION_DRAWER_LIST,
  payload,
});

export const accessGroupOpenCreateModal = () => ({
  type: ACCESS_GROUP_OPEN_CREATE_MODAL,
});

export const openTransferEmployeeModal = () => ({
  type: OPEN_TRANSFER_EMPLOYEE_MODAL,
});

export const openSuspendEmployeeModal = () => ({
  type: OPEN_SUSPEND_EMPLOYEE_MODAL,
});

export const openProbationUpdateModal = () => ({
  type: OPEN_PROBATION_UPDATE_EMPLOYEE_MODAL,
});

export const setEmployeeData = (payload) => ({
  type: SET_EMPLOYEE_DATA,
  payload,
});

export const openBulkCreationModal = () => ({
  type: OPEN_BULK_CREATION,
});

export const openBulkModification = () => ({
  type: OPEN_BULK_MODIFICATION,
});

export const openBulkUpdation = () => ({
  type: OPEN_BULK_UPDATION,
});

export const openBulkCreationConfigModal = () => ({
  type: OPEN_BULK_CREATION_CONFIG,
});

export const openBulkUpdationConfigModal = () => ({
  type: OPEN_BULK_UPDATION_CONFIG,
});

export const openBulkModificationConfigModal = () => ({
  type: OPEN_BULK_MODIFICATION_CONFIG,
});
export const openExcludeEmployeeModalAction = () => ({
  type: OPEN_EXCLUDE_EMPLOYEE_MODAL,
});

export const isLoadingPage = (payload) => ({
  type: IS_LOADING_PAGE,
  payload,
});

export const isUserAuthenicated = (payload) => ({
  type: IS_USER_AUTHENICATED,
  payload,
});

export const editSuspendEmployeeDetails = () => ({
  type: EDIT_SUSPEND_EMPLOYEE_DETAILS,
});

export const orgMasterOrgUnitPage = (payload) => ({
  type: ORG_MASTER_ORG_UNIT_PAGE,
  payload,
});

export const orgMasterFormLibraryPage = (payload) => ({
  type: ORG_MASTER_FORM_LIBRARY_PAGE,
  payload,
});

export const orgMasterEmailLibraryPage = (payload) => ({
  type: ORG_MASTER_EMAIL_LIBRARY_PAGE,
  payload,
});

export const collapseLeftBar = (payload) => ({
  type: COLLAPSE_LEFTBAR,
  payload,
});

export const openLmsModelAction = () => ({
  type: OPEN_LMS_CRED_MODAL,
});

export const openAadharVerifyModal = () => ({
  type: OPEN_AADHAR_VERIFY_MODAL,
});

export const storeParentTabData = (payload) => ({
  type: PARENT_TABS_DATA,
  payload,
});

export const childrenTabData = (payload) => ({
  type: CHILDREN_TABS_DATA,
  payload,
});


export const openRelaxedGeoModalAction = () => ({
  type: OPEN_RELAXED_GEO_MODAL,
});

export const viewRelaxGeoEmployee = () => ({
  type : VIEW_RELAXED_GEO_EMPLOYEE
})

export const viewGeoEmployee = () => ({
  type : VIEW_GEO_EMPLOYEE
})