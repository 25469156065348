import AsyncSelect from "react-select/async";
import './styles.scss'

/**
 * Multi select component on top of `react-select/async`.
 * By defaut provides employee emals as options but you can pass your own options.
 * Refer to `react-style/async` style guide to style the select options.
 * @param {{}} props 
 * @returns 
 */
const MultiSelect = (props) => {
    const {
        className = '',
        placeholder = '',
        helperText = null,
        error = false,
        label = "",
        required = false,
        onChange = () => { },
        value = [],
        cacheOptions = true,
        isCustomLoading = false,
        loadOptions,
        defaultOptions,
        controlStyles = {},
        multiLabelStyles = {},
        multiRemoveStyles = {},
        loadingMsg = 'Searching...',
        labelKey = (e) => e.label, // * NOTE: here e.label is e.{your key}
        valueKey = (e) => e.value, // * NOTE: here e.value is e.{your key}
        isMulti = true,
        menuPlacement = 'auto',
        menuPosition = 'absolute',
        ...rest
    } = props;



    const styles = {
        control: (styles) => ({
            ...styles,
            ...controlStyles,
        }),
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: data?.color || '#4C70FF',
            backgroundColor: data?.bg || '#E6ECFF',
            ...multiLabelStyles,
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: data?.color || "#615E69",
            backgroundColor: data?.bg || "#E6ECFF",
            ...multiRemoveStyles,
        }),
        input: (styles) => ({
            ...styles,
            padding: '0px !important',
        }),
        menuPortal: base => ({
            ...base,
            zIndex: 99999999999
        }),
        placeholder: (styles) => {
            return {
                ...styles,
                fontSize: '14px',
            }
        }
    };

    return (
        <div className={`flex-start flex-column ${className}`}>
            <div className={`custom-select-container w-100`}>
                {label && (
                    <label
                        id="aria-label"
                        htmlFor="aria-example-input"
                        className="custom-select-label"
                        style={{ color: error ? 'red' : 'rgba(0, 0, 0, 0.6)' }}
                    >
                        {label} {required && <span style={{ color: 'red' }}>*</span>}
                    </label>
                )}
                <AsyncSelect
                    className={className}
                    loadingMessage={() => loadingMsg}
                    isMulti={isMulti}
                    loadOptions={loadOptions ?? []}
                    defaultOptions={defaultOptions}
                    cacheOptions={cacheOptions}
                    value={value}
                    placeholder={placeholder}
                    isLoading={isCustomLoading}
                    onChange={onChange}
                    isClearable
                    hideSelectedOptions
                    captureMenuScroll
                    styles={styles}
                    getOptionLabel={labelKey}
                    getOptionValue={valueKey}
                    menuPlacement={menuPlacement}
                    menuPosition={menuPosition}
                    {...rest}
                />
            </div>
            {helperText && (
                <p
                    className="custom-select-helper-text"
                    style={{
                        ...(error && {
                            color: 'rgb(244, 11, 11)',
                        }),
                    }}
                >
                    {helperText}
                </p>
            )}
        </div>
    )
};

export default MultiSelect;
