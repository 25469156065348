import {
  GET_ORG_UNITS_LISTS,
  ORG_UNITS_PAGE_NUMBER,
  CREATE_ORG_UNITS,
  ORG_UNITS_RESET_SAVE_FROM_DATA_SUCCESS,
  UPDATE_SHOW_IN_MENU,
  GET_ORG_MENU,
  DOWNLOAD_BULK_CREATE_MASTER_TYPE,
  UPLOAD_BULK_CREATE_MASTER_TYPE,
  UPLOAD_SECTION_INFO,
  UPLOAD_BULK_GEOFENCING,
  DOWNLOAD_BULK_GEOFENCING,
  GET_ORGANISATION_STRUCTURE,
} from "./constant";

export const getOrgUnitLists = (payload) => ({
  type: GET_ORG_UNITS_LISTS,
  payload,
});

export const updateOrgUnitPageNumber = (payload) => ({
  type: ORG_UNITS_PAGE_NUMBER,
  payload,
});

export const createOrgUnit = (payload) => ({
  type: CREATE_ORG_UNITS,
  payload,
});

export const resetSaveOrgUnitSuccess = (payload) => ({
  type: ORG_UNITS_RESET_SAVE_FROM_DATA_SUCCESS,
  payload,
});

export const updateInShowInMenu = (payload) => ({
  type: UPDATE_SHOW_IN_MENU,
  payload,
});

export const getOrgMenu = (payload) => ({
  type: GET_ORG_MENU,
  payload,
});

export const dowloadMasterCreateFile = (payload) => ({
  type: DOWNLOAD_BULK_CREATE_MASTER_TYPE,
  payload,
});

export const uploadMasterCreateFile = (payload) => ({
  type: UPLOAD_BULK_CREATE_MASTER_TYPE,
  payload,
});

export const downloadBulkGeofencing = (payload) => ({
  type: DOWNLOAD_BULK_GEOFENCING,
  payload,
});

export const uploadBulkGeofencing = (payload) => ({
  type: UPLOAD_BULK_GEOFENCING,
  payload,
});

export const uploadSectionInfo = (payload) => ({
  type: UPLOAD_SECTION_INFO,
  payload,
});

export const getOrganisationStructure = (payload) => ({
  type: GET_ORGANISATION_STRUCTURE,
  payload,
});
